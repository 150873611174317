import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductCategory.scss'
import ProductCategorys from './ProductCategorys';
import Category from './../../data/Category.json'
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { listCategoryAPI } from './../../API/Category'
class ProductCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            category: [],
            loading: false


        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.Handlechange = this.Handlechange.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
    }
    /**
* @description The function executed for redirect the page when the cookie are not available
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed change in home and menu bar category
*/
    Handlechange(value) {
        console.log(value)
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            if (value === "Menu") {
                newupdate['MENU_BAR'] = "true"
                newupdate['HOME_PAGE'] = "false"
            }
            else if (value === "Home") {
                newupdate['HOME_PAGE'] = "true"
                newupdate['MENU_BAR'] = "false"
            }
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "id",
            "url",
            "label",
            "date_created",
            "date_modified",
            "is_inactive",
            "name",
            "show_in_menubar",
            "order_precedence",
            "is_homepage_category",
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        //api for list orders
        listCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listCategory?.summary?.reason;
                let status = res?.data?.listCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listCategory.details;
                    self.setState({ loading: false })
                    self.setState({ category: data });

                } else {
                    var data = [];
                    self.setState({ category: data });
                    self.setState({ loading: false })
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed for conavert the object to string
*/
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
    }


    render() {
        return (
            <div className='Category-main  h-screen xl:h-full'>
                < Breadthcrumbs Breadthcrumb={'Product Category'} />
                <div className={'category-content '}>
                    <ProductCategorys Category={this.state.category} Homepage={Category.Homepage} passToParent={this.Handlechange} loading={this.state.loading} />
                </div>
            </div>
        )
    }
};

export default ProductCategory;