import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
class NotFound extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterVal: '',

        }
        this.handlechange = this.handlechange.bind(this);
    }
    /**
*@description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    /**
* @description The function executed change the url and update to store
*/
    handlechange() {
        store.dispatch({ type: 'URL', url: '/home' })
    }
    render() {
        return (
            < div className={'  ' + (!getCookie("wn_log") === true ? 'h-min' : 'h-screen paymentDetail-main')}>
                <div className='page-not-found top-3/12 relative  w-max m-auto'>
                    <h1 className='text-redcolor font-OpenSans font-bold xl:text-5xl text-4xl pb-10'>404 PAGE <br></br>NOT FOUND</h1>
                    {/* <p className='leading-9 pagenotfound text-black font-OpenSans font-normal xl:text-2xl text-xl py-2'>Website Is Under Construction<br></br> Navigate to a different page  </p> */}
                    <Link to={'/home'} onClick={this.handlechange}>
                        <button className='text-white text-sm bg-redcolor px-5 py-2 rounded-md font-normal font-OpenSans'>BACK TO HOME</button>
                    </Link>
                </div>

            </div >
        )
    }
};

export default NotFound;