import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import './Login.scss'
import store from './../../Store/Store'
import { emailregex } from './../../global/js/validator'
import local from '../../global/js/locale';
import { adminLoginAPI } from './../../API/Admin';
import { fetchDutyDetailsAPI } from './../../API/Leadtime';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Loader from "../../components/Loader/FullLoader";
import { setCookie, getCookie, eraseCookie } from './../../global/js/browserfunction'
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            valid: true,
            show: false,
            password: '',
            varchar: local.VARCHAR,
            Int: local.INT,
            loading: false,
            emailvalid: true,
        }
        this.emailvalidator = this.emailvalidator.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Gotopage = this.Gotopage.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
    }
    /**
* @description The function executed for dispatch the header value to store when the simpler header show 
*/
    async handleCheck() {

        store.dispatch({ type: 'Header', header: true })

        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })

    }
    /**
* @description The function executed for exists components
*/
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        eraseCookie('wn_log');
        localStorage.removeItem("wn_log");

    }
    /**
 * @description The function executed for change the email input box changes
 */
    handleChange = (e) => {
        if (e.target.id == 'email') {
            this.setState({ email: e.target.value }, function () {
                this.emailvalidator();
            });
        }
        else {
            this.setState({ password: e.target.value })
        }

    };
    /**
* @description The function executed for check the valid email
*/
    emailvalidator() {
        if (emailregex.test(this.state.email)) {
            this.setState({ valid: true })
        } else {
            this.setState({ valid: false })
        }
    }
    handleShow() {
        this.setState({ show: !this.state.show })
    }

    /**
 * @description The function executed for conavert the object to string
 */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { RECORD_ID: "RECORD_ID", MARKUP: "MARKUP" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed for submit the form
*/
    handleSubmit(e) {
        var self = this;
        var email = self.state.email;
        var password = self.state.password;
        e.preventDefault();
        let details = [
            "first_name",
            "last_name",
            "email",
            "access_token",
        ];
        var detailsduty = [
            "record_row_id",
            "duty_rate",
            "wine_type",
            "wine_volume"


        ];
        // check the email and password requirement valid
        if (this.state.valid == true) {
            this.setState({ emailvalid: true });
            this.setState({ loading: true });
            //api for admin login
            adminLoginAPI(email, password, details)
                .then((res) => res.json())
                .then(function (res) {

                    let reason = res?.data?.adminLogin?.summary?.reason;
                    let status = res?.data?.adminLogin?.summary?.status;
                    if (reason == "LOGIN_SUCCESS" && status == "SUCCESS") {
                        let data = res?.data?.adminLogin?.details;
                        //when login sucess email,name,accesstoken are stored in local storage
                        localStorage.setItem("wn_log", JSON.stringify(data));
                        setCookie("wn_log", true);

                        self.setState({ loading: false });
                        self.Gotopage('/home')
                        // showNotification("Success", "success");
                    } else {
                        self.setState({ loading: false });
                        showNotification(
                            "Invalid Email or Password",
                            "danger",
                        );
                    }
                });


        } else {
            this.setState({ emailvalid: false });
        }
    }
    /**
* @description The function executed for redirect the page
*/
    Gotopage(path) {
        this.props.history.push(path);
        store.dispatch({ type: 'Header', header: false })
    }
    render() {
        return (
            < div className=' mt-5 Login' >
                <ReactNotification />
                <Loader loading={this.state.loading} />
                <div className='user-icon m-auto w-max  relative top-9'>
                    <img src='/static/Icons/user_icon.png' alt='' className='m-auto' />
                </div>
                <div className='xl:w-1/3 w-2/3 m-auto bg-white shadow-loginshadow '>
                    <h1 className='login-head xl:text-4xl text-2xl font-OpenSans font-normal text-bluecolor text-center py-12'>Admin Login</h1>
                    <form action="" className="mt-2 flex flex-col lg:w-2/3 w-8/12 m-auto" onSubmit={this.handleSubmit} autoComplete="off">
                        <div className={"flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded  " + (this.state.emailvalid == false ? '' : 'mb-6')}>
                            <div className="flex -mr-px justify-center w-15 p-4 pr-2 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white px-3 pl-0 border-0 rounded rounded-r-none text-2xl text-gray-600"
                                >
                                    <img src='/static/Icons/email_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type="text" id='email' autoComplete="off" maxLength={this.state.varchar['MD']}
                                className={"flex-shrink text-base input-box text-bluecolor  flex-grow flex-auto leading-normal w-px flex-1 h-10   px-3 self-center relative   text-sm outline-none border-b font-OpenSans " + (this.state.valid === true ? 'border-grey-light' : 'border-redcolor')}
                                placeholder="Email" value={this.state.email} onChange={this.handleChange}
                            />

                        </div>
                        <span className={'font-OpenSans font-normal text-redcolor text-sm pl-16 ' + (this.state.emailvalid == false ? 'mb-6' : 'hidden')}>Invalid Email</span>
                        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-4">
                            <div className="flex -mr-px justify-center w-15 p-4 pr-2 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white   px-3 pl-0 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/password_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type={this.state.show === true ? 'text' : 'password'} id='password' autoComplete="off" maxLength={this.state.varchar['2X']}
                                className="flex-shrink flex-grow input-box flex-auto text-bluecolor leading-normal w-px flex-1 border-0 h-10 px-3 relative self-center  outline-none border-b font-OpenSans text-sm"
                                placeholder="Password" onChange={this.handleChange}
                            />
                            <div className="flex -mr-px border-b font-OpenSans h-10 my-auto ">
                                <span
                                    className="flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/show_password.png' alt='' className='cursor-pointer' onClick={this.handleShow} />
                                </span>
                            </div>
                        </div>
                        <Link to='/forgot' className="text-base text-redcolor text-right font-roboto leading-normal hover:underline mb-6 font-OpenSans w-max ml-auto">Forgot Password?</Link>
                        <button
                            type='submit'
                            className="bg-redcolor font-semibold py-4  mb-16 text-center w-full text-white rounded leading-tight text-xl xl:text-lg font-OpenSans font-sans mt-4 mb-2 "
                        >
                            Sign In
                        </button>
                        {/* <div className='flex mt-6 m-auto mb-16'>
                            <span className='font-OpenSans text-base text-bluecolor'>Don't have an account ?</span>
                            <Link to='/signup' className='font-OpenSans text-base text-redcolor'>&nbsp; Sign Up</Link>
                        </div> */}
                    </form>
                </div>
            </div >
        )
    }
};

export default Login;