import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CgFormatSlash } from 'react-icons/cg';
import './Breadthcrumb.scss'
class Breadthcrumbs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Breadthcrumb: props.Breadthcrumb,
            level: props.level,
            CustomPage: '',
            id: props.id,
            Url: '',
            level0: null,
            level1: null,
            level2: null,
            customer: null,
            levels: props.levels,
            level_0: props.level_0,
            level_1: props.level_1,
            level_2: props.level_2,
            level_3: props.level_3,
        }
    }
    /**
    * @description The function executes before component call
    */
    componentDidMount() {
        // Update the url for get the breadthcrumb intermediate value 
        var urls = "/" + window.location.pathname.split('/')[1];
        this.setState({ Url: urls })
        if (urls == '/homepage') {
            this.setState({ CustomPage: 'Home Page Customization' })
        } else if (urls == '/paymentmethod') {
            this.setState({ CustomPage: ' Payment Method' })

        }
        else {
            this.setState({ CustomPage: ' Product Category' })

        }
        // get the value from url in level0 and level1
        let params = new URLSearchParams(document.location.search)
        let level0 = params.get("level0");
        let level1 = params.get("level1");
        let level2 = params.get("level2");
        let customer = params.get("customer");
        if (params.get("level0")) {
            this.setState({ level0: level0 });
        }
        if (params.get("level1")) {
            this.setState({ level1: level1 });
        }
        if (params.get("level2")) {
            this.setState({ level2: level2 });
        }
        if (params.get("customer")) {
            this.setState({ customer: customer });
        }

    }
    render() {
        return (
            <div className={'breadthcrum    w-full xl:h-12 h-20   '}>
                <div className=' flex xl:py-3 xl:pb-2 py-10 xl:w-full px-5'>
                    <Link to='/home'>
                        <h2 className='text-bluecolor  xl:pl-0  xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>Home</h2></Link>
                    <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                    {this.state.Breadthcrumb == 'Address' &&
                        <React.Fragment>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to='/customer'>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>Customer</h2></Link>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to={'/customer/customerdetails/' + (this.state.customer)}>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>Customer Details</h2></Link>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                        </React.Fragment>
                    }
                    {this.props.Count == 2 && this.state.Breadthcrumb !== 'Products' &&
                        <React.Fragment>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to={'/' + this.state.level.toLowerCase()}>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>{this.state.level}</h2></Link>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>

                        </React.Fragment>
                    }
                    {this.props.Count == 2 && this.state.Breadthcrumb == 'Products' &&
                        <React.Fragment>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <span>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal '>{this.state.level}</h2></span>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>

                        </React.Fragment>
                    }
                    {this.props.Count == 3 &&
                        <React.Fragment>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to={this.state.Url}>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>{this.state.CustomPage}</h2></Link>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level0 !== '' && this.state.level0 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className={'text-bluecolor my-auto ' + (this.state.level0 !== '' && this.state.level0 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal ' + (this.state.level0 !== '' && this.state.level0 !== null ? '' : 'hidden')}>{this.state.level0}</h2>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level1 !== '' && this.state.level1 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className={'text-bluecolor my-auto ' + (this.state.level1 !== '' && this.state.level1 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal  ' + (this.state.level1 !== '' && this.state.level1 !== null ? '' : 'hidden')}>{this.state.level1}</h2>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                        </React.Fragment>
                    }
                    {this.props.Count == 4 && this.props.levels === undefined &&
                        <React.Fragment>
                            <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to={this.state.Url}>
                                <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60'>{this.state.CustomPage}</h2></Link>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level0 !== '' && this.state.level0 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className={'text-bluecolor my-auto ' + (this.state.level0 !== '' && this.state.level0 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal ' + (this.state.level0 !== '' && this.state.level0 !== null ? '' : 'hidden')}>{this.state.level0}</h2>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level1 !== '' && this.state.level1 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className={'text-bluecolor my-auto ' + (this.state.level1 !== '' && this.state.level1 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal  ' + (this.state.level1 !== '' && this.state.level1 !== null ? '' : 'hidden')}>{this.state.level1}</h2>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level2 !== '' && this.state.level2 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <span className={'text-bluecolor my-auto ' + (this.state.level2 !== '' && this.state.level2 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal  ' + (this.state.level2 !== '' && this.state.level2 !== null ? '' : 'hidden')}>{this.state.level2}</h2>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                        </React.Fragment>
                    }
                    {this.props.Count == 4 && this.props.levels == true &&
                        <React.Fragment>
                            <span className={'text-bluecolor my-auto ' + (this.state.level_0 !== '' && this.state.level_0 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                            <Link to={'/' + this.state.level_0}>
                                <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal opacity-60' + (this.state.level_0 !== '' && this.state.level_0 !== null ? '' : 'hidden')}>{this.state.level_0}</h2>
                            </Link>
                            <span className={'text-bluecolor my-auto hidden ' + (this.state.level_1 !== '' && this.state.level_1 !== null ? 'xl:block' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                            <Link className='flex' to={'/' + this.state.level_0.toLowerCase() + '/' + this.state.level_1.toLowerCase().replace(/ /g, '') + '/' + this.state.level_2}>
                                <span className={'text-bluecolor my-auto w-5 ' + (this.state.level_1 !== '' && this.state.level_1 !== null ? 'xl:hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                                <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal  ' + (this.state.level_1 !== '' && this.state.level_1 !== null ? '' : 'hidden')}>{this.state.level_1}</h2>
                                <span className={'text-bluecolor my-auto hidden ' + (this.state.level_2 !== '' && this.state.level_2 !== null ? 'hidden' : 'hidden')}><CgFormatSlash size='25px' color='bluecolor' /></span>
                                <span className={'text-bluecolor my-auto ' + (this.state.level_2 !== '' && this.state.level_2 !== null ? 'xl:hidden hidden' : 'xl:hidden hidden')}><CgFormatSlash size='20px' color='bluecolor' /></span>
                                {/* <h2 className={'text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal  ' + (this.state.level_2 !== '' && this.state.level_2 !== null ? '' : 'hidden')}>{this.state.level_2}</h2> */}
                            </Link>
                            <span className=' xl:block hidden text-bluecolor my-auto'><CgFormatSlash size='25px' color='bluecolor' /></span>
                        </React.Fragment>
                    }
                    <span className=' block xl:hidden text-bluecolor my-auto  font-normal'><CgFormatSlash size='20px' color='bluecolor' /></span>
                    <h2 className='text-bluecolor   xl:text-base text-sm font-OpenSans xl:font-semibold font-normal'>{this.props.Breadthcrumb}</h2>
                </div>
                {/* <hr className='breadthcrum-hr xl:block hidden'></hr> */}
            </ div >
        )
    }
};

export default Breadthcrumbs;