
import { BASEURL } from './Baseurl'

/**
     * @description  The function for API for listing Users
     * @param {email,token,limit,offset} mail Id, access token, page limit and page
     * @returns login success or fails
*/
function userListAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listAllAdmin(${query})
                       {
                   summary{
                        status
                        reason
                       }
                       details
                       {
                            list{
                                ${detail}
                                
                            }
                            totalCount
                       }
                    }
                }`,
            }
        ),
    })
}

/**
     * @description  The function for API for fetching single user details
     * @param {query, details}, payload and details to be displayed
     * @returns single user details
*/
function singleUserListAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchAdminDetails(${query})
                       {
                        summary{
                            status
                            reason
                        }
                        details
                        {
                            ${detail}
                        }
                    }
                }`,
            }
        ),
    })
}

/**
     * @description  The function for API for Adding new user
     * @param {query, details}, payload and details to be displayed
     * @returns stores new user details
*/
function newUserAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                            createAdmin(${query})
                            {
                                summary{
                                    status
                                    reason
                                }
                                details
                                {
                                    ${detail}
                                }
                            }
                        }`,
            }
        ),
    })
}

/**
     * @description  The function for API for updating single user details
     * @param {query, details}, payload and details to be displayed
     * @returns updated details of an User
*/
function updateUserAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
        {
            query: `
            mutation{
                updateAdmin(${query})
                {
                    summary{
                        status
                        reason
                    }
                    details
                    {
                        ${detail}
                    }
                }
            }`,
        }
        ),
    })
}

/**
     * @description  The function for API for sending tokens for getting new password for new users and resetting password for existing Users
     * @param {query, details}, payload and details to be displayed
     * @returns token
*/
function sendPasswordAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/admin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
        {
            query: `
            mutation{
                forgotPassword(${query})
                {
                    summary{
                        status
                        reason
                    }
                    details
                    {
                        ${detail}
                    }
                }
            }`,
        }
        ),
    })
}



export {
    userListAPI,
    newUserAPI,
    singleUserListAPI,
    updateUserAPI,
    sendPasswordAPI

}