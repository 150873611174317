import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../Store/Store'
import Select from 'react-select'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { deliveryChargeUpdateAPI } from '../../API/Delivery';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import './devivery.scss'
class Deliverylist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            CountryList: props.Countrylist,
            Edit: store.getState().edit,
            Deliverycharge: props.Deliverycharge,
            Countrylist: [],
            varchar: local.VARCHAR,
            Int: local.INT,
            ShippingCost: [],
            strObj: '',
            stateobj: false,
            loading: false,
            Row_not_match: []


        }

        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleAddNew = this.HandleAddNew.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdation = this.handleUpdation.bind(this);
        this.HandleRemove = this.HandleRemove.bind(this);
        this.HandleUpdate = this.HandleUpdate.bind(this);
        this.HandleValidate = this.HandleValidate.bind(this);
    }
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })

        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.CountryList !== nextProps.Countrylist) {
            this.setState({ CountryList: nextProps.Countrylist })
            console.log(nextProps.Countrylist)
        }
        if (this.state.Deliverycharge !== nextProps.Deliverycharge) {
            this.setState({ Deliverycharge: nextProps.Deliverycharge })
        }
        // if (this.state.loading !== nextProps.loading) {
        //     this.setState({ loading: nextProps.loading })
        // }
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
    }

    /**
        * @description The function executed for exists the component 
    */
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }

    /**
        * @description The function executed when the change in status change the value of the breadthcrumbs
    */
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        var editState = !this.state.Edit
        var obj = JSON.parse(this.state.strObj);
        if (editState === false) {
            this.setState({ ShippingCost: obj })
            var array = []
            this.setState({ Row_not_match: array })
        }
    }

    HandleUpdate() {

        if (this.state.StateOption === undefined) {
            var countryoption = this.state.CountryOption
            this.setState({ ShippingCost: countryoption.standard });
            var strObj = JSON.stringify(countryoption.standard);
            this.setState({ strObj: strObj });

        } else {
            var city = this.state.StateOption.value
            var countrylist = this.state.CountryOption
            var specialcharges = countrylist.specialCharges
            var shippingcost;
            if (specialcharges.length > 0) {
                var item = specialcharges.find(item => item.state === city);
                if (item !== undefined) {
                    this.setState({ ShippingCost: item.charge });
                    shippingcost = item.charge
                } else if (city === "Others") {
                    this.setState({ ShippingCost: countrylist.standard });
                    shippingcost = countrylist.standard
                } else {
                    this.setState({ ShippingCost: countrylist.standard });
                    shippingcost = countrylist.standard
                }
            } else {
                this.setState({ ShippingCost: countrylist.standard });
                shippingcost = countrylist.standard
            }
            var strObj = JSON.stringify(shippingcost);
            this.setState({ strObj: strObj });
        }
        this.setState({ loading: false })
    }

    /**
        * @description  Function for update state
        * @param {e,id}, entered data and key of record's index
        * @returns Display updated data 
    */
    handleChange(e, id) {
        var position = id
        var name = e.target.id;
        var value = e.target.value;
        var deliverycharge = this.state.ShippingCost
        deliverycharge[position][name] = parseFloat(value)
        console.log(deliverycharge)
        this.setState({ ShippingCost: deliverycharge });
    }

    /**
        * @description The function executed for add new row in delivery charge
        * @param {key} position to add new row
        * @returns updated data
    */
    HandleAddNew(key) {
        var deliverycharge = this.state.ShippingCost;
        var position = key + 1;
        var id = [];
        deliverycharge.forEach(element => {
            id.push(element.record_id)
        });
        var max = Math.max.apply(null, id);
        var object = {
            record_id: max + 1,
            min_weight: deliverycharge[position - 1].max_weight !== NaN && parseFloat((deliverycharge[position - 1].max_weight) + 0.01).toFixed(2),
            max_weight: "",
            price: deliverycharge[position - 1].price !== NaN && ((deliverycharge[position - 1].price) + 1)
        }
        if ((object['record_id'] !== null) || (object['max_weight'] !== null) || (object['min_weight'] !== null) || (object['price'] !== null)) {
            deliverycharge.splice(position, 0, object);
        } else {
            showNotification("Empty Can't Be Added", "warning")
        }
        this.setState({ ShippingCost: deliverycharge });

    }

    /**
        * @description The function executed for delete rows from delivery charge
        * @param {key} position to delete rows
        * @returns updated data
    */
    HandleRemove(key) {
        var deliverycharge = this.state.ShippingCost;
        if (deliverycharge.length > 1) {
            deliverycharge.splice(key, 1);
        } else {
            showNotification("Cannot Remove Entire Data", "warning")
        }
        console.log(deliverycharge)
        this.setState({ ShippingCost: deliverycharge });

    }


    /**
        * @description The function executed for update the country
        * @param {selectedOption} selected obj
    */
    handleCountry = selectedOption => {
        console.log(selectedOption)
        this.setState({ CountryOption: selectedOption });
        this.setState({ ShippingCost: selectedOption.standard });
        var strObj = JSON.stringify(selectedOption.standard);
        this.setState({ strObj: strObj });
        var notmatch = []
        this.setState({ Row_not_match: notmatch })


    };

    /**
        * @description The function executed for update the state
    */
    handleState = selectedOption => {
        this.setState({ StateOption: selectedOption });
        var city = selectedOption.value
        var countrylist = this.state.CountryOption
        var specialcharges = countrylist.specialCharges
        var shippingcost;
        if (specialcharges.length > 0) {
            var item = specialcharges.find(item => item.state === city);
            if (item !== undefined) {
                this.setState({ ShippingCost: item.charge });
                shippingcost = item.charge
            } else if (city === "Others") {
                this.setState({ ShippingCost: countrylist.standard });
                shippingcost = countrylist.standard
            }
            else {
                this.setState({ ShippingCost: countrylist.standard });
                shippingcost = countrylist.standard
            }
        } else {
            this.setState({ ShippingCost: countrylist.standard });
            shippingcost = countrylist.standard
        }
        var strObj = JSON.stringify(shippingcost);
        this.setState({ strObj: strObj });
        var notmatch = []
        this.setState({ Row_not_match: notmatch })
        // this.setState({ Deliverycharge: selectedOption.standard });

    };
    /**
        * @description The function executed for validate shipping cost 
        * @returns updated data
    */
    HandleValidate(cost) {
        console.log(cost)
        var notmatch = []
        cost.forEach((e, i) => {
            if (e.min_weight > e.max_weight) {
                notmatch.push(i)
            }
            if (cost[i - 1] != undefined) {
                if (cost[i - 1].max_weight > cost[i].min_weight) {
                    notmatch.push(i)
                }
                // if (cost[i - 1].price > cost[i].price) {
                //     notmatch.push(i)
                // }
            }


        });
        this.setState({ Row_not_match: notmatch })
        return notmatch

    }
    /**
        * @description The function executed for update the Charges and weight slabs
        * @returns updated data
    */
    handleUpdation(e) {

        e.preventDefault();
        var self = this;
        var token;
        var email;
        var delvery = JSON.stringify(self.state.ShippingCost)// convert updated charges array to string
        var shippingCost = delvery.replace(/"/g, "'"); //replacing double quotes to single quotes to convert the object into string to be passed inAPI
        var Row_not_match = this.HandleValidate(self.state.ShippingCost)
        var newupdate = {};
        if (!getCookie("wn_log")) {//checking for empty cookies
            self.props.history.push("/delivery");
        }
        if (localStorage.getItem("wn_log") !== null) {//checking  for empty token
            // self.setState({ loading: true })
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['COUNTRY'] = self.state.CountryOption.value
            if (self.state.StateOption != undefined) {
                newupdate['STATE'] = self.state.StateOption.value
            }
            newupdate['SHIPPING_COST'] = shippingCost
            console.log(shippingCost)

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                TOKEN: "TOKEN",
                COUNTRY: "COUNTRY",
                STATE: "STATE",
                SHIPPING_COST: "SHIPPING_COST"
            };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
                var details = [
                    "id",
                    "country",
                    "states"
                ];

            }

            if (self.state.Edit) {
                console.log(Row_not_match.length === 0)
                if (Row_not_match.length === 0) {
                    store.dispatch({ type: 'EditOrder', edits: false })
                    deliveryChargeUpdateAPI(graphQLParam, details)//API call for getting single User details
                        .then((res) => res.json())
                        .then(function (res) {
                            self.setState({ loading: true })
                            let reason = res?.data?.updateDeliveryCharge?.summary?.reason;
                            let status = res?.data?.updateDeliveryCharge?.summary?.status;
                            if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                                showNotification("Updated", "success")
                                self.props.passToParent(true)
                                self.HandleUpdate()
                            }
                            else if (reason == "RECORD_NOT_FOUND" && status == "SUCCESS") {
                                self.setState({ loading: false })
                                self.props.passToParent(true)
                                showNotification("Somethingwent wrong", "danger")
                            }
                            else {
                                self.setState({ loading: false })
                                eraseCookie('wn_log');
                                localStorage.removeItem("wn_log");
                                self.props.history.push("/");
                            }

                        })
                } else {
                    showNotification("Incorrect Slab Price", "danger")
                }
            }
        } else {
            store.dispatch({ type: 'EditOrder', edits: false })
            self.props.history.push("/delivery");
        }
    }


    render() {
        return (
            <div className='Order-Main'>

                <form onSubmit={this.handleUpdation}>
                    <div className='Order-List h-full shadow-shadowblack mt-7 bg-white px-5' >
                        <div className='items count flex my-auto  pt-5'>
                            <div className='item-show mt-3 w-5/12 '>
                                <span className='font-OpenSans font-bold text-base text-bluecolor'>Weight Class</span>
                            </div>
                            {this.state.CountryOption && this.state.CountryOption.value &&
                                <div className={'w-1/2 text-right my-auto w-max ml-auto mr-5 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                    <span className={'ml-auto w-max block ml-auto mr-4 cursor-pointer ' + (this.state.Edit == true ? 'hidden' : '')} onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>

                                </div>}
                            <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                                <div className='buttons w-max flex ml-auto'>
                                    <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                        <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 ' >
                                            <div className='flex'>
                                                <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                <span className='ml-2'>Save</span>
                                            </div>
                                        </button>
                                    </div>

                                    <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                                </div>
                            </div>
                        </div>

                        <div className='w-auto py-5'>
                            <div className='flex'>

                                <div className='w-auto flex'>
                                    <span className='font-OpenSans font-normal text-base text-bluecolor w-20 my-auto'>Country</span>
                                    <span className={' shipping-country w-52 wine-country status-select shipping-font deivery-select '}>
                                        <Select
                                            value={this.state.CountryOption}
                                            onChange={this.handleCountry}
                                            options={this.state.CountryList} isSearchable={true}
                                            placeholder="Country"
                                        />
                                    </span>
                                </div>
                                {this.state.CountryOption?.specialCharges.length != 0 && this.state.CountryOption != undefined &&
                                    < div className='w-auto flex ml-6'>
                                        <span className='font-OpenSans font-normal text-base text-bluecolor w-14 my-auto'>State</span>
                                        <span className={' shipping-country w-52 wine-country status-select shipping-font deivery-select'}>
                                            <Select
                                                value={this.state.StateOption}
                                                onChange={this.handleState}
                                                options={this.state.CountryOption?.stateslist} isSearchable={true}
                                                placeholder="States"
                                            />
                                        </span>
                                    </div>}
                            </div>
                        </div>
                        {/* The orderlist view Desktop */}
                        <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                            <Loader loading={this.state.loading} />
                        </div>
                        <div className={'items-text mt-6 pb-6  ' + (this.state.loading === true ? 'hidden' : 'block')}>
                            {this.state.CountryOption &&
                                <table className="table-auto w-full mt-3 ">

                                    <thead >
                                        <tr className='payment-table'>

                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 xl:w-64 '>Min</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 xl:w-64'>Max</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3 xl:w-64'>Delivery Charge</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'></th>
                                        </tr>
                                    </thead>
                                    <tbody className={'mt-2 payment-table-body ' + (this.state.ShippingCost.length > 0 ? '' : 'hidden')}>
                                        {/* The Foreach of the added deliverycharge */}
                                        {this.state.ShippingCost.map((list, key) =>
                                            <tr key={key} className={'' + (this.state.Row_not_match.includes(key) === true ? 'not-match' : '')}>
                                                <td className={'table-payment text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 '}><span className={'' + (this.state.Edit === true ? 'hidden' : '')}>{list.min_weight}</span>
                                                    <input max="1000" type='number' id="min_weight" name='Name' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 xl:ml-2  ' + (this.state.Edit === true ? ' w-11/12 m-auto ' : 'hidden')} maxLength={this.state.Int["SM-US"]} required onChange={(e) => this.handleChange(e, key)} value={list.min_weight} /></td>
                                                <td className='table-payment text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'><span className={'' + (this.state.Edit === true ? 'hidden' : '')}>{list.max_weight}</span>
                                                    <input max="1000" type='number' id="max_weight" name='Name' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 xl:ml-2  ' + (this.state.Edit === true ? ' w-11/12 m-auto ' : 'hidden')} maxLength={this.state.Int["SM-US"]} required onChange={(e) => this.handleChange(e, key)} value={list.max_weight} /></td>
                                                <td className='table-payment text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>
                                                    <input max="10000" type='number' id="price" name='Name' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-48 xl:ml-2 ' + (this.state.Edit === true ? ' w-11/12 m-auto ' : 'hidden')} required onChange={(e) => this.handleChange(e, key)} value={list.price} /><span className={'' + (this.state.Edit === true ? 'hidden' : '')} maxLength={this.state.Int["SM-US"]}>{this.state.Currency}{list.price}</span></td>
                                                <td className={'table-payment text-left text-sm  text-bluecolor font-NotoSans font-normal py-3 xl:pl-sm ' + (this.state.Edit === true ? 'hidden' : '')}></td>
                                                <td className={'table-payment text-left text-sm  text-bluecolor font-NotoSans font-normal py-3 xl:pl-sm  ' + (this.state.Edit === true ? '' : 'hidden')}>
                                                    <div className='flex'>
                                                        <span className='px-3 cursor-pointer my-auto'><img src='/static/Icons/plus.svg' alt='' className='w-5 cursor-pointer' onClick={() => this.HandleAddNew(key)} /></span>
                                                        <span className='px-3 cursor-pointer my-auto' ><img src='/static/Icons/delete.svg' alt='' className='w-5 cursor-pointer' onClick={() => this.HandleRemove(key)} /></span>
                                                    </div>
                                                </td>

                                            </tr>

                                        )}

                                    </tbody>
                                </table>
                            }
                        </div>
                    </div >
                </form>
            </div >
        )
    }
};

export default Deliverylist;