import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/FullLoader'
import Select from 'react-select'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './HomePageCustomization.scss'
import HomePage from './HomePage';
import HomePageCustom from '../../data/HomePage.json'
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { listHomePageCategoryAPI, UpdateHomePageItemAPI, listCategoryAPI } from './../../API/Category'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
class HomepageCustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Id: props.match.params.id,
            Type: '',
            Update: {},
            States: '',
            category: '',
            categorylist: [],
            categoryid: '',
            inactive: false,
            categorys: ''

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleUpdate = this.HandleUpdate.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
        this.HandleUpdates = this.HandleUpdates.bind(this);
        this.Handlechecked = this.Handlechecked.bind(this);

    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })

        })
    }
    /**
* @description The function executed for change the inactive state
*/
    Handlechecked() {
        if (this.state.Edit) {
            this.setState({ inactive: !this.state.inactive });
        }

    }
    /**
* @description The function executed for exists the component 
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        // dispatch the url to store
        store.dispatch({ type: 'URL', url: urls })
        let params = new URLSearchParams(document.location.search)
        // check the status of the page edit or not also check the type of the customiztation of the homepage
        if ((params.get("Edit") == null || params.get("Edit") == '') || (params.get("Type") == null || params.get("Type") == '')) {
            this.props.history.push('/homepage')
        } else {
            let edit = params.get("Edit");
            store.dispatch({ type: 'EditOrder', edits: JSON.parse(edit) })
            let type = params.get("Type");

            // this.setState({ Edit: JSON.parse(edit) });
            this.setState({ Type: type });
        }
        if (store.getState().edit == true) {
            this.setState({ States: 'Edit' });
        } else {
            this.setState({ States: 'Details' });
        }
        this.Handlechange()
    }
    /**
* @description The function executed when the change in status change the value of the breadthcrumbs
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        if (store.getState().edit == true) {
            this.setState({ States: 'Edit' });
        } else {
            this.setState({ States: 'Details' });
        }
        var edit = !this.state.Edit;
        if (edit === false) {
            this.Handlechange()
        }

    }
    /**
* @description The function executed get the categoryid
*/
    handleCategory = selectedOption => {
        this.setState({ categoryid: selectedOption });
        var Update = this.state.Update
        Update['CATEGORY'] = selectedOption.id;
        console.log(Update)

    };
    /**
* @description The function executed get the changes from input box and store as object
*/
    HandleUpdate(e, index) {
        let update = this.state.Update;
        let key = e.target.id;
        let value = e.target.value;
        if (index === 'Banner') {
            if (key === 'TITLE') {
                update[key] = value.replace(/\n/g, ' ');
            } else {
                update[key] = value;
            }
        }
        else if (key === 'DESCRIPTION') {
            update[key] = value.replace(/\n/g, ' ');
        } else {
            update[key] = value;
        }


        console.log(update)
        this.setState({ Update: update });
    }
    /**
* @description The function executed change list homepage category
*/
    Handlechange() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "record_id",
            "image",
            "title",
            "category",
        ];
        var bannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
        ];
        var bottombannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
            "description",
            "category",
            "is_inactive"
        ];
        //api for list category
        listHomePageCategoryAPI(query, details, bannerdetails, bottombannerdetails)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.listHomePageCategory?.summary?.reason;
                let status = res?.data?.listHomePageCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listHomePageCategory.details;
                    let Type = self.state.Type
                    var category = [];
                    var newarray = [];
                    data.featureCategory.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });
                    data.featureProducts.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });
                    data.bottomHeader.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });
                    self.setState({ categorylist: newarray });
                    var id = parseInt(self.state.Id);
                    self.setState({ loading: false })
                    if (Type === 'Banner') {
                        var obj = data.bannerHeader.find(item => item.record_id === id);
                        category = obj;
                    } else if (Type === 'Category') {
                        var obj = data.featureCategory.find(item => item.record_id === id);
                        category = obj
                    }
                    else if (Type === 'Product') {
                        var obj = data.featureProducts.find(item => item.record_id === id);
                        category = obj
                    }
                    else {
                        var obj = data.bottomHeader.find(item => item.record_id === id);
                        category = obj
                        var inactive = obj.is_inactive
                        self.setState({ inactive: inactive });
                    }
                    console.log(category)
                    self.setState({ category: category });
                    if (Type !== 'Banner') {
                        self.HandleHomePage(newarray, category)
                    }

                    var Update = self.state.Update
                    if (category.category && category.category != null && category.category != '') {
                        Update['CATEGORY'] = category.category;
                    }
                    if (category.image && category.image != null && category.image != '') {
                        Update['IMAGE_URL'] = category.image;
                    }
                    self.setState({ Update: Update });

                } else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed change in home and menu bar category
*/
    HandleHomePage(array, category) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['HOME_PAGE'] = "true"
            newupdate['MENU_BAR'] = "false"

        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "id",
            "url",
            "label",
            "date_created",
            "date_modified",
            "is_inactive",
            "name",
            "show_in_menubar",
            "order_precedence",
            "is_homepage_category",
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        //api for list orders
        listCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listCategory?.summary?.reason;
                let status = res?.data?.listCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listCategory.details;
                    self.setState({ loading: false })
                    let newarray = []
                    data = data.forEach(element => {
                        newarray.push({ label: element.label + ' ( ID:' + element.id + ' )', value: element.id + '(' + element.label + ')', id: element.id })
                    });
                    var obj = newarray.find(item => item.id === category.category);
                    console.log(obj)
                    self.setState({ categorys: obj });
                    var categoryvalue = { label: obj.label, value: obj.label, id: obj.id }
                    self.setState({ categoryid: categoryvalue });
                    self.setState({ categorylist: newarray });
                } else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
   * @description The function executed for update or edit the category
   */
    HandleUpdates() {
        let token;
        let email;
        var id = parseInt(this.state.Id);
        var update = this.state.Update;
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            update['EMAIL'] = email
            update['TOKEN'] = token
            update['RECORD_ID'] = id
            update['IS_INACTIVE'] = JSON.stringify(this.state.inactive)
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(update)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "record_id",
            "image",
            "title",
            "category",
        ];
        //api for list category
        console.log(query)
        UpdateHomePageItemAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.UpdateHomePageItem?.summary?.reason;
                let status = res?.data?.UpdateHomePageItem?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                    self.setState({ loading: false })
                    showNotification("Category  Updated", "success");
                    self.Handlechange()
                }
                else {
                    self.setState({ loading: false })
                    showNotification("Sorry Category Not Updated", "danger");
                }

            });
    }
    /**
    * @description The function executed for convert the object to string
    */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            TITLE: "TITLE",
            IMAGE_URL: "IMAGE_URL",
            DESCRIPTION: "DESCRIPTION",
        };
        let booleanFields = { MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE", RECORD_ID: "RECORD_ID", CATEGORY: "CATEGORY", IS_INACTIVE: "IS_INACTIVE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed submit the form
*/
    HandleSubmit(e) {
        e.preventDefault();
        this.HandleUpdates()
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    render() {
        return (
            <div className={'HomePageCustomization-main  h-screen xl:h-full ' + (this.state.States)}>
                <ReactNotification />
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={this.state.States} Count={3} />
                    <Link to='/homepage' className='flex bg-white w-56 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/surface.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To HomePage</span>
                    </Link>
                </div>
                {this.state.loading === true ?
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div> :
                    <div className={'HomePageCustomization-main-content px-5 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                        <form onSubmit={this.HandleSubmit}>
                            <div className='bg-white px-5 py-5'>
                                <div className='w-full flex'>
                                    <div className='flex w-1/2 px-3'>
                                        {/* The page header based on the type */}
                                        <img src='/static/Icons/surface.svg' alt='' className='xl:w-8 w-5' />
                                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Banner' ? '' : 'hidden')}>Home Page Customization</span>
                                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Category' ? '' : 'hidden')}>Featured Category Section</span>
                                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Product' ? '' : 'hidden')}>Featured Product Section</span>
                                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Bottombanner' ? '' : 'hidden')}> Bottom Banner Section</span>
                                    </div>
                                    <div className={'w-1/2 text-right my-auto w-max ml-auto mr-5 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                        <span className={'ml-auto w-max block ml-auto mr-4 cursor-pointer ' + (this.state.Edit == true ? 'hidden' : '')} onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>

                                    </div>
                                    <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                                        <div className='buttons w-max flex ml-auto h-8'>
                                            <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                                    <div className='flex'>
                                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                        <span className='ml-2 mx-auto'>Save</span>
                                                    </div>
                                                </button>
                                            </div>

                                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className="sortfilterborder my-4"></hr>
                                {/* The section for edits banner */}
                                <div className={'mt-5 px-4 ' + (this.state.Type == 'Banner' ? '' : 'hidden')}>
                                    <table className="table-auto">
                                        <tbody>
                                            <tr>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm align-top w-48 py-2'>Banner image</td>
                                                <td className='py-2'><div className={' h-36 bg-no-repeat  bg-cover  ' + (this.state.Edit === true ? '' : '')} style={{
                                                    backgroundImage: `url(${this.state.Update?.IMAGE_URL})`
                                                }}>
                                                </div> </td>

                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans font-bold text-bluecolor xl:text-sm text-sm w-48 py-2 align-top'>Description {this.state.Type == 'Banner' ? '*' : ''}</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2 word-breaks-list'><span className={'word-breaks-list ' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.category.title} </span>  <textarea type='text' rows='3' id='TITLE' className={'resize-none pl-2 font-OpenSans font-normal text-sm text-bluecolor border rounded-md  w-96 focus:border-redcolor ' + (this.state.Edit == true ? '' : 'hidden')} onChange={this.handleBilling} defaultValue={this.state.category.title} required={this.state.Type == 'Banner' ? true : false} maxLength="200" required={this.state.Type == 'Banner' ? true : false} onChange={(e) => this.HandleUpdate(e, 'Banner')} pattern="\s*\S+.*"
                                                    title="space only is not allowed" /></td>
                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2'>Url</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'><span className={'' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.category.image}</span>
                                                    <input type='text' id='IMAGE_URL' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-2 focus:border-redcolor w-96 ' + (this.state.Edit == true ? '' : 'hidden')} defaultValue={this.state.category.image} required={this.state.Type == 'Banner' ? true : false} maxLength={this.state.varchar['LG']} onChange={(e) => this.HandleUpdate(e, 'Banner')} pattern="\s*\S+.*"
                                                        title="space only is not allowed" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* The section for edit and update without banner */}
                                <div className={'mt-5 px-4 ' + (this.state.Type != 'Banner' ? '' : 'hidden')}>
                                    <table className="table-auto">
                                        <tbody>
                                            <tr>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Title {this.state.Type === 'Bottombanner' ? '*' : ''}<span className={'' + (this.state.Type === 'Bottombanner' ? 'hidden' : '')}>& Description *</span></td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'><span className={'' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.category.title}</span>  <input type='text' id='TITLE' className={' pl-2 font-OpenSans font-normal text-sm text-bluecolor border rounded-md  w-96 focus:border-redcolor ' + (this.state.Edit == true ? '' : 'hidden')} defaultValue={this.state.category.title} required={this.state.Type != 'Banner' ? true : false} maxLength={this.state.Type == 'Bottombanner' ? "50" : "15"} onChange={(e) => this.HandleUpdate(e, 'category')} pattern="\s*\S+.*"
                                                    title="space only is not allowed" /></td>
                                            </tr>
                                            <tr className={'' + (this.state.Type === 'Product' ? 'hidden' : '')}>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm align-top w-48 py-2'>Image</td>
                                                <td className='py-2'><div className={'  bg-no-repeat  bg-cover   ' + (this.state.Edit === true ? ' ' : '') + (this.state.Type === 'Bottombanner' ? 'h-36' : 'h-48 w-52 ')} style={{
                                                    backgroundImage: `url(${this.state.Update?.IMAGE_URL})`
                                                }}>
                                                </div>  </td>

                                            </tr>
                                            <tr className={'' + (this.state.Type === 'Bottombanner' ? '' : 'hidden')}>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Description</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2 '><span className={'word-breaks-list ' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.category.description} </span>  <textarea type='text' rows='3' id='DESCRIPTION' className={'resize-none pl-2 font-OpenSans font-normal text-sm text-bluecolor border rounded-md  w-96 focus:border-redcolor ' + (this.state.Edit == true ? '' : 'hidden')} onChange={this.handleBilling} defaultValue={this.state.category.description} maxLength="50" onChange={(e) => this.HandleUpdate(e, 'category')}
                                                    pattern="\s*\S+.*" title="space only is not allowed" /></td>
                                            </tr>
                                            <tr>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Category Id *</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'><span className={'' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.categorys.label}</span>
                                                    <span className={'phone-select order-page custom ' + (this.state.Edit === true ? '  ' : 'hidden')}><Select
                                                        value={this.state.categoryid}
                                                        onChange={this.handleCategory}
                                                        options={this.state.categorylist} isSearchable={false}
                                                    /> </span></td>
                                            </tr>

                                            <tr className={'' + (this.state.Type === 'Product' ? 'hidden' : '')}>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2'>Url *</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'><span className={'' + (this.state.Edit == true ? 'hidden' : '')}>{this.state.category.image}</span>
                                                    <input type='text' id='IMAGE_URL' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-2 focus:border-redcolor w-96 ' + (this.state.Edit == true ? '' : 'hidden')} defaultValue={this.state.category.image} required={this.state.Type == 'Product' ? false : true} maxLength={this.state.varchar['LG']} onChange={(e) => this.HandleUpdate(e, 'category')} pattern="\s*\S+.*"
                                                        title="space only is not allowed" /></td>
                                            </tr>
                                            <tr className={'' + (this.state.Type === 'Bottombanner' ? '' : 'hidden')}>
                                                <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Inactive</td>
                                                <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2 '><span className='ml-2' onClick={() => this.Handlechecked()}>
                                                    <input type="checkbox" className={"form-checkbox   h-4 w-4 text-white my-auto cursor-pointer " + (this.state.inactive === true ? 'order-sync-netsuite' : 'order-sync')} />
                                                    {/* <input type="checkbox" className={"form-checkbox category-check  h-4 w-4  text-white cursor-pointer " + (this.state.inactive === true ? 'checked' : '')} />
                                                <label className='cursor-pointer' onClick={() => this.Handlechecked()}></label> */}
                                                </span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div >
        )
    }
};

export default HomepageCustom;