import React, { Component } from 'react'
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
class NewOrder extends Component {
    constructor(props) {
        super(props);
        this.handleNeworder = this.handleNeworder.bind(this);
        this.state = {
            order: props.total,
            uk: props.uk,
            international: props.international,
            ukpercent: '',
            otherpercent: '',
            styles: {
                height: '60px',
                textAlign: 'center',
                verticalAlign: 'middle',

            },
            ukspan: '',
            internspan: ''

        }
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this.handleNeworder();
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.total !== this.state.order) {
            this.setState({ order: nextProps.total })
            console.log(nextProps.total)
        }
        if (nextProps.uk !== this.state.uk) {
            this.setState({ uk: nextProps.uk })
        }
        if (nextProps.international !== this.state.international) {
            this.setState({ international: nextProps.international })
        }
        if (nextProps.total !== undefined && nextProps.uk !== undefined && nextProps.international !== undefined) {
            this.handleNeworder(nextProps.total, nextProps.uk, nextProps.international);
        }
    }
    /**
* @description The function executed for convert the value in percentage
*/
    handleNeworder(total, uk, international) {
        var State = this.state;
        var ukpercent, international;
        var bguk, bgintern
        if (this.state.order !== 0) {
            ukpercent = (this.state.uk / this.state.order * 100)
            international = (this.state.international / this.state.order * 100)
            this.setState({ ukpercent: ukpercent, otherpercent: international })
            bguk = 'bg-ukblue'
            bgintern = 'bg-otherblue'
        } else {
            ukpercent = 0
            international = 0
            bguk = 'bg-uk_grey'
            bgintern = 'bg-international_grey'
            this.setState({ ukpercent: 50, otherpercent: 50 })
        }
        this.setState({ ukspan: <span className={' px-2 px-10 py-1 rounded-md text-sm font-OpenSans ' + (bguk)}>Uk: {parseFloat(ukpercent).toFixed(2) + '%'}</span> })
        this.setState({ internspan: <span className={' px-2 py-1 rounded-md text-sm font-OpenSans mr-10 ' + (bgintern)}>International: {parseFloat(international).toFixed(2) + '%'}</span> })
        this.forceUpdate()
    }
    render() {
        return (
            <div className=' '>
                <div className='flex'>
                    <h3 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>New Orders </h3>
                    <span className=" font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full h-5 my-auto">Today</span>
                </div>
                <div className={'relative xl:pt-0 pt-5 xl:top-7'}>
                    <h2 className={'py-2 px-2 text-3xl text-redcolor font-NotoSans  text-center mt-6 mb-8 font-bold relative  ' + (this.state.order === 0 ? 'top-1/4' : '')}>{this.state.order}</h2>
                    <div className={'order-chart  flex w-11/12 m-auto h-8 ' + (this.state.order === 0 ? '' : '')}>
                        <Tooltip placement="top" overlay={this.state.ukspan}>
                            <div className={' uk-order  ' + (this.state.ukpercent === 0 ? ' hidden ' : ' text-center ') + (this.state.order === 0 ? ' bg-uk_grey ' : ' bg-ukblue ')} style={{ width: this.state.ukpercent + '%' }}>

                                <span className='font-NotoSans text-white xl:text-base text-xs m-auto block w-max  xl:top-1 top-2 relative ' >{this.state.uk}</span>

                            </div>
                        </Tooltip>

                        <Tooltip placement="top" overlay={this.state.internspan}>
                            <div className={' international-order  ' + (this.state.otherpercent === 0 ? ' hidden ' : ' text-center ') + (this.state.order === 0 ? ' bg-international_grey ' : ' bg-otherblue ')} style={{ width: this.state.otherpercent + '%' }}>

                                <span className='font-NotoSans text-white xl:text-base text-xs pl-1  block xl:top-1 top-2 relative' style={this.state.styles}> {this.state.international}</span>

                            </div>
                        </Tooltip>
                    </div>
                    <div className='w-max ml-auto mt-5 mr-5'>
                        <div className={'flex '}>
                            <span className={'w-3 h-3  my-auto ' + (this.state.order === 0 ? ' bg-uk_grey ' : ' bg-ukblue ')}></span>
                            <span className='text-base font-OpenSans font-normal text-bluecolor pl-2'>UK</span>
                        </div>
                        <div className={'flex '} >
                            <span className={'w-3 h-3  my-auto ' + (this.state.order === 0 ? ' bg-international_grey ' : ' bg-otherblue ')}></span>
                            <span className='text-base font-OpenSans font-normal text-bluecolor pl-2' >International</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
};

export default NewOrder;