
import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all orders
     * @returns all the orders
     */
function listOrdersAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listOrders(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       list{
                         ${detail}
                         
                       }
                         totalCount
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the order information
     */
function listOrderInformationAPI(query, details, itemdetails, paymentdetails) {
    //The details are convert to string
    let detail = details.toString();
    let itemdetail = itemdetails.toString();
    let paymentdetail = paymentdetails.toString();
    console.log(query)
    console.log(detail)
    console.log(itemdetail)
    console.log(paymentdetail)
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchOrderInformation(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                        
                         
                        items{
                        ${itemdetail}
                         
                       } 
                       payment{
                        ${paymentdetail}
                       }
                       }
               
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for update the order
     */
function updateOrderInformationAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateOrderInformation(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        ${detail}
                       }
               
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for Count the orders 
     */
function countOrdersAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    countOrders(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                       }
                 
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for total amount the orders 
     */
function totalAmountOrdersAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    totalAmountOrders(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                       }
                 
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for total amount the orders 
     */
function unsyncedOrderAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    unsyncedOrder(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                         ${detail}
                       }
                 
                }
                }`,
            }
        ),
    })
}

/**
     * @description  The function for find the cronjob status 
     */
function salesHistoryAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/order', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    salesHistory(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        transaction_buckets{
                            ${detail}
                         }
                       }
                 
                }
                }`,
            }
        ),
    })
}
export {
    listOrdersAPI,
    listOrderInformationAPI,
    updateOrderInformationAPI,
    countOrdersAPI,
    totalAmountOrdersAPI,
    unsyncedOrderAPI,
    salesHistoryAPI


}