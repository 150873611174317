import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './User.scss'
import Filter from './UserFilter';
import order from '../../data/Order.json'
import Loader from '../../components/Loader/Loader'
import { Link } from 'react-router-dom';
import store from './../../Store/Store';
import moment from 'moment';
class UserList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Userlist: [],
            DataLength: '',
            pageNumbers: [],
            Count: '',
            counts: 1,
            pageid: 1,
            PageStart: 0,
            PageEnd: 20,
            loading: props.loading,
            PageMin: 0,
            PageMax: 5,
            TableView: 'grid',
            sort: false,
            sortdropdown: false,
            SortbyFilter: props.SortByFilter,
            SortValue: "",
            sorthover: "",
            filtervalue: props.Filterval,
            Cursur: 'none',
            Update: {},
            updates: props.Update,
            List: props.UserList,
            Total: props.Total,
            popstate: false
        }
        this.handlePage = this.handlePage.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSortFilter = this.handleSortFilter.bind(this);
        this.handlesorthover = this.handlesorthover.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
        this.hideOnClickOutside = this.hideOnClickOutside.bind(this);
        this.handlePagecheck = this.handlePagecheck.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Filterval !== this.props.Filterval) {
            if (this.props.Filterval === false) {
                this.setState({ sortdropdown: false });
                this.setState({ sort: false });
            }

        }
    }

    componentWillReceiveProps(nextProps) {

        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading });
        }
        if (nextProps.updates !== this.state.updates) {
            if (window.location.search === '') {
                var update = {
                    username: '',
                    email: '',
                    page: 1
                }
                this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
                document.getElementById("UserName").value = "";
                document.getElementById("Email").value = "";
                this.handlePage(1)
                var usr = [];
                this.setState({ Userlist: usr });
                this.setState({ PageMin: 0, PageMax: 5 });
                this.setState({ PageMin: 0, PageMax: 5 });
            }
        }

        if (nextProps.UserList !== this.state.UserList) {
            this.setState({ Userlist: nextProps.UserList });
            var page = store.getState().urlappend.page
            if (store.getState().urlappend.page != null && store.getState().urlappend.page != undefined) {
                this.setState({ PageStart: ((page - 1) * 20) });
                this.setState({ PageEnd: (20 * page) });
                var Count = Math.ceil(page / 5)
                this.setState({ counts: Count });
                var pagemax = 5 * Count
                var pagemin = pagemax - 5
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                var length = nextProps.UserList.length;
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                if (length < 20) {
                    this.setState({ PageEnd: this.state.PageStart + length });
                    this.setState({ Cursur: 'block' });
                }
            }
        }

        if (nextProps.Total !== this.state.Total) {
            this.setState({ Total: nextProps.Total });
            this.setState({ DataLength: nextProps.Total });
            var length = nextProps.Total;
            var pagenum = [];
            for (let i = 1; i <= Math.ceil(length / 20); i++) {
                pagenum.push(i);
            }
            this.setState({ pageNumbers: pagenum });
            this.setState({ Count: Math.ceil(pagenum.length / 5) });
            if (length < 20) {
                this.setState({ PageEnd: this.state.Total });
                this.setState({ Cursur: 'block' });
            }
        }
    }

    /**
        * @description  Function for subscribing to the store 
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Update: store.getState().urlappend })
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.handleLoad();

        window.onpopstate = e => {
            this.setState({ popstate: true });
            this._isMounted && this.handleLoad();
            this.props.passToFilter(true)
            this.props.passToFilter(false)
        }

    }

    handleLoad() {
        window.addEventListener('click', this.hideOnClickOutside)
        //check the parameters and if the value of the sort is exists applay as sort value
        let params = new URLSearchParams(document.location.search)
        if (params.get("sort")) {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === params.get("sort"))
            this.setState({ SortValue: obj.label });
        } else {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === 'Date_Created_Desc')
            this.setState({ SortValue: obj.label });
        }
        //conver the url parameters as object
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            var page = parseInt(update.page)
            if (update.page != null && update.page != undefined) {
                //check the url and show the corresponding page
                var popstate = this.state.popstate
                if (popstate == false) {
                    this.handlePage(page)
                } else {
                    this.handlePagecheck(page)
                }
                var Count = Math.ceil(page / 5)
                this.setState({ counts: Count });
                var pagemin = this.state.PageMin + (5 * (Count - 1))
                var pagemax = this.state.PageMax + (5 * (Count - 1))
                this.setState({ PageMin: pagemin, PageMax: pagemax });


                if (update.UserName == undefined) {
                    document.getElementById("UserName").value = "";
                } else {
                    document.getElementById("UserName").value = update.UserName;
                }
                if (update.Email == undefined) {
                    document.getElementById("Email").value = "";
                } else {
                    document.getElementById("Email").value = update.Email;
                }
            }
        }
        else {
            var update = {}
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            document.getElementById("UserName").value = "";
            document.getElementById("Email").value = "";
        }
        // if the page in url null execute the page as one
        if (params.get("page") == null) {
            this._isMounted && this.handlePagecheck(1)
        }
        this.setState({ popstate: false })
    }

    /**
        * @description  To handle pages
        * @param {Number} id - Selected record Id
        * @returns page numbers 
    */
    handlePage(id) {
        window.scrollTo(0, 0)
        var self = this;
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id;
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        self.props.history.push({
            pathname: '/user',
            search: "?" + str.toString()
        })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });


    }

    /**
        * @description  To handle pagination
        * @returns data within the page and view limit
    */
    handlePagination(id) {
        var self = this.state;
        var count = this.state.counts;
        var pagemin = this.state.PageMin;
        var pagemax = this.state.PageMax;
        if (id == 'next') {
            if (self.counts < self.Count) {
                pagemin = pagemin + 5
                pagemax = pagemin + 5
                this.setState({ counts: count + 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)
            }
        }
        if (id == 'prev') {
            if (self.counts > self.Count) {
                pagemin = pagemin - 5
                pagemax = pagemax - 5
                this.setState({ counts: count - 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)

            }
        }

    }

    /**
        * @description The function executed for pagination when click the pagenumber
    */
    handlePagecheck(id) {
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;

        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });
    }

    /**
        * @description  Function for toggling table and grid views
    */
    handleView(view) {
        this.setState({ TableView: view });
    }

    /**
        * @description  Function for form submission. it stores the updated details of an User
        * @returns sorted details   
    */
    handleSort() {
        if (this.state.popstate == false) {
            this.setState({ sort: !this.state.sort });
            if (this.state.sortdropdown === false) {
                this.setState({ sortdropdown: !this.state.sortdropdown });
            } else {
                this.setState({ sortdropdown: false });
            }
        }

    }

    /**
        * @description  Function for sorting and filter
        * @returns sorted and filtered users    
    */
    handleSortFilter(value) {
        var sort = this.state.SortbyFilter
        var obj = sort.find(item => item.value === value)
        var update
        this.setState({ SortValue: obj.label }, function () {
            update = store.getState().urlappend;
            update['sort'] = obj.value;
            update = Object.keys(update)
                .filter((k) => update[k] != '')
                .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
            var str = "";
            for (var key in update) {
                if (str != "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(update[key]);
            }
            //dipatch the updated value to store
            store.dispatch({ type: 'urlappend', Urlappend: update })
            this.props.history.push({
                pathname: '/user',
                search: "?" + str.toString()
            })
            this.props.passToParent(true)

            this.props.passToParent(false)
            setTimeout(
                function () {
                    this.setState({ popstate: false });
                    this.handleSort();
                    this.setState({ sortdropdown: false });
                }
                    .bind(this),
                100
            );

        });

        this.setState({ sortdropdown: !this.state.sortdropdown });
    }

    /**
        * @description The function executes outside click
    */
    hideOnClickOutside() {
        if (this.state.sortdropdown == true) {
            this.setState({ popstate: !this.state.popstate });
            this.handleSort();
        }
    }

    handlesorthover(id) {
        this.setState({ sorthover: id });
    }

    render() {
        return (
            <div className={'Order-Main' + (this.state.filtervalue)}>
                < div className='Order-List h-full shadow-shadowblack mt-7 bg-white px-5' >
                    <div className='items count flex'>
                        <div className='item-show mt-3 w-5/12 '>
                            <span className={this.state.Userlist.length === 0 ? 'hidden' : '' + 'font-OpenSans font-bold text-base text-bluecolor'}>Showing {this.state.PageStart + 1} - {this.state.PageEnd} of {this.state.Total} </span><span className='font-OpenSans font-bold text-base text-bluecolor'>Results</span>
                        </div>
                        <div className='item-filter  my-auto mt-3 flex w-7/12'>
                            {/* The grid and list view button */}
                            <div className='w-7/12 xl:w-full '>
                                <div className='w-max flex my-auto bg-bluetablebg px-3 py-1 rounded-lg ml-auto'>
                                    <span className='font-OpenSans font-semibold text-base text-bluecolor'>Sort By</span>
                                    <span className={this.state.sort === true ? 'block' : 'hidden'}><input type='text' className='border-none text-sm font-OpenSans font-normal text-sortblue bg-bluetablebg pl-2' readOnly value={this.state.SortValue} /></span>
                                    <span onClick={() => this.handleSort()}><img src='/static/Icons/sort-by.svg' alt='' className='ml-2 mt-1 cursor-pointer ' /> </span>
                                    <div className={'absolute mt-8 bg-bluetablebg rounded-lg z-20 ' + (this.state.sortdropdown === true && this.state.sort === true ? 'block ' : 'hidden ')}>
                                        <div className='block w-full py-2'>
                                            {this.state.SortbyFilter.map(list =>
                                                <div key={list.id} onMouseMove={() => this.handlesorthover(list.id)} onMouseLeave={() => this.handlesorthover(list)}>
                                                    <div className={' font-OpenSans font-semibold text-sm  py-2 px-4 pr-9 w-64 rounded-lg cursor-pointer  ' + (this.state.sorthover === list.id ? 'text-white sort-by ' : 'text-bluecolor ') + (this.state.SortValue === list.label ? 'sort-by-text' : '')} onClick={() => this.handleSortFilter(list.value)}>{list.label}</div>
                                                    <hr className={'sortfilterborder mx-4 ' + (this.state.sorthover === list.id ? 'hidden ' : 'block ') + ((this.state.SortValue === list.label ? 'hidden' : ''))} ></hr>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-3/12 ml-auto my-auto border-l  xl:hidden'>
                                <div className='w-max ml-auto flex'>
                                    <span className='text-xs font-OpenSans text-bluecolor opacity-80 my-auto'>View :</span>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/grid.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}> <img src='/static/Icons/gridred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}><img src='/static/Icons/list.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/listred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.loading === true ?
                        <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                            <Loader loading={this.state.loading} />
                        </div> :
                        <>
                            {/* The userlist view Desktop */}
                            <div className={'items-text mt-12 pb-6 hidden ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                                <table className="table-auto w-full mt-3 ">
                                    <thead >
                                        <tr className='order-table'>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans  py-3'>
                                            </th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Date</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>User Name</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Email</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Phone</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Last Login Date</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Access</th>
                                            <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Inactive</th>
                                        </tr>
                                    </thead>
                                    <tbody className='mt-2 order-table-body'>
                                        {/* The Foreach of the registered user */}
                                        {this.state.Userlist.map((list, index) => {
                                            return <tr key={index + 1}>
                                                <td className='table-order font-normal text-sm text-bluecolor font-OpenSans p-3'>
                                                    <Link to={'/user/userdetail/' + (list.record_row_id)} >  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' /></Link>
                                                </td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.date_created ? moment(list.date_created).format('DD/MM/YYYY') : ''}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.first_name && list.first_name}{" "}{list.last_name && list.last_name}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.email && list.email}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.phone && list.phone}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.last_login_date && moment(list.last_login_date).format('DD/MM/YYYY')}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.have_access ? 'Yes' : 'No'}</td>
                                                <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.is_inactive ? 'Yes' : 'No'}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {/* The userlist view Mobile */}
                            <div className={'items-text mt-16 pb-6  gap-2 xl:hidden  ' + (this.state.loading === true ? 'hidden ' : 'block ') + (this.state.TableView === 'list' ? ' grid grid-cols-1' : 'grid grid-cols-2')}>
                                {this.state.Userlist.map((list, index) =>
                                    <div key={index + 1} className='order-border-table break-all rounded-xl px-4 py-3 mb-4'>
                                        <div className='grid grid-cols-2 gap-2 my-1'>
                                            <div className='w-max mr-auto pl-2 my-auto'>
                                                <span className='text-bluecolor font-NotoSans font-normal opacity-50 text-xs'>{list.date_created ? moment(list.date_created).format('DD/MM/YYYY') : ''}</span>
                                            </div>
                                            <div className='w-max mr-3 pl-2 my-auto ml-auto'>
                                                <Link to={'/user/userdetail/' + (list.record_row_id)} > <img src='/static/Icons/view.svg' alt='' className='w-max ml-auto' /></Link>
                                            </div>
                                        </div>
                                        <hr className='order-view-table'></hr>
                                        <h2 className='text-redcolor  text-sm font-OpenSans font-semibold mt-3 py-2'> {list.first_name && list.first_name}{" "}{list.last_name && list.last_name}</h2>
                                        <table className="w-full table-order-mobile">
                                            <tbody>
                                                <tr>
                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Email</td>
                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.email && list.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Phone</td>
                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.phone}</td>
                                                </tr>
                                                <tr>
                                                    <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Last Login date</td>
                                                    <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.last_login_date && moment(list.last_login_date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div></>
                    }
                    <div className={'w-max m-auto py-3 ' + (this.state.Userlist.length === 0 ? '' : 'hidden')}>
                        <span className=' font-OpenSans text-bluecolor   text-sm font-semibold'>-- No Result Found --</span>
                    </div>
                </div >
                {this.state.Userlist.length > 0 &&
                    <div className={'w-max mt-5 m-auto px-5 flex ' + (this.state.PageMin) + (this.state.PageMax)}>
                        <span className={'font-OpenSans text-xs font-regular border border-bluecolor  px-1   flex ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('prev')}> <img src='/static/Icons/arrowleft.svg' alt='' className='w-5' /> </span>
                        {this.state.pageNumbers.slice(this.state.PageMin, this.state.PageMax).map(list =>
                            <span key={list} className={'font-OpenSans text-xs font-regular border border-bluecolor  px-3 py-1  ' + (parseInt(this.state.Update.page) === list ? 'text-white active-page ' : 'text-bluecolor ') + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePage(list)}> {list}</span>
                        )}
                        <span className={'font-OpenSans flex text-xs font-regular border border-bluecolor  px-1   ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('next')}> <img src='/static/Icons/arrowright.svg' alt='' className='w-5 flex' /> </span>
                    </div>
                }
            </div >
        )
    }
};

export default UserList;