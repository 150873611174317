import React, { Component } from 'react';
import store from './../../Store/Store'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import SalesReport from "./SalesReport"
import NetAmount from './NetAmount'
import dashboard from './../../data/Dashboard.json'
import './Dashboard.scss'
import local from './../../global/js/locale';
import SyncStatus from './SyncStatus'
import AddedProducts from './AddedProducts'
import CreatedOrders from './CreatedOrders'
import NewOrder from './NewOrder'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { countOrdersAPI, totalAmountOrdersAPI, unsyncedOrderAPI, salesHistoryAPI } from './../../API/Order'
import { cronJobStatusAPI } from './../../API/Cronjob'
import { listProductsAPI } from './../../API/Product'
import { listOrdersAPI } from './../../API/Order'
import Loader from './../../components/Loader/Loader'
import RefundList from './RefundList';
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: '',
            uk: '',
            international: '',
            totalamount: '',
            totalcount: '',
            ukTotal: 0,
            internationalTotal: 0,
            Series: [],
            Categories: [],
            menuid: store.getState().todos,
            loading: false,
            totalunsyc: '',
            ProductList: [],
            listorder: [],
            salesHistory: [],
            YearWise: [],
            MonthWise: [],
            Monthlist: [],
            YearList: [],
            totalcronjob: {
                "pending": 0,
                "finished": 0,
                "processing": 0,
                "failed": 0,
                "count": 0
            },
            RefundList: [],
            loading1: false,
            loading2: false,
            loading3: false,
            loading4: false,
            loading5: false,
            loading6: false,
            loading7: false,


        }
        this.handleSales = this.handleSales.bind(this);
        this.HandleString = this.HandleString.bind(this);
        this.HandleCount = this.HandleCount.bind(this);
        this.HandleSales = this.HandleSales.bind(this);
        this.HandleRefundOrders = this.HandleRefundOrders.bind(this);
        this.HandleSalesHistory = this.HandleSalesHistory.bind(this);
        this.HandleSalesHistorymonth = this.HandleSalesHistorymonth.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
    }
    /**
* @description The function executed redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        this.HandleCount()
        this.HandlePrice()
        this.Handleunsync()
        this.HandleProducts()
        this.HandleOrders()
        this.HandleSales()
        this.HandleRefundOrders()
    }
    handleSales(id) {
        this.setState({ orderid: id })
    }
    /**
   * @description The function executed fetch the count of orders
   */
    HandleCount() {
        var self = this
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
            }
            query = this.HandleString(query)
            self.setState({ loading1: true })
            var details = [
                "ukCount",
                "internationalCount",
                "totalCount"
            ];
            //api for list orders
            countOrdersAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.countOrders?.summary?.reason;
                    let status = res?.data?.countOrders?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading1: false })
                        let total = res.data.countOrders.details.totalCount;
                        let ukcount = res.data.countOrders.details.ukCount;
                        let internationalCount = res.data.countOrders.details.internationalCount;
                        self.setState({ order: total })
                        self.setState({ uk: ukcount })
                        self.setState({ international: internationalCount })
                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }

    }
    /**
* @description The function executed fetch the price of orders
*/
    HandlePrice() {
        var self = this
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
            }
            query = this.HandleString(query)
            self.setState({ loading2: true })
            var details = [
                "ukTotal",
                "internationalTotal",
                "totalAmount"
            ];
            //api for list orders
            totalAmountOrdersAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.totalAmountOrders?.summary?.reason;
                    let status = res?.data?.totalAmountOrders?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading2: false })
                        let totalamount = res.data.totalAmountOrders.details.totalAmount;
                        let ukTotal = res.data.totalAmountOrders.details.ukTotal;
                        let internationalTotal = res.data.totalAmountOrders.details.internationalTotal;
                        self.setState({ totalamount: totalamount })
                        self.setState({ ukTotal: ukTotal })
                        self.setState({ internationalTotal: internationalTotal })
                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }

    }
    /**
* @description The function executed fetch the unsync of orders
*/
    Handleunsync() {
        var self = this
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
            }
            query = this.HandleString(query)
            self.setState({ loading3: true })
            var details = [
                "totalCount",
                "ukCount",
                "internationalCount"
            ];
            //api for list orders
            unsyncedOrderAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.unsyncedOrder?.summary?.reason;
                    let status = res?.data?.unsyncedOrder?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading3: false })
                        let totalunsyc = res.data.unsyncedOrder.details.totalCount;
                        self.setState({ totalunsyc: totalunsyc })
                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }

    }
    /**
* @description The function executed fetch the latest 5 products
*/
    HandleProducts() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['SORT'] = "Date_Modified_Desc"
            newupdate['limit'] = "5"
            newupdate['offset'] = "0"
            var query = this.HandleString(newupdate)
            var self = this
            self.setState({ loading4: true })
            var details = [
                "record_row_id",
                "date_modified",
                "date_created",
                "sku",
                "wine_color",
                "wine_vintage",
                "wine_country",
                "wine_region",
                "wine_subregion",
                "quantity_available",
                "wine_bottlesize",
                "wine_packsize",
                "price_markup_percentage",
                "price_online",
                "name"
            ];
            //api for list orders
            listProductsAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.listProducts?.summary?.reason;
                    let status = res?.data?.listProducts?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading4: false })
                        let data = res.data.listProducts.details.list;
                        self.setState({ ProductList: data });

                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        } else {
            this.GoToPage("/");
        }

    }
    //     /**
    // * @description The function executed fetch the sales history
    // */
    //     HandleCronJob() {
    //         var self = this
    //         var email;
    //         var token;
    //         if (localStorage.getItem("wn_log") !== null) {
    //             let login = JSON.parse(localStorage.getItem("wn_log"));
    //             token = login.access_token;
    //             email = login.email;
    //             var query = {
    //                 "EMAIL": email,
    //                 "TOKEN": token,
    //             }
    //             query = this.HandleString(query)
    //             self.setState({ loading: true })
    //             var details = [
    //                 "pending",
    //                 "finished",
    //                 "processing",
    //                 "failed",
    //                 "count",
    //             ];
    //             //api for list orders
    //             cronJobStatusAPI(query, details)
    //                 .then((res) => res.json())
    //                 .then(function (res) {

    //                     let reason = res?.data?.cronJobStatus?.summary?.reason;
    //                     let status = res?.data?.cronJobStatus?.summary?.status;
    //                     if (reason == "RECORD_FOUND" && status == "SUCCESS") {
    //                         let totalcronjob = res.data.cronJobStatus.details;
    //                         self.setState({ totalcronjob: totalcronjob })
    //                     } else {
    //                         // eraseCookie('wn_log');
    //                         // localStorage.removeItem("wn_log");
    //                         // self.props.history.push("/");
    //                     }
    //                 });
    //         }

    //     }
    /**
* @description The function executed fetch the sales history
*/
    HandleSales() {
        var self = this
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
            }
            query = this.HandleString(query)
            self.setState({ loading5: true })
            var details = [
                "transaction_sum",
                "transaction_period",
                "transaction_month",
                "transaction_year",
            ];
            //api for list orders
            salesHistoryAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.salesHistory?.summary?.reason;
                    let status = res?.data?.salesHistory?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        let salesHistory = res.data.salesHistory.details.transaction_buckets;
                        setTimeout(function () {
                            self.setState({ loading5: false })
                        }, 1000);
                        self.setState({ salesHistory: salesHistory })
                        self.HandleSalesHistory(salesHistory)
                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }

    }
    /**
* @description The function For convert sales history
*/
    HandleSalesHistory(salesHistory) {
        console.log(salesHistory)
        const years = (back) => {
            const year = new Date().getFullYear();
            return Array.from({ length: back }, (v, i) => year - back + i + 1);
        }
        let yearlist = years(5)
        var filteredlist = []
        yearlist.forEach(element => {
            var sum;
            let obj = salesHistory.filter(item => item.transaction_year.indexOf(JSON.stringify(element)) !== -1);
            if (obj.length != 0) {
                sum = obj.reduce(function (cnt, o) { return cnt + o.transaction_sum; }, 0)
                sum = parseFloat(sum).toFixed(2)
            }
            else {
                sum = 0
            }
            filteredlist.push(sum)

        });
        this.setState({ YearWise: filteredlist })
        this.setState({ YearList: yearlist })
        var d = new Date();
        let monthlists
        var currentmonth = d.getMonth();
        if (currentmonth < 12) {
            monthlists = years(2)
        } else {
            monthlists = years(1)
        }
        this.HandleSalesHistorymonth(monthlists, salesHistory)

    }
    HandleSalesHistorymonth(monthlists, salesHistory) {
        var d = new Date();
        var currentmonth = d.getMonth() + 2
        let months = [];
        var currentTime = new Date()
        var year = currentTime.getFullYear()
        var monthsales = [];
        var monthlist = [];
        for (var i = 0; i < 12; i++) {
            if (currentmonth === 1) {
                var year = currentTime.getFullYear() - 1;
                currentmonth = 13
            }
            var monthname;
            currentmonth--
            if (currentmonth === 1) {
                monthname = "Jan"
            } else if (currentmonth === 2) {
                monthname = "Feb"
            }
            else if (currentmonth === 3) {
                monthname = "Mar"
            }
            else if (currentmonth === 4) {
                monthname = "Apr"
            }
            else if (currentmonth === 5) {
                monthname = "May"
            }
            else if (currentmonth === 6) {
                monthname = "Jun"
            }
            else if (currentmonth === 7) {
                monthname = "Jul"
            }
            else if (currentmonth === 8) {
                monthname = "Aug"
            }
            else if (currentmonth === 9) {
                monthname = "Sep"
            }
            else if (currentmonth === 10) {
                monthname = "Oct"
            }
            else if (currentmonth === 11) {
                monthname = "Nov"
            }
            else if (currentmonth === 12) {
                monthname = "Dec"
            }
            let yearlastdigit = year % 100
            monthlist.push(monthname + '-' + yearlastdigit)

            var list;
            if (parseInt(currentmonth) < 10) {
                list = "0" + currentmonth
            } else {
                list = currentmonth
            }
            months.push(year + '-' + list)

        }

        months.forEach(element => {
            var sum;
            let obj = salesHistory.filter(item => item.transaction_period.indexOf(element) !== -1);
            if (obj.length != 0) {
                sum = obj.reduce(function (cnt, o) { return cnt + o.transaction_sum; }, 0)
                sum = sum
            }
            else {
                sum = 0
            }
            monthsales.push(sum)
        });
        this.setState({ MonthWise: monthsales.reverse() })
        this.setState({ Monthlist: monthlist.reverse() })
        this.Handlechange(1)
        console.log(monthsales, monthlist)
    }
    Handlechange(value) {
        var id = value
        if (id === 1) {
            this.setState({ Categories: this.state.Monthlist, Series: this.state.MonthWise })
            // this.setState({ Series: this.state.MonthWise })
        } else {
            this.setState({ Categories: this.state.YearList, Series: this.state.YearWise })
            // this.setState({ Series: this.state.YearWise })
        }
    }
    /**
* @description The function executed fetch the latest 5 orders
*/
    HandleOrders() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            // newupdate['STATUS'] = "BILLED"
            newupdate['SORT'] = "Date_Desc"
            newupdate['limit'] = "5"
            newupdate['offset'] = "0"
            var query = this.HandleString(newupdate)
            var self = this;
            self.setState({ loading6: true })
            var details = [
                "record_row_id",
                "date_created",
                "date_modified",
                "is_inactive",
                "customer_name",
                "status",
                "sync_with_netsuite",
                "transaction_total",
                "transaction_number",
                "transaction_date"
            ];
            //api for list orders
            listOrdersAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.listOrders?.summary?.reason;
                    let status = res?.data?.listOrders?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading6: false })
                        let data = res.data.listOrders.details.list;
                        console.log(data)
                        self.setState({ listorder: data });

                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        } else {
            this.GoToPage("/");
        }

    }
    /**
* @description The function executed fetch the latest 5 orders
*/
    HandleRefundOrders() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            // newupdate['ORDER_RESOLVED'] = "false"
            newupdate['STATUS'] = "PROCESSING"
            newupdate['SORT'] = "Date_Desc"
            newupdate['limit'] = "5"
            newupdate['offset'] = "0"
            var query = this.HandleString(newupdate)
            var self = this;
            self.setState({ loading7: true })
            var details = [
                "record_row_id",
                "date_created",
                "date_modified",
                "is_inactive",
                "customer_name",
                "status",
                "sync_with_netsuite",
                "transaction_total",
                "transaction_number",
                "transaction_date"
            ];
            //api for list orders
            listOrdersAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.listOrders?.summary?.reason;
                    let status = res?.data?.listOrders?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        self.setState({ loading7: false })
                        let data = res.data.listOrders.details.list;
                        let totalcount = res.data.listOrders.details.totalCount
                        console.log(data)
                        self.setState({ RefundList: data });
                        self.setState({ totalcount: totalcount });

                    } else {
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        } else {
            this.GoToPage("/");
        }

    }
    /**
   * @description The function executed for cancel the edit
   */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            SORT: "SORT",

        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", limit: "limit", offset: "offset", ORDER_RESOLVED: "ORDER_RESOLVED" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            <div className='Dashboard '>
                {/* Breadthcrum of the Dashboard */}
                <Breadthcrumbs Breadthcrumb={'Dashboard'} />

                <div className={'Dashboard-content px-5 py-2  h-full '}>
                    <div className=' m-auto grid xl:grid-cols-3 grid-cols-2 gap-4'>
                        {/* The new order section of wineye Dashboard */}
                        <div className='new-orders bg-white shadow-shadowblack '>
                            {this.state.loading1 === true ?
                                <div >
                                    <div className='flex'>
                                        <h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>New Orders </h2>
                                        <span className=" font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full h-5 mt-3">Today</span>
                                    </div>
                                    <div className={this.state.loading1 ? ' w-max m-auto block h-64  ' : ''}>
                                        <Loader loading={this.state.loading1} />
                                    </div>
                                </div> :
                                <NewOrder uk={this.state.uk} international={this.state.international} total={this.state.order} />
                            }
                        </div>
                        {/* The NetAmount Section of  wineye Dashboard */}
                        <div className='net-amount bg-white shadow-shadowblack'>
                            {this.state.loading2 === true ?
                                <div >
                                    <div className='flex'>
                                        < h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold' > Total Net Amount</h2 >
                                        <span className=" font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full h-5 mt-3">Today</span>
                                    </div>
                                    <div className={this.state.loading2 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading2} />
                                    </div>
                                </div > :
                                <NetAmount NetAmount={[this.state.ukTotal, this.state.internationalTotal]} total={this.state.totalamount} Currency={local.locale.CURRENCY.SYMBOL} />
                            }
                        </div>
                        {/* Desktopview of unsync orders */}
                        <div className='status bg-white shadow-shadowblack xl:block hidden' >
                            {/* The SyncStatus Section of  wineye Dashboard */}
                            {this.state.loading3 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Sync Status</h2>
                                    <div className={this.state.loading3 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading3} />
                                    </div>
                                </div> :
                                < SyncStatus totalunsyc={this.state.totalunsyc} />
                            }
                        </div>
                    </div>
                    <div className='m-auto xl:grid  xl:grid-cols-2 mt-8 xl:gap-4 flex'>
                        {/* Tabview of unsync orders */}
                        <div className='status bg-white shadow-shadowblack block xl:hidden w-2/7' >
                            {this.state.loading3 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Sync Status</h2>
                                    <div className={this.state.loading3 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading3} />
                                    </div>
                                </div> :

                                < SyncStatus totalunsyc={this.state.totalunsyc} />

                            }
                        </div>
                        <div className='sales-history bg-white shadow-shadowblack xl:w-full w-5/7 ml-auto ' >
                            {this.state.loading5 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-lg text-base text-bluecolor font-OpenSans font-semibold'>Sales History</h2>
                                    <div className={this.state.loading5 ? ' w-max m-auto block   h-96 ' : ''}>
                                        <Loader loading={this.state.loading5} />
                                    </div>
                                </div> :
                                <SalesReport Series={this.state.Series} Categories={this.state.Categories} Report={dashboard.Report} passToParent={this.Handlechange} />
                            }
                        </div>
                        {/* CronJobStatus Desktop view */}
                        <div className='Cronjobs bg-white shadow-shadowblack xl:block hidden' >
                            {this.state.loading7 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-lg text-base text-bluecolor font-OpenSans font-semibold'>To be Processed</h2>
                                    <div className={this.state.loading7 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading7} />
                                    </div>
                                </div> :
                                <RefundList CreatedOrders={this.state.RefundList} totalcount={this.state.totalcount} />
                            }
                        </div>
                    </div>
                    {/* CronJobStatus tab view */}
                    <div className='Cronjobs bg-white shadow-shadowblack xl:hidden block my-8 ' >
                        {this.state.loading7 === true ?
                            <div>
                                <h2 className='py-2 px-2 pl-4 xl:text-lg text-base text-bluecolor font-OpenSans font-semibold'>To be Processed</h2>
                                <div className={this.state.loading7 ? ' w-max m-auto block   h-96' : ''}>
                                    <Loader loading={this.state.loading7} />
                                </div>
                            </div> :
                            <RefundList CreatedOrders={this.state.RefundList} totalcount={this.state.totalcount} />
                        }
                    </div>
                    <div className='grid  xl:grid-cols-2   my-8 gap-4  grid-cols-1'>
                        {/* The AddedProducts Section table of  wineye Dashboard */}
                        <div className='bg-white py-4 shadow-shadowblack'>
                            {this.state.loading4 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Recently Modified Products</h2>
                                    <div className={this.state.loading4 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading4} />
                                    </div>
                                </div> :
                                <AddedProducts AddedProducts={this.state.ProductList} />
                            }
                        </div>
                        {/* The CreatedOrders Section table of  wineye Dashboard */}
                        <div className='bg-white py-4 shadow-shadowblack'>
                            {this.state.loading6 === true ?
                                <div>
                                    <h2 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Recently Created Orders</h2>
                                    <div className={this.state.loading6 ? ' w-max m-auto block   h-96' : ''}>
                                        <Loader loading={this.state.loading6} />
                                    </div>
                                </div> :
                                <CreatedOrders CreatedOrders={this.state.listorder} />
                            }
                        </div>
                    </div>
                </div>


            </div >

        )
    }
};

export default Dashboard;