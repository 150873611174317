
import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all customers
     * @returns all the customers
     */
function listCustomersAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/customer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    listCustomers(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       list{
                         ${detail}
                         
                       }
                         totalCount
                       }
                         
                
                }
                }`,
            }
        ),
    })
}
/**
     * @description  The function for list the all customers information
     * @returns all the customers information
     */
function fetchCustomerInformationAPI(query, details, orderdetails, cartdetails, customerdetails) {
    //The details are convert to string
    let detail = details.toString();
    let orderdetail = orderdetails.toString();
    let cartdetail = cartdetails.toString();
    let customerdetail = customerdetails.toString();
    return fetch(BASEURL + 'graphql/customer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchCustomerInformation(${query})
                     {
                         summary{
                             status
                             reason
                             }
                             details
                             {
                            ${detail}
                              order_history{
                               ${orderdetail}
                              }
                              cart{
                               ${cartdetail}
                              }
                              customer_address{
                                ${customerdetail}
                                
                             }
                            }
                              
                  
                  
                      }
                      }`,
            }
        ),
    })
}
/**
     * @description  The function for update the customer information
     * @returns all updated customers information
     */
function updateCustomerProfileAPI(query, details,) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/customer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateCustomerProfile(${query})
                     {
                         summary{
                             status
                             reason
                             }
                             details
                             {
                                ${detail}
                            }
                              
                  
                  
                      }
                      }`,
            }
        ),
    })
}
/**
     * @description  The function for update the customer address
     * @returns all updated customer address
     */
function updateCustomerAddressAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/customer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateCustomerAddress(${query})
                     {
                         summary{
                             status
                             reason
                             }
                             details
                             {
                                ${detail}
                            }
                              
                  
                  
                      }
                      }`,
            }
        ),
    })
}

export {
    listCustomersAPI,
    fetchCustomerInformationAPI,
    updateCustomerProfileAPI,
    updateCustomerAddressAPI


}