import React, { Component } from 'react';
import local from '../../global/js/locale';
import Select from 'react-select'
import store from '../../Store/Store'
import { Link } from 'react-router-dom';
import CountryCode from './../../data/countrycode.json'
import { convertDate, convertDatetime } from './../../global/js/browserfunction'
import { CurrencyFormat } from './../../global/js/browserfunction'
import moment from 'moment';
class CustomerInfo extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: store.getState().edit,
            Status: props.Status,
            CurrentCustomer: '',
            Item: 1,
            ItemList: [],
            Cartitems: [],
            customerdetails: props.customerdetails,
            orderlist: props.orderlist,
            cartlist: props.cartlist,
            customeraddresslist: props.customeraddresslist,
            id: props.Id,
            varchar: local.VARCHAR,
            Int: local.INT,
            selectedOption: { value: "Active", label: 'Active', id: 1 },
            Status: props.Status,
            Currency: local.locale.CURRENCY.SYMBOL,
            symbolweight: local.locale.WEIGHT.SYMBOL,
            defualtbilling: {},
            defualtshipping: {},
            Address: [],
            haveaccess: '',
            inactive: '',
            codephone: '',
            FORMAT: local.locale.DATE.FORMAT,
        }
        this.HandleEdit = this.HandleEdit.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleItem = this.handleItem.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.HandleconvertCode = this.HandleconvertCode.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        var update = store.getState().update;
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.customerdetails !== this.state.customerdetails) {
            this.setState({ customerdetails: nextProps.customerdetails })
            var countrycode = CountryCode.CountryCode
            if (nextProps.customerdetails.phone != null) {
                var code_phone = this.HandleconvertCode(nextProps.customerdetails.phone)
                let obj_phone
                if (code_phone !== "undefined" && code_phone !== undefined) {
                    obj_phone = countrycode.find(o => o.code === code_phone);
                }
                // else {
                //     obj_phone = countrycode.find(o => o.code === "+44");
                //     let phonenumber = obj_phone.code + ' ' + this.Handleconvert(nextProps.customerdetails.phone)
                //     console.log(phonenumber)
                //     update['PHONE'] = phonenumber;
                // }
                // console.log(obj_phone)
                this.setState({ codephone: obj_phone });
            } else {
                // let obj_phone
                // obj_phone = countrycode.find(o => o.code === "+44");
                // this.setState({ codephone: obj_phone });
            }
            if (nextProps.customerdetails.have_access === null) {
                this.setState({ haveaccess: false })
            } else {
                this.setState({ haveaccess: nextProps.customerdetails.have_access })
            }
            if (nextProps.customerdetails.is_inactive === null) {
                this.setState({ inactive: false })
            } else {
                this.setState({ inactive: nextProps.customerdetails.is_inactive })
            }
        }
        if (nextProps.orderlist !== this.state.orderlist) {
            this.setState({ orderlist: nextProps.orderlist })

        }
        if (nextProps.cartlist !== this.state.cartlist) {
            this.setState({ cartlist: nextProps.cartlist })

        }
        if (nextProps.customeraddresslist !== this.state.customeraddresslist) {
            this.setState({ customeraddresslist: nextProps.customeraddresslist })
            console.log(nextProps.customeraddresslist)
            var address = nextProps.customeraddresslist
            var Address = []
            var ShippingAddress, BillingAddress
            address.forEach(list => {
                if (list.default_billing == true) {
                    if (list.default_shipping == true) {
                        this.setState({ defualtshipping: list });
                        ShippingAddress = list
                    }
                    this.setState({ defualtbilling: list });
                    BillingAddress = list
                } else if (list.default_shipping == true) {
                    if (list.default_billing == true) {
                        this.setState({ defualtbilling: list });
                        BillingAddress = list
                    }
                    this.setState({ defualtshipping: list });
                    ShippingAddress = list
                }
                Address.push(list)

            });
            this.setState({ Address: Address });
            console.log(ShippingAddress, BillingAddress, Address)

        }



    }
    /**
* @description The function executed for convert phone number
*@param input-phone number
*/
    Handleconvert(phone) {
        if (phone != undefined) {
            return phone.substr(phone.indexOf(" ") + 1)
        }

    }
    /** 
   * * @description The function executed for convert phone number to code
*@param input-phone number
*/
    HandleconvertCode(phone) {
        if (phone != undefined) {
            return phone.substr(0, phone.indexOf(" "));
        }

    }
    /**
* @description The function executed for change the country code and phone number
*/
    handleCode = selectedOption => {
        this.setState({ codephone: selectedOption });
        var update = store.getState().update;
        let phone = document.getElementById("PHONE").value
        let phonenumber = selectedOption.code + ' ' + phone
        update['PHONE'] = phonenumber;
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)

    };
    /**
* @description The function executed change the value from store 
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }
    /**
* @description The function executed for remove the component dispatch the value to store
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var id = this.state.id;
        this.setState({ Codes: CountryCode.CountryCode });

    }
    /**
* @description The function executed cancel the edit of the page
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        var edit = !this.state.Edit;
        if (edit === false) {
            var update = {}
            store.dispatch({ type: 'Update', Update: update })
            this.props.passToParent(false)
        }

    }
    /**
* @description The function executed change form the form input box
*/
    handleUpdate(e) {
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        if (key == 'PHONE') {
            update['PHONE'] = this.state.codephone.code + ' ' + value
        } else {
            update[key] = value;
        }
        store.dispatch({ type: 'Update', Update: update })
        console.log(update)



    }
    /**
* @description The function executed for change the state of active and inactive
*/
    handleToggle(e) {
        var self = this;
        var update = store.getState().update
        if (e.target.children.inactive != undefined) {
            var inactive = !e.target.children.inactive.checked
            self.setState({ inactive: inactive })
            update['IS_INACTIVE'] = JSON.stringify(!e.target.children.inactive.checked);
            store.dispatch({ type: 'Update', Update: update })

        }
        else if (e.target.children.haveaccess != undefined) {
            self.setState({ haveaccess: !e.target.children.haveaccess.checked })
            update['HAVE_ACCESS'] = JSON.stringify(!e.target.children.haveaccess.checked);
            store.dispatch({ type: 'Update', Update: update })
        }
        console.log(update)
    }
    /**
* @description The function executed changes from the select box
*/
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        var update = store.getState().update;
        update['Status'] = selectedOption.value;
        store.dispatch({ type: 'Update', Update: update })
    };
    handleItem(id) {
        this.setState({ Item: id })
    }
    render() {
        return (
            < div className='Customer-No h-full ' >
                <div className='py-5 px-6 bg-white shadow-shadowblack'>
                    <div className='w-full flex px-5'>
                        <div className='w-8/12 flex'>
                            <img src='/static/Icons/target.svg' alt='' className='w-7' />
                            <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-2'>{this.state.customerdetails.first_name + ' ' + this.state.customerdetails.last_name}</h1>
                        </div>
                        <div className={'w-4/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                            <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                        </div>
                        <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                            <div className='buttons w-max flex ml-auto h-8'>
                                <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                    <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                        <div className='flex'>
                                            <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                            <span className='ml-2 mx-auto'>Save</span>
                                        </div>
                                    </button>
                                </div>

                                <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                            </div>
                        </div>
                    </div>
                    <hr className='sortfilterborder my-4' />
                    <div className='flex mx-2'>
                        <div className='xl:flex  px-4 w-1/2'>
                            <div className='xl:w-1/2 w-full'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-2'>Date Created &nbsp;&nbsp;</span> <span className='font-normal text-sm text-bluecolor font-OpenSans'> {this.state.customerdetails.date_created !== '' && this.state.customerdetails.date_created !== null ? convertDatetime(this.state.customerdetails.date_created) : ''}</span>
                            </div>
                            <div className='xl:w-1/2 w-full'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-1'>Date Modified  &nbsp;&nbsp; </span> <span className='font-normal text-sm text-bluecolor font-OpenSans'> {this.state.customerdetails.date_modified !== '' && this.state.customerdetails.date_modified !== null ? convertDatetime(this.state.customerdetails.date_modified) : ''}</span>
                            </div>

                        </div>
                        <div className='xl:flex  px-4 w-1/2 '>
                            <div className='xl:w-1/2 w-full'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:mr-2 mr-9'>Inactive &nbsp;&nbsp;</span> <span onClick={(e) => this.handleToggle(e)} className='cursor-pointer '><input type="checkbox" id={(this.state.Edit === true ? 'inactive' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white ml-2 " + (this.state.Edit === true ? 'cursor-pointer' : '')} defaultValue={this.state.inactive} checked={this.state.inactive} readOnly={!this.state.Edit} /></span>
                            </div>
                            <div className='xl:w-1/2 w-full'>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor mr-2    xl:mr-0'>Have Access  &nbsp;&nbsp; </span><span onClick={(e) => this.handleToggle(e)} className='cursor-pointer '><input type="checkbox" id={(this.state.Edit === true ? 'haveaccess' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white ml-2 " + (this.state.Edit === true ? 'cursor-pointer' : '')} defaultValue={this.state.haveaccess} checked={this.state.haveaccess} readOnly={!this.state.Edit} /></span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='Customer-details py-5 px-6 bg-white shadow-shadowblack mt-5'>
                    <div className='w-full  px-5'>
                        <div className='w-8/12 flex'>
                            <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto '>Details</span>
                        </div>

                    </div>
                    <hr className='sortfilterborder my-4' />
                    <div className='grid xl:grid-cols-2  grid-cols-1 gap-2 py-5 px-6'>
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>First Name *</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'><span className={'  ' + (this.state.Edit === true ? ' hidden' : '')}> {this.state.customerdetails.first_name}</span>
                                        <input type='text' name='FirstName' id='FIRST_NAME' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-60  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customerdetails.first_name} required maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" /></td>
                                </tr>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Last Name *</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'><span className={'  ' + (this.state.Edit === true ? ' hidden' : '')}>{this.state.customerdetails.last_name}</span><input type='text' name='FirstName' id='LAST_NAME' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-60  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customerdetails.last_name} required maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" /></td>
                                </tr>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Company Name</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2 '><span className={'  ' + (this.state.Edit === true ? ' hidden' : '')}>{this.state.customerdetails.company_name}</span> <input type='text' id='COMPANY_NAME' name='CompanyName' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-60  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.state.customerdetails.company_name} maxLength={this.state.varchar['LG']} /></td>
                                </tr>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Email *</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'><span className={'  '}>{this.state.customerdetails.email}</span> </td>
                                </tr>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Phone No.</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'><span className={'  ' + (this.state.Edit === true ? ' hidden' : '')}>{this.state.customerdetails.phone}</span><span className={'phone-select order-page ' + (this.state.Edit === true ? ' flex ' : 'hidden')}><Select
                                        value={this.state.codephone}
                                        onChange={this.handleCode}
                                        options={this.state.Codes} isSearchable={false}
                                    /> <input type='text' name='PhoneNumber' id='PHONE' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-2 focus:border-redcolor w-36 ml-1  ' + (this.state.Edit === true ? ' ' : 'hidden')} onInput={this.handleUpdate} defaultValue={this.Handleconvert(this.state.customerdetails.phone)} maxLength={this.state.varchar['LG']} minLength="8" maxLength="15" pattern='^(?!0*$)[0-9-]+' required={this.state.codephone !== undefined && this.state.codephone !== '' ? true : false} /></span></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="w-full customerheight">
                            <tbody>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Last Login Date</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'>{this.state.customerdetails.last_login_date !== '' && this.state.customerdetails.last_login_date !== null ? convertDatetime(this.state.customerdetails.last_login_date) : ''}</td>
                                </tr>
                                <tr>
                                    <td className='font-OpenSans font-bold  text-bluecolor text-sm w-48 py-2'>Last Password Reset Date</td>
                                    <td className='font-OpenSans font-semibold  text-bluecolor text-sm py-2'>{this.state.customerdetails.last_password_reset_date !== '' && this.state.customerdetails.last_password_reset_date !== null ? convertDatetime(this.state.customerdetails.last_password_reset_date) : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-5'>
                        <div className='item-subsection flex'>
                            <div className='flex'>
                                <span className={' font-OpenSans font-semibold  text-bluecolor text-xl  my-auto cursor-pointer ' + (this.state.Item == 1 ? 'text-redcolor' : 'text-bluecolor')} onClick={() => this.handleItem(1)}> &nbsp; &nbsp;&nbsp;Order History &nbsp; &nbsp;&nbsp;</span>
                                <img src='/static/Icons/Line.svg' alt='' className='h-7 w-2' />
                            </div>
                            <div className='flex'>
                                <span className={' font-OpenSans font-semibold  text-bluecolor text-xl  my-auto cursor-pointer ' + (this.state.Item == 2 ? 'text-redcolor' : 'text-bluecolor')} onClick={() => this.handleItem(2)}> &nbsp; &nbsp;&nbsp;Address &nbsp; &nbsp;&nbsp;</span>
                                <img src='/static/Icons/Line.svg' alt='' className='h-7 w-2' />
                            </div>
                            <div className='flex'>
                                <span className={'font-OpenSans font-semibold  text-bluecolor text-xl  my-auto cursor-pointer ' + (this.state.Item == 3 ? 'text-redcolor' : 'text-bluecolor')} onClick={() => this.handleItem(3)}> &nbsp; &nbsp;&nbsp;Cart &nbsp; &nbsp;&nbsp;</span>
                            </div>


                        </div>
                        <div className='mt-5'>
                            <div className={'' + (this.state.Item == 1 ? '' : 'hidden')} >
                                <table className={"w-full " + (this.state.orderlist.length == 0 ? 'hidden' : '')}>
                                    <thead>
                                        <tr className='order-table Customer-view border-t border-b'>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'></th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm'>Date</th>
                                            <th className='text-center  font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm w-44 xl:w-max'>Order Number</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm'>Amount</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className={'order-table-body ' + (this.state.Items)}>
                                        {this.state.orderlist.map(list =>
                                            <tr key={list.record_row_id}>
                                                <td className=' py-2 pl-2 font-OpenSans font-semibold text-sm w-20'><Link to={'/order/orderdetail/' + (list.record_row_id)} className='text-redcolor underline'> <img src='/static/Icons/view.svg' alt='' className='m-auto' /> </Link></td>
                                                <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm w-48'>{convertDate(list.transaction_date)}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm'>{list.transaction_number}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(list.transaction_total)}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{list.status}</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                <div className={'w-max ml-auto mt-5 mr-5 ' + (this.state.orderlist.length === 0 ? 'hidden' : '')}>
                                    <Link to={'/order?email=' + (encodeURIComponent(this.state.customerdetails.email))} className='text-right '>
                                        <span className='font-OpenSans font-normal  text-redcolor text-sm  my-auto cursor-pointer underline'>View All</span>
                                    </Link>
                                </div>
                                <hr className={'sortfilterborder my-4 ' + (this.state.orderlist.length === 0 ? '' : 'hidden')} />
                                <div className={'w-max mr-auto mt-5 mr-5 ' + (this.state.orderlist.length !== 0 ? 'hidden' : '')}>
                                    <span className='pl-5 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm text-left px-4'>Order Not Found</span>
                                </div>
                            </div>
                            <div className={'' + (this.state.Item == 3 ? '' : 'hidden')} >

                                <table className={"w-full " + (this.state.cartlist.length == 0 ? 'hidden' : '')}>
                                    <thead>
                                        <tr className='order-table Customer-view border-t border-b'>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm text-left px-4'>Product</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm xl:w-24 w-20'>Quantity</th>
                                            <th className='text-center  font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm  xl:w-44 w-20 xl:w-max'>Offer Price</th>
                                            <th className='text-center  font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm  xl:w-44 w-24 xl:w-max'>Online Price</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Vintage</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Contract Type</th>
                                            <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Color</th>
                                            {/* <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Color</th> */}
                                            {/* <th className='py-3 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Region</th> */}

                                        </tr>
                                    </thead>
                                    <tbody className={'order-table-body ' + (this.state.Items)}>
                                        {this.state.cartlist.map(list =>
                                            <tr key={list.product_row_id}>
                                                <td className=' py-2 pl-2 font-OpenSans font-semibold text-sm '><Link to={'/product/productdetails/' + (list.product_row_id)} className='text-redcolor underline px-4'> <span>{list.product_name}{list.product_contract_type === "SEP" || list.product_contract_type === "X" ? ' ( Case -' + list.product_packsize + '/' + list.product_bottle_size /
                                                    10 +
                                                    this.state.symbolweight + ')' : ' (  Bottle - ' + list.product_packsize + '/' + list.product_bottle_size /
                                                    10 + '' +
                                                    this.state.symbolweight + ')'}</span> </Link></td>
                                                <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm xl:w-24 w-20'>{list.cart_quantity}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(list.product_price_offer)}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{CurrencyFormat(list.product_online_price)}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{list.product_vintage}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm w-28 '>{list.product_contract_type}</td>
                                                <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm w-18 '>{list.product_color}</td>
                                                {/* <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{list.product_color}</td> */}
                                                {/* <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm'>{list.product_region}</td> */}

                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                <hr className={'sortfilterborder my-4 ' + (this.state.cartlist.length === 0 ? '' : 'hidden')} />
                                <div className={'w-max mr-auto mt-5 mr-5 ' + (this.state.cartlist.length !== 0 ? 'hidden' : '')}>
                                    <span className='pl-5 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm text-left px-4'>Items Not Found</span>
                                </div>
                            </div>
                            <div className={'' + (this.state.Item == 2 ? '' : 'hidden')} >
                                <hr className={'sortfilterborder my-4 '} />
                                <div className={'w-max mr-auto mt-5 mr-5 ' + (this.state.customeraddresslist.length !== 0 ? 'hidden' : '')}>
                                    <span className='pl-5 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm text-left px-4'>Address Not Found</span>
                                </div>
                                <div className='xl:px-10 px-5'>
                                    <div className='defualt w-full mb-5 flex'>
                                        <div className={'defualt-billing flex ' + (Object.keys(this.state.defualtbilling).length === 0 ? 'hidden' : '')}>
                                            <div className=' w-64'>
                                                <h3 className='font-OpenSans font-bold  text-bluecolor  text-sm'>Default Billing Address</h3>
                                                <div className='mt-2'>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.attention}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.address_line_1}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.address_line_2}</p>
                                                    {/* <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.address_line_3}</p> */}
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.city}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.state}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.phone}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.country_name}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtbilling.zip}</p>
                                                </div>
                                            </div>
                                            <div className={'  xl:ml-20 ml-5  ' + (this.state.Edit === true ? 'block' : ' hidden')}>
                                                <span className=' cursor-pointer' ><Link to={'/customer/customerdetails/address/' + (this.state.defualtbilling.record_row_id) + '?customer=' + (this.state.id) + "&edit=true"} className='text-redcolor underline'><img src='/static/Icons/edit.svg' alt='' className='w-5' /></Link></span>
                                            </div>
                                        </div>
                                        <div className={'defualt-shipping flex xl:ml-20 ml-10 ' + (Object.keys(this.state.defualtshipping).length === 0 ? 'hidden' : '')}>
                                            <div className=' w-64'>
                                                <h3 className='font-OpenSans font-bold  text-bluecolor  text-sm'>Default Shipping Address</h3>
                                                <div className='mt-2'>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.attention}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.address_line_1}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.address_line_2}</p>
                                                    {/* <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.address_line_3}</p> */}
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.city}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.state}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.phone}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.country_name}</p>
                                                    <p className='font-OpenSans font-normal  text-bluecolor  text-sm break-words'>{this.state.defualtshipping.zip}</p>
                                                </div>
                                            </div>
                                            <div className={'  xl:ml-20 ml-5  ' + (this.state.Edit === true ? 'block' : ' hidden')}>
                                                <span className=' cursor-pointer' ><Link to={'/customer/customerdetails/address/' + (this.state.defualtshipping.record_row_id) + '?customer=' + (this.state.id) + "&edit=true"} className='text-redcolor underline'><img src='/static/Icons/edit.svg' alt='' className='w-5' /></Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <table className={"w-full " + (this.state.Address.length === 0 ? 'hidden' : '')}>
                                        <thead>
                                            <tr className='order-table Customer-view border-t border-b'>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm  px-4 xl:w-20 w-10'></th>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm px-4'>Label</th>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Attention</th>
                                                <th className='text-center  font-OpenSans font-semibold  text-bluecolor xl:text-base text-sm text-sm w-44 xl:w-max'>Address</th>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>City</th>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Country</th>
                                                <th className='py-2 font-OpenSans font-semibold  text-bluecolor  xl:text-base text-sm'>Zip</th>

                                            </tr>
                                        </thead>
                                        <tbody className={'order-table-body ' + (this.state.Items)}>
                                            {this.state.Address.map(list =>
                                                <tr key={list.record_row_id}>
                                                    <td className=' py-2 pl-2 font-OpenSans font-semibold text-sm xl:w-20 w-10'><Link to={'/customer/customerdetails/address/' + (list.record_row_id) + '?customer=' + (this.state.id) + '&edit=false'} className={'text-redcolor underline ' + (this.state.Edit === true ? 'hidden ' : '')}> <img src='/static/Icons/view.svg' alt='' className='m-auto' /> </Link><Link to={'/customer/customerdetails/address/' + (list.record_row_id) + '?customer=' + (this.state.id) + '&edit=true'} className={'text-redcolor underline ' + (this.state.Edit === true ? ' ' : 'hidden')}> <img src='/static/Icons/edit.svg' alt='' className='m-auto w-5 ' /> </Link></td>
                                                    <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm break-words'>{list.label}  &nbsp;<span className={"inline-flex font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full " + (list.default_billing === true ? '' : 'hidden')}>Default Billing</span><span className={"inline-flex font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full " + (list.default_shipping === true ? '' : 'hidden')}>Default Shipping</span></td>
                                                    <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm break-words'>{list.attention} </td>
                                                    <td className='text-center py-2 font-OpenSans font-normal text-bluecolor text-sm break-words'>{list.address_line_1}</td>
                                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm break-words'>{list.city}</td>
                                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm break-words'>{list.country_name}</td>
                                                    <td className='text-center py-2 font-OpenSans font-normal  text-bluecolor text-sm break-words px-2'>{list.zip}</td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
};

export default CustomerInfo;