import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import './ProductCategory.scss'
import Progress from '../../components/ProgressBar/Progress';
import Select from 'react-select'
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import { withRouter } from 'react-router-dom';
import ImportHeader from './ImportHeader';
import Export from './Export';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import CSV from './../../Store/Csv'
import { mappingProductTableAPI } from './../../API/Cronjob'
import { capitalizeFirstLetter } from './../../global/js/browserfunction'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { importProductsAPI } from './../../API/Cronjob'
class Mapping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            loading: false,
            Category: props.Category,
            Homepage: props.Homepage,
            Status: 'Home',
            delimiter: {},
            RECORD_ID: '',
            DATE_CREATED: '',
            DATE_MODIFIED: '',
            IS_INACTIVE: '',
            TYPE: '',
            STATUS: '',
            SUMMARY: '',
            FILE_IMPORT: '',
            FILE_RESULT: '',
            File_column: '',
            mappingProductTable: [],
            Mapping: [],
            required: [],
            Mapping_required: [],
            File: ''



        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleField = this.handleField.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.HandleMapping = this.HandleMapping.bind(this);
        this.HandleImport = this.HandleImport.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }
    async handleCheck() {
        var File_column = CSV.getState()?.CSVkeys;
        console.log(CSV.getState()?.CSVkeys)
        this.setState({ File_column: File_column });
        var Files = CSV.getState()?.Files;
        this.setState({ File: Files });
        if (File_column === undefined) {

            this.props.history.push('/importproducts')
        }

    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.HandleMapping()
        this._isMounted && this.handleCheck()

    }
    /**
         * @description The function executed for get the table coumn name
     */
    HandleMapping() {
        var self = this;
        self.setState({ loading: true });
        let details = [
            "column",
            "type",
            "required",
        ]
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            var token = login.access_token;
            var email = login.email;

            mappingProductTableAPI(email, token, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.mappingProductTable?.summary?.reason;
                    let status = res?.data?.mappingProductTable?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        let data = res?.data?.mappingProductTable?.details;
                        const itemToBeRemoved = ['condition_image_1', 'is_livex', 'condition_image_2', 'condition_image_3', 'condition_image_4', 'condition_image_5', 'price_bid', 'guid_offer', 'guid_bid', 'date_created', 'date_modified', 'test_env', 'record_row_id', 'netsuite_item_Internal_id', 'price_discount_percentage', 'is_inactive', 'lead_time_min', 'lead_time_max', 'price_online', 'price_last_trade', 'price_last_trade_date', 'price_market', 'sku']
                        data.forEach(element => {
                            itemToBeRemoved.forEach(e => {
                                var findIndex = data.findIndex(a => a.column === e)
                                findIndex !== -1 && data.splice(findIndex, 1)
                            });

                        });
                        console.log(data)
                        self.setState({ mappingProductTable: data });
                        self.setState({ loading: false });
                        let required = [];
                        data.forEach(element => {
                            if (element.required === true) {
                                required.push(element.column)
                            }
                        });
                        self.setState({ required: required });
                        var File_column = self.state.File_column
                        console.log(data)
                        if (File_column != '' && File_column != undefined) {
                            data.forEach(element => {
                                File_column.forEach(e => {
                                    if (element.column === e.value) {
                                        self.handleField(e, element.column, element.required)
                                    }
                                });
                            });
                        }
                        console.log(File_column)
                    } else {

                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");
                        self.props.history.push("/");
                    }
                });
        }
        else {
            this.GoToPage("/");
        }
    }
    /**
        * @description The function executed for change the status
    */
    handleStatus(id) {
        this.setState({ Status: id });
        if (id === 'Home') {
            CSV.dispatch({ type: 'CSV', Key: undefined })

        }
        if (id === 'Menu') {
            if (CSV.getState()?.CSVkeys === undefined) {
                this.props.history.push('/importproducts')
            }
        }
    }

    /**
        * @description The function executed for set the values for table header
    */
    handleField(e, item, required) {
        var delimiter = this.state.delimiter
        var mapping = this.state.Mapping
        var mapping_required = this.state.Mapping_required
        delimiter[item] = e
        var index = mapping.findIndex(p => p.table_column == item);
        console.log(index)
        if (index !== -1) {
            mapping.splice(index, 1);
            mapping_required.splice(index, 1);
        }
        mapping.push({ csv_column: e.value, table_column: item })
        mapping_required.push({ csv_column: e.value, table_column: item, required: required })
        console.log(mapping)
        this.setState({ Mapping: mapping })
        this.setState({ mapping_required: mapping_required })


    }

    /**
        * @description The function executed for navigating to next page
    */
    handleContinue() {
        var required = this.state.required;
        var mapping_required = this.state.Mapping_required
        var mappings = [];
        mapping_required.forEach(element => {
            if (element.required === true) {
                mappings.push(element.table_column)
            }
        });

        if (mappings.sort().join(',') === required.sort().join(',')) {
            this.HandleImport()
            // this.props.history.push('/importproducts/mapping/importing')
        }
        else {
            showNotification("Please Choose Madatory Feilds", "warning")
        }

    }
    HandleImport() {
        var self = this;
        var Mapping = this.state.Mapping
        var file = this.state.File;
        console.log('mapping,', Mapping)
        console.log('file,', file)
        CSV.dispatch({ type: 'File', File: file })
        let login = JSON.parse(localStorage.getItem("wn_log"));
        var token = login.access_token;
        var email = login.email;
        var mapping = JSON.stringify(Mapping)
        importProductsAPI(email, token, mapping, file)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.importProducts?.summary?.reason;
                let status = res?.data?.importProducts?.summary?.status;
                if (reason == "FILE_IMPORTED" && status == "SUCCESS") {
                    self.props.history.push('/importproducts/mapping/importing')
                } else if (reason == "SUPPLER_CANNOT_BE_LIVEX" && status == "SUCCESS") {
                    showNotification("Supplier cannot be Livex", "warning")
                }
                else if (reason == "DELIMERTER_MUST_BE_TAB" && status == "SUCCESS") {
                    showNotification("Delimeter must be Tab", "warning")
                }
                else {

                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });
    }
    render() {
        return (
            <div className='Categorys-main  '>
                <ReactNotification />
                < Breadthcrumbs Breadthcrumb={'Products'} Count={2} level={'Import'} />
                {/* Name of the Page Header */}
                <div className='px-4 py-1'>

                    <ImportHeader />

                    {/* Rest of the portion with step progress bar */}
                    <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                        <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                            <div className='categorys grid grid-cols-2 '>

                                {/* Import Header */}
                                {/* <div className={'category-list '}>
                                    <div className={' px-2 border py-2 ' + (this.state.Status === 'Menu' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Menu')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Menu' ? 'text-white' : 'text-bluecolor')}  >IMPORT</h2>
                                        </div>
                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} />
                                </div> */}

                                {/* Export Header */}
                                <div className={'category-list    '}>
                                    <div className={' px-2 border py-2 px-2 ' + (this.state.Status === 'Home' ? 'bg-bluecolor' : ' ')}>
                                        <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Home')}>
                                            <h2 className={' font-OpenSans text-base font-normal my-auto text-center ' + (this.state.Status === 'Home' ? 'text-white' : 'text-bluecolor')}>EXPORT</h2>
                                        </div>
                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} />
                                </div>

                            </div>
                        </div>

                        <div className={'category-down pt-6 ' + (this.state.Status === 'Menu' ? '' : 'hidden')}>
                            <div className='categorys-div '>

                                {/* Step Progress Indicator */}
                                <div className='level0  w-full'>
                                    <Progress level1={"level1"} level2={"level2"} />
                                </div>

                                <div className='mt-12 mb-2'>
                                    <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl mt-4'>CSV Import Mapping With Database Column</span>
                                </div>
                                {this.state.loading === true ? <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                                    <Loader loading={this.state.loading} />
                                </div> :
                                    <div className='border-solid border-2 border-gray-200 my-auto border-b mt-2'>
                                        <div className="mb-12 ml-4">
                                            <div className="w-full">
                                                {/* Table header mapping section */}
                                                <form>
                                                    <div className='w-full mt-5 px-5 m-auto'>
                                                        <table className="xl:w-1/2 w-full">
                                                            <tbody>
                                                                <tr>
                                                                    <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-6 text-left'><span>Column Name </span></td>
                                                                    <td className='font-OpenSans  font-bold text-bluecolor text-sm w-40  py-6 pl-6 text-justify'><span>Map To Field </span></td>
                                                                </tr>
                                                                {this.state.mappingProductTable && this.state.mappingProductTable.length > 0 && this.state.mappingProductTable.map((item, index,) => {
                                                                    return <tr key={index}>
                                                                        <td className='text-base text-bluecolor font-OpenSans my-auto w-72  py-4 pl-4 text-left capitalize'><span>{capitalizeFirstLetter(item.column)} {item.required === true ? '*' : ''}</span></td>
                                                                        <td className='text-base text-bluecolor font-OpenSans my-auto w-40  py-4 text-left'>
                                                                            <div className="flex w-72">
                                                                                <span className='w-full order-status status-select adduser'>
                                                                                    <Select
                                                                                        value={this.state.delimiter[item.column]}
                                                                                        onChange={(e) => this.handleField(e, item.column, item.required)}
                                                                                        options={this.state.File_column} isSearchable={true}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                )
                                                                }
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                </form>

                                            </div>

                                            <div className="xl:w-1/2 w-full my-auto xl:ml-2 ml-1 mt-10">
                                                <div className='flex w-1/2 ml-auto items-end'>
                                                    <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer xl:w-8/12 w-10/12 flex items-end' onClick={this.handleContinue}>
                                                        <span className="xl:w-full w-full text-white text-md mx-4"> Run The Importer </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {this.state.Status === 'Home' && <Export />}
                    </div >
                </div>

            </div >
        )
    }
};

export default withRouter(Mapping);