import React, { Component } from 'react';
import './ProductDetail.scss'
import store from './../../Store/Store'
import local from '../../global/js/locale';
class ProductImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Title: 1,
            Edit: store.getState().edit,
            id: props.Id,
            currentProduct: '',
            Products: props.Products,
            varchar: local.VARCHAR,
            Int: local.INT,
            Float: local.FLOAT,
            images: [],
            condition_images: [],
            Livex: ''
        }
        this.handleTitle = this.handleTitle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
* @description The function executed for get he edit value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
        var id = parseInt(this.state.id);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Products !== this.state.Products) {
            this.setState({ Products: nextProps.Products })
            console.log(nextProps.Products)
            var images = [];
            if (nextProps.Products.image_1 !== '' && nextProps.Products.image_1 !== null) {
                images.push(nextProps.Products.image_1)
            }
            if (nextProps.Products.image_2 !== '' && nextProps.Products.image_2 !== null) {
                images.push(nextProps.Products.image_2)
            }
            if (nextProps.Products.image_3 !== '' && nextProps.Products.image_3 !== null) {
                images.push(nextProps.Products.image_3)
            }
            console.log(images)
            this.setState({ images: images })
            var condition = [];
            if (nextProps.Products.condition_image_1 !== '' && nextProps.Products.condition_image_1 !== null) {
                condition.push(nextProps.Products.condition_image_1)
            }
            if (nextProps.Products.condition_image_2 !== '' && nextProps.Products.condition_image_2 !== null) {
                condition.push(nextProps.Products.condition_image_2)
            }
            if (nextProps.Products.condition_image_3 !== '' && nextProps.Products.condition_image_3 !== null) {
                condition.push(nextProps.Products.condition_image_3)
            }
            if (nextProps.Products.condition_image_5 !== '' && nextProps.Products.condition_image_5 !== null) {
                condition.push(nextProps.Products.condition_image_5)
            }
            if (nextProps.Products.condition_image_4 !== '' && nextProps.Products.condition_image_4 !== null) {
                condition.push(nextProps.Products.condition_image_4)
            }
            console.log(condition)
            this.setState({ condition_images: condition })
        }
        if (nextProps.Products.supplier_name === "Liv-ex" || nextProps.Products.supplier_name === 'livex' || nextProps.Products.supplier_name === 'LIVEX') {
            this.setState({ Livex: true })
        } else {
            this.setState({ Livex: false })
        }


    }
    /**
* @description The function executed for exists the component the edit as false
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
    }
    /**
* @description The function executed for set the title 
*/
    handleTitle(id) {
        this.setState({ Title: id });

    }
    /**
* @description The function executed for updated value stored as object.the id as key
*/
    handleChange(e) {
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        update[key] = value;
        store.dispatch({ type: 'Update', Update: update })
    }
    render() {
        return (
            <div className='Product-image h-full  bg-white py-4 px-5 mt-6 ' >
                <div className='mr-2 flex'>
                    <span className='OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer text-bluecolor ' >Images &nbsp;</span>
                </div>
                <hr className='sortfilterborder mb-4 mt-4' />
                <div className='product-head flex px-5'>
                    <div className='mr-2 flex'>
                        <span className={'OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer mr-4 ' + (this.state.Title === 1 ? 'text-redcolor ' : 'text-bluecolor ')} onClick={() => this.handleTitle(1)}>Stock ({this.state.images.length}) &nbsp;</span>
                        <img src='/static/Icons/Line.svg' alt='' className={'h-8 w-2 '} />
                    </div>
                    <div className='mr-2 flex'>
                        <span className={'OrderItem font-OpenSans font-semibold   text-base my-auto cursor-pointer ml-4 ' + (this.state.Title === 2 ? 'text-redcolor ' : 'text-bluecolor ')} onClick={() => this.handleTitle(2)}>Condition ({this.state.condition_images.length}) &nbsp;</span>
                    </div>
                </div>
                <div className={'stock-image ' + (this.state.Title === 1 ? 'block' : 'hidden')}>
                    <div className={'flex mt-6 px-3 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                        <div className={'flex ' + ((this.state.Products.image_1 === '' || this.state.Products.image_1 === null) && (this.state.Products.image_2 === '' || this.state.Products.image_2 === null) && (this.state.Products.image_3 === '' || this.state.Products.image_3 === null) ? 'hidden' : '')}>
                            {this.state.Products.image_1 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.image_1 + ")" }}></div>
                            }
                            {this.state.Products.image_2 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.image_2 + ")" }}></div>
                            }
                            {this.state.Products.image_3 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.image_3 + ")" }}></div>
                            }
                        </div>
                        <div className={' ' + ((this.state.Products.image_1 === '' || this.state.Products.image_1 === null) && (this.state.Products.image_2 === '' || this.state.Products.image_2 === null) && (this.state.Products.image_3 === '' || this.state.Products.image_3 === null) ? '' : 'hidden')}>
                            <span className='font-OpenSans font-semibold text-sm  ml-6 text-bluecolor'>Image Not Found</span>
                        </div>
                    </div>
                    <div className={'edit-stock px-5 mt-5 ' + (this.state.Edit === true ? 'block' : 'hidden')}>
                        <table className="">
                            <tbody>
                                <tr>
                                    <td className='py-2 w-36'> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>Images_1</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_1' onInput={this.handleChange} defaultValue={this.state.Products.image_1} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_2</span></td>
                                    <td className='py-2 w-full'> <input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_2' onInput={this.handleChange} defaultValue={this.state.Products.image_2} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_3</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='IMAGE_3' onInput={this.handleChange} defaultValue={this.state.Products.image_3} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={'condition-image ' + (this.state.Title === 2 ? 'block' : 'hidden')}>
                    <div className={'flex mt-6 px-3 ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden' : 'block')}>
                        <div className={'flex ' + ((this.state.Products.condition_image_1 === '' || this.state.Products.condition_image_1 === null) && (this.state.Products.condition_image_2 === '' || this.state.Products.condition_image_2 === null) && (this.state.Products.condition_image_3 === '' || this.state.Products.condition_image_3 === null) && (this.state.Products.condition_image_4 === '' || this.state.Products.condition_image_4 === null) && (this.state.Products.condition_image_5 === '' || this.state.Products.condition_image_5 === null) ? 'hidden' : '')}>
                            {this.state.Products.condition_image_1 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.condition_image_1 + ")" }}></div>
                            }
                            {this.state.Products.condition_image_2 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.condition_image_2 + ")" }}></div>
                            }
                            {this.state.Products.condition_image_3 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.condition_image_3 + ")" }}></div>
                            }
                            {this.state.Products.condition_image_4 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.condition_image_4 + ")" }}></div>
                            }
                            {this.state.Products.condition_image_5 &&
                                <div className='h-44 w-36 shadow-shadowblack mx-5 bg-center bg-no-repeat bg-cover ' style={{ backgroundImage: "url(" + this.state.Products.condition_image_5 + ")" }}></div>
                            }
                        </div>
                        <div className={' ' + ((this.state.Products.condition_image_1 === '' || this.state.Products.condition_image_1 === null) && (this.state.Products.condition_image_2 === '' || this.state.Products.condition_image_2 === null) && (this.state.Products.condition_image_3 === '' || this.state.Products.condition_image_3 === null) && (this.state.Products.condition_image_4 === '' || this.state.Products.condition_image_4 === null) && (this.state.Products.condition_image_5 === '' || this.state.Products.condition_image_5 === null) ? '' : 'hidden')}> <span className='font-OpenSans font-semibold text-sm  ml-6 text-bluecolor'>Image Not Found</span></div>
                    </div>
                    <div className={'edit-condition px-5 mt-5 ' + (this.state.Edit === true && this.state.Livex === false ? 'block' : 'hidden')}>
                        <table className="">
                            <tbody>
                                <tr>
                                    <td className='py-2 w-36'> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>Images_1</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='CONDITION_IMAGE_1' onInput={this.handleChange} defaultValue={this.state.Products.condition_image_1} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_2</span></td>
                                    <td className='py-2 w-full'> <input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='CONDITION_IMAGE_2' onInput={this.handleChange} defaultValue={this.state.Products.condition_image_2} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_3</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='CONDITION_IMAGE_3' onInput={this.handleChange} defaultValue={this.state.Products.condition_image_3} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_4</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='CONDITION_IMAGE_4' onInput={this.handleChange} defaultValue={this.state.Products.condition_image_4} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                                <tr>
                                    <td className='py-2 w-36'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Images_5</span></td>
                                    <td className='py-2 w-full'><input type='text' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md xl:w-1/2 w-3/4 pl-2' id='CONDITION_IMAGE_5' onInput={this.handleChange} defaultValue={this.state.Products.condition_image_4} maxLength={this.state.varchar['3X']} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
};

export default ProductImage;