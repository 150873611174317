import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import CustomerInfo from './CustomerInfo';
import store from '../../Store/Store'
import customer from '../../data/Customer.json'
import { getCookie } from './../../global/js/browserfunction'
import { fetchCustomerInformationAPI, updateCustomerProfileAPI } from './../../API/Customer'
import { Link } from 'react-router-dom';
import Loader from './../../components/Loader/FullLoader'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'

class CustomerDetails extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: '',
            Id: props.match.params.id,
            customerdetails: '',
            orderlist: [],
            cartlist: [],
            customeraddresslist: '',
            loading: false,




        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleListcustomer = this.handleListcustomer.bind(this);
        this.HandleString = this.HandleString.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed setstate the value when change in the store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
        window.scrollTo(0, 0)

    }
    /**
* @description The function executed for after the component remove
*/
    componentWillUnmount() {
        this._isMounted = false;
        var update = {}
        store.dispatch({ type: 'Update', Update: update })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        var id = this.state.Id
        console.log(id)
        this.handleListcustomer(id)
    }
    /**
* @description The function executed conver the updates and change the keys as the same of the api keyword
*/
    Handlechange(value) {
        var id = this.state.Id
        this.handleListcustomer(id)
    }
    /**
* @description The function executed get the customer details based on the customer id
*@param id-order id
*/
    handleListcustomer(id) {
        document.getElementById("Customer-form").reset();
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "RECORD_ROW_ID": id
            }
            query = this.HandleString(query)
            var details = [
                "record_row_id",
                "date_created",
                "date_modified",
                "first_name",
                "last_name",
                "company_name",
                "email",
                "phone",
                "have_access",
                "is_inactive",
                "last_login_date",
                "last_password_reset_date",

            ];
            var orderdetails = [
                "record_row_id",
                "status",
                "transaction_date",
                "transaction_number",
                "transaction_total",

            ]
            var cartdetails = [
                "product_row_id",
                "product_contract_type",
                "product_name",
                "product_vintage",
                "product_color",
                "product_region",
                "product_online_price",
                "cart_duty",
                "cart_quantity",
                "cart_tax",
                "cart_weight",
                "cart_total",
                "product_price_offer",
                "product_bottle_size",
                "product_packsize"
            ];
            var customerdetails = [
                "record_row_id",
                "customer_row_id",
                "label",
                "default_billing",
                "default_shipping",
                "is_residential",
                "attention",
                "addressee",
                "landmark",
                "address_line_1",
                "address_line_2",
                "address_line_3",
                "city",
                "state",
                "zip",
                "phone",
                "country_code",
                "country_name",
                "addressee",

            ];
            //api for order information
            self.setState({ loading: true })
            console.log(query)
            fetchCustomerInformationAPI(query, details, orderdetails, cartdetails, customerdetails)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchCustomerInformation?.summary?.reason;
                    let status = res?.data?.fetchCustomerInformation?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var customerdetails = res.data.fetchCustomerInformation.details;
                        var orderlist = res.data.fetchCustomerInformation.details.order_history;
                        var cartlist = res.data.fetchCustomerInformation.details.cart;
                        var customeraddresslist = res.data.fetchCustomerInformation.details.customer_address;
                        self.setState({ customerdetails: customerdetails })
                        self.setState({ orderlist: orderlist })
                        self.setState({ cartlist: cartlist })
                        self.setState({ customeraddresslist: customeraddresslist })
                        self.setState({ loading: false })
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/customer");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }

    }
    /** 
* * @description The function executed for convert phone number to code
*@param input-phone number
*/
    HandleconvertCode(phone) {
        if (phone != undefined) {
            return phone.substr(0, phone.indexOf(" "));
        }

    }
    /**
* @description The function executed for cancel the edit boxes
*/
    handleCancel() {
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for submit the changes of the form
*/
    handleSubmit(e) {
        e.preventDefault();
        var id = parseInt(this.state.Id);
        var update = store.getState().update;
        update['RECORD_ID'] = this.state.Id
        var code = false
        var self = this;
        var email;
        var token;
        var query
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            update['EMAIL'] = email
            update['TOKEN'] = token
            console.log(update)
            if (update['PHONE'] === null || update['PHONE'] === undefined) {
                delete update['PHONE']
                this.handleListcustomer(id)
                code = true
            } else {

                var code = this.HandleconvertCode(update['PHONE'])
                if (update['PHONE'] === 'undefined ') {
                    delete update['PHONE']
                    code = true
                }
                else if (code === undefined || code === "undefined") {
                    code = false
                    showNotification("Choose Country Code", "danger");
                    delete update['PHONE']

                } else {
                    code = true
                }
            }
            query = this.HandleString(update)
            var details = [
                "email",

            ];

            //api for order information
            self.setState({ loading: true })
            if (code === true) {
                updateCustomerProfileAPI(query, details)
                    .then((res) => res.json())
                    .then(function (res) {
                        console.log(res.data)
                        let reason = res?.data?.updateCustomerProfile?.summary?.reason;
                        let status = res?.data?.updateCustomerProfile?.summary?.status;
                        if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                            var id = parseInt(self.state.Id)
                            self.handleListcustomer(id)
                            showNotification("Updated Customer", "success");
                            self.setState({ loading: false })
                        } else if (reason == "RECORD_NOT_UPDATED" && status == "SUCCESS") {
                            var id = parseInt(self.state.Id)
                            self.handleListcustomer(id)
                            self.setState({ loading: false })
                            showNotification("Order Has Not Update", "danger");
                        } else {
                            var id = parseInt(self.state.Id)
                            self.handleListcustomer(id)
                            showNotification("Cannot Update Customer", "danger");
                            // self.props.history.push("/product?page=1");
                            self.setState({ loading: false })

                        }
                    });
                store.dispatch({ type: 'EditOrder', edits: false })
            } else {
                self.setState({ loading: false })
                // store.dispatch({ type: 'EditOrder', edits: true })
            }

        } else {
            this.GoToPage("/");
            store.dispatch({ type: 'EditOrder', edits: false })
        }

    }
    /**
* @description The function executed for cancel the edit
*/
    HandleString(newupdate) {
        // var newupdate = Object.keys(newupdate)
        //     .filter((k) => newupdate[k] != '')
        //     .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            PHONE: "PHONE",
            FIRST_NAME: "FIRST_NAME",
            LAST_NAME: "LAST_NAME",
            COMPANY_NAME: "COMPANY_NAME",
            ADDRESSEE: "ADDRESSEE"

        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID", RECORD_ID: "RECORD_ID", HAVE_ACCESS: "HAVE_ACCESS", IS_INACTIVE: "IS_INACTIVE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    render() {
        return (
            < div className='Customer-detail xl:h-full h-screen Order-main  ' >
                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    <ReactNotification />
                    < Breadthcrumbs Breadthcrumb={'Customer Details'} Count={2} level={'Customer'} />
                    <Link to='/customer' className='flex bg-white xl:w-48 w-56 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/target.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Customer</span>
                    </Link>
                </div>
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'Customer-detail-content px-5 py-2 ' + (this.state.loading === true ? 'hidden' : '')}>
                    <form onSubmit={this.handleSubmit} id='Customer-form'>
                        <CustomerInfo Status={customer.Status} customerdetails={this.state.customerdetails} orderlist={this.state.orderlist} cartlist={this.state.cartlist} customeraddresslist={this.state.customeraddresslist} Id={parseInt(this.state.Id)} passToParent={this.Handlechange} />
                    </form>

                </div>
            </div >
        )
    }
};

export default CustomerDetails;