import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import './ProductCategory.scss'
import { deleteCategoryAPI } from './../../API/Category'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { listCategoryAPI, generateHeaderTreeAPI } from './../../API/Category'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
class ProductCategorys extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Category: props.Category,
            Homepage: props.Homepage,
            loading: props.loading,
            Status: 'Menu',
            level0: '',
            level1: '',
            level2: '',
            level_0: '',
            home_level_0: '',
            parent: 0


        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleLevel0 = this.handleLevel0.bind(this);
        this.handleLevel1 = this.handleLevel1.bind(this);
        this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
        this.handleUpdateCache = this.handleUpdateCache.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Category !== this.state.Category) {
            this.setState({ Category: nextProps.Category })
            console.log(nextProps.Category)
        }
        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading })
        }

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        console.log(this.state.Category.length)
        this.setState({ level_0: this.state.Category.length });
        this.setState({ home_level_0: this.state.Homepage.length });
        this.props.passToParent(this.state.Status)
    }
    /**
* @description The function executed for change the status
*/
    handleStatus(id) {
        this.setState({ Status: id });
        this.props.passToParent(id)
    }
    /**
* @description The function executed for delete category
*@param id-category id
*/
    handleDeleteCategory(id) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['ROW_ID'] = id
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        //api for list orders
        deleteCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.deleteCategory?.summary?.reason;
                let status = res?.data?.deleteCategory?.summary?.status;
                if (reason == "RECORD_DELETED" && status == "SUCCESS") {
                    self.props.passToParent(self.state.Status)
                    showNotification("Removed", "success");

                } else {
                    showNotification("Sorry Category Not Removed", "danger");
                }
            });

    }
    /**
* @description The function executed for update the cache in category
*/
    handleUpdateCache() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        var self = this;
        self.setState({ loading: true })
        var details = [
            "url",

        ];
        //api for list orders
        generateHeaderTreeAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.generateHeaderTree?.summary?.reason;
                let status = res?.data?.generateHeaderTree?.summary?.status;
                if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                    self.props.passToParent(self.state.Status)
                    showNotification("Updated Record", "success");

                } else {
                    showNotification("Sorry Update Fail", "danger");
                }
            });
    }
    /**
* @description The function executed for set the levels for dropdown
*/
    handleLevel0(id) {
        if (this.state.level0 == id) {
            this.setState({ level0: null })
        } else {
            this.setState({ level0: id })
        }

        // if (this.state.level0.includes(id)) {
        //     let someArray = this.state.level0;
        //     someArray = someArray.filter(person => person != id);

        // }
        // else {
        //     this.setState({ level0: [...this.state.level0, id] });
        // }
    }
    /**
* @description The function executed for set the levels for dropdown
*/
    handleLevel1(id) {
        if (this.state.level1 == id) {
            this.setState({ level1: null })
        } else {
            this.setState({ level1: id })
        }
        // if (this.state.level1.includes(id)) {
        //     let someArray = this.state.level1;
        //     someArray = someArray.filter(person => person != id);
        //     this.setState({ level1: someArray })
        // }
        // else {
        //     this.setState({ level1: [...this.state.level1, id] });
        // }
    }
    /**
* @description The function executed for conavert the object to string
*/
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { ROW_ID: "ROW_ID", HOME_PAGE: "HOME_PAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    render() {
        return (
            <div className='Categorys-main py-1 px-6'>
                <ReactNotification />
                < div className='CategorysInformation   bg-white shadow-shadowblack py-5 px-6' >
                    <div className='w-full flex'>
                        <div className='flex w-1/2'>
                            <img src='/static/Icons/categories_page.svg' alt='' className='xl:w-8 w-5' />
                            <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto'>Manage Category</h1>
                        </div>
                        <div className='w-1/2 text-right my-auto '>
                            <span className='font-OpenSans font-normal  text-white xl:text-base text-base my-auto bg-bluecolor px-4 py-1 rounded-lg cursor-pointer ' onClick={() => this.handleUpdateCache()}>Update Website Cache</span>
                        </div>
                    </div>
                </div >
                <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                    <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                        <div className='categorys grid grid-cols-2 '>
                            <div className={'category-list '}>
                                <div className={' px-2 border py-2 cursor-pointer ' + (this.state.Status === 'Menu' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('Menu')}>
                                    <div className='cursor-pointer flex w-max m-auto' >

                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'Menu' ? 'text-white' : 'text-bluecolor')}  >Menu Bar Category</h2>
                                    </div>

                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} />
                            </div>
                            <div className={'category-list    '}>
                                <div className={' px-2 border py-2 px-2 cursor-pointer  ' + (this.state.Status === 'Home' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('Home')}>
                                    <div className='cursor-pointer flex w-max m-auto'>

                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'Home' ? 'text-white' : 'text-bluecolor')}>Home Page Category</h2>
                                    </div>
                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} />
                            </div>

                        </div>
                    </div>
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div>
                    <div className={'category-content ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                        <div className={'category-down pt-6 ' + (this.state.Status === 'Menu' ? '' : 'hidden')}>
                            <div className='categorys-div '>
                                <div className='level0  w-full'>
                                    {this.state.Category.map(level0 =>
                                        <div key={level0.id}>
                                            <div className='parent-item parent-cat  py-2 flex my-auto w-full mb-2 '>
                                                <div className='cursor-pointer w-full  my-auto flex w-max' onClick={() => this.handleLevel0(level0.id)}>
                                                    <div className='my-auto'>
                                                        <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.level0 === level0.id ? '' : 'hidden')} src='/static/Icons/bold_minus_icon.svg' alt='' />
                                                        <img className={'font-OpenSans  font-bold text-bluecolor  my-auto w-3 mx-3 ' + (this.state.level0 === level0.id ? 'hidden' : '')} src='/static/Icons/bold_plus_icon.svg' alt='' />
                                                    </div>
                                                    <div className='my-auto'>

                                                        <span className='font-OpenSans text-sm font-semibold my-auto text-bluecolor'>{level0.label}</span>
                                                    </div>
                                                </div>
                                                <div className='actions-cat my-auto flex w-max ml-auto mr-5'>
                                                    <div className='view-action my-auto px-2'>
                                                        <Link to={'/category/viewcategory/' + (level0.id) + '?level0=' + (level0.label) + '&is_homepage=false'}>
                                                            <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                        </Link>
                                                    </div>
                                                    <div className='view-action my-auto px-2'>
                                                        <Link to={'/category/editcategory/' + (level0.id) + '?level0=' + (level0.label) + '&is_homepage=false'}>
                                                            <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                        </Link>
                                                    </div>
                                                    <div className={'view-action my-auto px-2 ' + (level0.child.length === 0 ? '' : 'hidden')}>
                                                        <img src='/static/Icons/delete.svg' alt='' className='w-4 my-auto cursor-pointer' onClick={() => this.handleDeleteCategory(level0.id)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'level-1 bg-white ml-7 ' + (this.state.level0 === level0.id ? '' : 'hidden')}>
                                                <div>
                                                    {level0.child &&
                                                        level0.child.map(level1 =>
                                                            <div key={level1.id}>
                                                                <div className=' border-b border-l flex py-2' >
                                                                    <div className='cursor-pointer w-full my-auto flex w-max ' onClick={() => this.handleLevel1(level1.id)}>
                                                                        <div className='my-auto'>
                                                                            <img className={'font-OpenSans  font-bold text-bluecolor mx-3 my-auto w-2 ' + (this.state.level1 === level1.id ? '' : 'hidden')} src='/static/Icons/minus_icon.svg' alt='' />
                                                                            <img className={'font-OpenSans  font-bold text-bluecolor mx-3 my-auto w-2 ' + (this.state.level1 === level1.id ? 'hidden' : '')} src='/static/Icons/plus_icon.svg' alt='' />
                                                                        </div>
                                                                        <div className='my-auto'>
                                                                            <span className='font-OpenSans text-sm font-semibold my-auto text-bluecolor'>{level1.label}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='actions-cat my-auto flex w-max ml-auto mr-5'>
                                                                        <div className='view-action my-auto px-2'>
                                                                            <Link to={'/category/viewcategory/' + (level1.id) + '?level0=' + (level0.label) + '&level1=' + (level1.label) + '&is_homepage=false'}>
                                                                                <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                            </Link>
                                                                        </div>
                                                                        <div className='view-action my-auto px-2'>
                                                                            <Link to={'/category/editcategory/' + (level1.id) + '?level0=' + (level0.label) + '&level1=' + (level1.label) + '&is_homepage=false'}>
                                                                                <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                            </Link>
                                                                        </div>
                                                                        <div className={'view-action my-auto px-2 ' + (level1.child.length === 0 ? '' : 'hidden')}>
                                                                            <img src='/static/Icons/delete.svg' alt='' className='w-4 my-auto cursor-pointer' onClick={() => this.handleDeleteCategory(level1.id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={'level-2 parent-cat ml-8 ' + (this.state.level1 === level1.id ? '' : 'hidden')} >
                                                                    <div>
                                                                        {level1.child &&
                                                                            level1.child.map(level2 =>
                                                                                <div key={level2.id}>
                                                                                    <div className=' flex py-2  border-b border-l' >
                                                                                        <div className='cursor-pointer w-full my-auto flex '>
                                                                                            <div className='my-auto'>
                                                                                                <img className={'font-OpenSans  font-bold text-bluecolor mx-3 my-auto w-3 '} src='/static/Icons/level.svg' alt='' />
                                                                                            </div>
                                                                                            <div className='my-auto'>
                                                                                                <span className='font-OpenSans text-sm font-semibold my-auto text-bluecolor'>{level2.label}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='actions-cat my-auto flex w-max ml-auto mr-5'>
                                                                                            <div className='view-action my-auto px-2'>
                                                                                                <Link to={'/category/viewcategory/' + (level2.id) + '?level0=' + (level0.label) + '&level1=' + (level1.label) + '&level2=' + (level2.label) + '&is_homepage=false'}>
                                                                                                    <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className='view-action my-auto px-2'>
                                                                                                <Link to={'/category/editcategory/' + (level2.id) + '?level0=' + (level0.label) + '&level1=' + (level1.label) + '&level2=' + (level2.label) + '&is_homepage=false'}>
                                                                                                    <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                                                                                </Link>
                                                                                            </div>
                                                                                            <div className='view-action my-auto px-2'>
                                                                                                <img src='/static/Icons/delete.svg' alt='' className='w-4 my-auto cursor-pointer' onClick={() => this.handleDeleteCategory(level2.id)} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                    <div className='my-auto  px-2 pl-3 py-2   border-b border-l cursor-pointer'>
                                                                        <Link to={'/category/addcategory/' + (level1.id) + '?is_menu_category=true&level=3&order_precedence=' + (level1.child.length + 1) + '&level0=' + (level0.label) + '&level1=' + (level1.label)} className='flex my-auto'>
                                                                            <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer mr-2' />
                                                                            <span className='font-OpenSans text-sm font-semibold my-auto text-redcolor'>Add New Category</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )}

                                                </div>
                                                <div className='my-auto bg-white border-b border-l   px-2 pl-3 py-2 cursor-pointer'>
                                                    <Link to={'/category/addcategory/' + (level0.id) + '?is_menu_category=true&level=2&order_precedence=' + (level0.child.length + 1) + '&level0=' + (level0.label)} className='flex my-auto'>
                                                        <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer mr-2' />
                                                        <span className='font-OpenSans text-sm font-semibold my-auto text-redcolor'>Add New Category</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>

                                <div className='my-auto  border-b px-2 pl-3 py-2 mt-2 cursor-pointer'>
                                    <Link to={'/category/addcategory/' + (this.state.parent) + '?is_menu_category=true&level=1&order_precedence=' + (this.state.level_0 + 1)} className='flex my-auto'>
                                        <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer mr-2' />
                                        <span className='font-OpenSans text-sm font-semibold my-auto text-redcolor'>Add New Category</span>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className={'category-down pt-6 ' + (this.state.Status === 'Home' ? '' : 'hidden')}>
                            {this.state.Category.map(level0 =>
                                <div className='parent-item parent-cat  py-2 flex my-auto w-full mb-2 ' key={level0.id}>
                                    <div className='cursor-pointer w-full  my-auto flex' onClick={() => this.handleLevel0(63)}>
                                        <div className='my-auto'>
                                            <img className={'font-OpenSans  font-bold text-bluecolor mx-3 my-auto w-3 '} src='/static/Icons/level.svg' alt='' />
                                        </div>
                                        <div className='my-auto'>
                                            <span className='font-OpenSans text-sm font-semibold my-auto text-bluecolor'>{level0.label}</span>
                                        </div>
                                    </div>
                                    <div className='actions-cat my-auto flex w-max ml-auto mr-5'>
                                        <div className='view-action my-auto px-2'>
                                            <Link to={'/category/viewcategory/' + (level0.id) + '?level0=' + (level0.label) + '&is_homepage=true'}>
                                                <img src='/static/Icons/view.svg' alt='' className='w-5 my-auto cursor-pointer' />
                                            </Link>
                                        </div>
                                        <div className='view-action my-auto px-2'>
                                            <Link to={'/category/editcategory/' + (level0.id) + '?level0=' + (level0.label) + '&is_homepage=true'}>
                                                <img src='/static/Icons/edit.svg' alt='' className='w-4 my-auto cursor-pointer' />
                                            </Link>
                                        </div>
                                        <div className='view-action my-auto px-2'>
                                            <img src='/static/Icons/delete.svg' alt='' className='w-4 my-auto cursor-pointer' onClick={() => this.handleDeleteCategory(level0.id)} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='cursor-pointer w-full  my-auto parent-item   ' >
                                <div className='my-auto py-1 ml-3'>
                                    <Link to={'/category/addcategory/' + (this.state.parent) + '?is_menu_category=false&level=1&order_precedence=' + (this.state.home_level_0 + 1)} className='flex my-auto'>
                                        <img src='/static/Icons/ADD.svg' alt='' className='w-4 my-auto cursor-pointer mr-2' />
                                        <span className='font-OpenSans text-sm font-semibold my-auto text-redcolor '>Add New Category</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div >
        )
    }
};

export default ProductCategorys;