import React from "react";
import { useState } from "react";

export default function Modal(props) {
  const [showModal, setShowModal] = useState(props.val);
  return (
    <>
      
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b rounded-t">
                  <button className="ml-auto bg-black rounded-2xl border-0 text-black float-right text-3xl leading-none font-OpenSans font-semibold outline-none focus:outline-none" onClick={() => props.handleModal(false)}>
                    <span className="bg-transparent text-white mb-2 mx-1.5 h-7 w-6 text-2xl block rounded-lg outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                <div className=" text-center items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h1 className="text-2xl font-OpenSans font-bold">
                  {props.title}
                  </h1>
                 
                </div>
                {/*body*/}
                <div className="relative p-6 text-justify">
                  <p className="font-OpenSans my-4 text-blueGray-500 text-lg leading-relaxed">
                  An email will be send to your registered Email ID to proceed with {props.msg} .<br/> Do you want to Continue ?
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-redcolor background-transparent font-OpenSans font-bold uppercase text-sm px-6 py-3 text-sm outline-none focus:outline-none hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => props.handleModal(false)}
                  >
                    No
                  </button>
                  <button
                    className="text-bluecolor background-transparent font-OpenSans font-bold uppercase text-sm px-6 py-3 text-sm outline-none focus:outline-none hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {props.resetPassword();setShowModal(false)}}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}