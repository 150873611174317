
import { BASEURL } from './Baseurl'

/**
     * @description  The function for API for listing Delivery Charges
     * @param {query, details1,details2} payload and details to be displayed
     * @returns login success or fails
*/
function deliveryChargeListAPI(query, details1,details2) {
    //The details are convert to string
    let detail1 = details1.toString();
    let detail2 = details2.toString();
    return fetch(BASEURL + 'graphql/delivery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchDeliveryCharge(${query})
                       {
                    summary{
                        status
                        reason
                       }

                       details
                       {
                           country_list{
                            id
                            itemid
                            country
                            states

                            standard_charges {
                                ${details1}
                            }

                            special_charges {
                                state
                                charge{
                                    ${details2}
                                }
                                
                            }
                           }

                        } 
                    }
                }`,
            }
        ),
    })
}

/**
     * @description  The function for API for updating deliveryCharge details
     * @param {query, details}, payload and details to be displayed
     * @returns updated details of deliveryCharge
*/
function deliveryChargeUpdateAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/delivery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
        {
            query: `
            mutation{
                updateDeliveryCharge(${query})
                {
                    summary{
                        status
                        reason
                    }
                    details
                    {
                        country_list{
                            ${detail}
                        }
                    }
                }
            }`,
        }
        ),
    })
}

/**
     * @description  The function for API for sending tokens for getting new password for new users and resetting password for existing Users
     * @param {query, details}, payload and details to be displayed
     * @returns token
*/
function updateWebsiteCacheAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/delivery', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
        {
            query: `
            mutation{
                updateDeliveryCashe(${query})
                {
                    summary{
                        status
                        reason
                    }
                    details
                    {
                        country_list{
                            ${detail}
                        }
                    }
                }
            }`,
        }
        ),
    })
}



export {
    deliveryChargeListAPI,
    updateWebsiteCacheAPI,
    deliveryChargeUpdateAPI

}