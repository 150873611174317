import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './ProductCategory.scss'
import UploadCSV from './UploadCSV';
import Category from '../../data/Category.json'
import store from '../../Store/Store'
import { getCookie } from '../../global/js/browserfunction'
class ProductImportExport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            breadthcrum: ''


        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }

    }
    /**
* @description The function executed for redirect the page when the cookie are not available
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        var breadthcrum = "Import " + '/' + ' Products';
        this.setState({ breadthcrum: breadthcrum })
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
    }


    render() {
        return (
            <div className='Category-main  h-screen xl:h-full'>
                < Breadthcrumbs Breadthcrumb={'Products'} Count={2} level={'Import'} />
                <div className='category-content'>
                    <UploadCSV Category={Category.Category} Homepage={Category.Homepage} />
                </div>
            </div>
        )
    }
};

export default ProductImportExport;