import React, { Component } from "react";
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.loading //To show loaded
        };
    }

    shouldComponentUpdate(nextProps, nextState) //To hide/show loader based on parent component request
    {

        if (this.state.loading !== nextProps.loading) {
            this.setState({ loading: nextProps.loading });
            return true;

        }
        return false;
    }


    render() {
        return (
            <div className={this.state.loading ? 'parentDisablefull' : ''} width="100%">
                <div className='overlay-box'>
                    <ScaleLoader
                        // css={override}
                        sizeUnit={"px"}
                        size={25}
                        color={'#6e768e'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default Loader;