import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from './../../Store/Store'
class SimpleFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

    }
    /**
   * @description The function executes before component call
   */
    componentDidMount() {
        store.dispatch({ type: 'Header', header: true })
    }

    render() {
        return (
            < div className=' w-max m-auto mt-10' >
                <img src='/static/images/header/Nickollas_icon.png' alt='' className='w-max' />
            </div >
        )
    }
};

export default SimpleFooter;