import React, { Component } from 'react'
import Chart from 'react-apexcharts'
import store from './../../Store/Store'
import { CurrencyFormatSpace } from './../../global/js/browserfunction'
var menuid;
class NetAmount extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.handleChange = this.handleChange.bind(this)
        this.handleNetamount = this.handleNetamount.bind(this)
        this.state = {
            menuid: store.getState().todos,
            Currency: props.Currency,
            total: props.total,
            NetAmount: props.NetAmount,
            options: {
                labels: ['Uk', 'International'],
                colors: ['#6BB4C6', '#2E85B8'],
                legend: {
                    show: false,
                    fontFamily: 'Open sans',
                    fontSize: '12px',

                },
                stroke: {
                    width: 0
                },
                plotOptions: {
                    pie: {
                        customScale: 0.8,

                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                },
                dataLabels: {
                    style: {
                        fontFamily: 'Open sans',
                        fontSize: '14px',
                        fontWeight: 'bold',

                    },
                },

            },
            series: props.NetAmount,
            chart: {
                type: 'donut',
            },
            dataLabels: {
                enabled: false
            },
        }
    }
    /**
* @description The function executed set the state of the sidebar
*/
    async handleChange() {
        console.log(this.props.total)
        store.subscribe(() => {
            this._isMounted && this.setState({ menuid: store.getState().todos })
        })


    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.total !== this.state.total) {
            this.setState({ total: nextProps.total })
        }
        if (nextProps.NetAmount !== this.state.NetAmount) {
            this.setState({ NetAmount: nextProps.NetAmount })
            this.setState({ series: nextProps.NetAmount })
            this.handleNetamount(nextProps.NetAmount)
        }
    }
    /**
* @description The function executed handle the amount of uk and international
*/
    handleNetamount(Amounts) {
        if (Amounts[0] === 0 && Amounts[1] === 0) {
            let net_amount = [100]
            let options = {
                labels: ['No Orders'],
                colors: ["#AEB0B8", "#CDD2DF"],
                legend: {
                    show: false,
                    fontFamily: 'Open sans',
                    fontSize: '12px',

                },
                stroke: {
                    width: 0
                },
                plotOptions: {
                    pie: {
                        customScale: 0.8,

                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                },
                dataLabels: {
                    style: {
                        fontFamily: 'Open sans',
                        fontSize: '14px',
                        fontWeight: 'bold',

                    },
                },

            }
            this.setState({ options: options })
            this.setState({ series: net_amount })
        } else {
            let options = {
                labels: ['Uk', 'International'],
                colors: ['#6BB4C6', '#2E85B8'],
                legend: {
                    show: false,
                    fontFamily: 'Open sans',
                    fontSize: '12px',

                },
                stroke: {
                    width: 0
                },
                plotOptions: {
                    pie: {
                        customScale: 0.8,

                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                },
                dataLabels: {
                    style: {
                        fontFamily: 'Open sans',
                        fontSize: '14px',
                        fontWeight: 'bold',

                    },
                },

            }
            this.setState({ options: options })
            this.setState({ series: Amounts })
        }
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleChange();
    }
    render() {
        return (
            <div className=''>
                <div className='flex'>
                    < h3 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold' > Total Net Amount</h3 >
                    <span className=" font-OpenSans items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-white bg-redcolor rounded-full h-5 my-auto">Today</span>
                </div >
                <h2 className={' px-2 xl:text-3xl text-redcolor font-NotoSans font-bold   net-amount-price pl-4 xl:pl-0 relative xl:text-center '}> {CurrencyFormatSpace(this.state.total)}</h2>
                <div className={' m-auto  xl:px-2 ' + (this.state.menuid === true ? 'flex w-max xl:m-auto xl:mr-10 ' : 'flex w-max xl:m-auto xl:mr-10')}>
                    {/* The Appexchart Line chart of wineye Dashboard */}
                    <Chart options={this.state.options} series={this.state.series} className='donut-chart' type="donut" height={280} />
                    <div className={' my-auto pl-4 '}>
                        <div className={'flex ' + (this.state.total === 0 ? 'hidden' : '')}>
                            <span className='w-3 h-3 bg-otherblue my-auto'></span>
                            <span className='text-base font-OpenSans font-normal text-bluecolor pl-2'>UK</span>
                        </div>
                        <div className={'flex ' + (this.state.total === 0 ? 'hidden' : '')} >
                            <span className='w-3 h-3 bg-ukblue my-auto'></span>
                            <span className='text-base font-OpenSans font-normal text-bluecolor pl-2' >International</span>
                        </div>
                        <div className={'flex ' + (this.state.total === 0 ? 'xl:mr-5' : 'hidden')} >
                            <span className='w-3 h-3 bg-international_grey my-auto'></span>
                            <span className='text-base font-OpenSans font-normal text-bluecolor pl-2' >No Orders</span>
                        </div>
                    </div>
                </div>

            </div >

        )
    }
};

export default NetAmount;