import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './HomePageCustomization.scss'
import HomePage from './HomePage';
import HomePageCustom from '../../data/HomePage.json'
import Select from 'react-select'
import store from '../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { addNewFeaturedProductAPI, listHomePageCategoryAPI, listCategoryAPI } from './../../API/Category'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { showNotification } from './../../global/js/notification'
class HomepageCustomAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Type: '',
            Update: {},
            States: '',
            categorylist: [],
            categoryid: '',
            categoryupdate: true

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleUpdate = this.HandleUpdate.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.HandleHomePage = this.HandleHomePage.bind(this);

    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed get the value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })

        })
    }
    /**
* @description The function executed get the categoryid
*/
    handleCategory = selectedOption => {
        this.setState({ categoryid: selectedOption });
        console.log(selectedOption)
        var Update = this.state.Update
        Update['CATEGORY'] = selectedOption.id;
        console.log(Update)

    };
    /**
* @description The function executed for when the component exists
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this.Handlechange()
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        let type;
        store.dispatch({ type: 'URL', url: urls })
        let params = new URLSearchParams(document.location.search)
        // check the status if edit or detail and also the type of the page
        if ((params.get("Edit") == null || params.get("Edit") == '') || (params.get("Type") == null || params.get("Type") == '')) {
            this.props.history.push('/homepage')
        } else {
            let edit = params.get("Edit");
            store.dispatch({ type: 'EditOrder', edits: JSON.parse(edit) })
            type = params.get("Type");

            // this.setState({ Edit: JSON.parse(edit) });
            this.setState({ Type: type });
        }
        //Based of the Type Changes the Header of the page
        if (type == 'Banner' || type == 'Bottombanner') {
            this.setState({ States: 'Add Banner' });
        } else {
            this.setState({ States: 'Add Category' });
        }
        console.log(this.state.Update)
    }
    /**
* @description The function executed for cancel the edit of the page
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })

    }
    /**
* @description The function executed for get the changes of the input box and store as the object
*/
    HandleUpdate(e) {
        let update = this.state.Update;
        let key = e.target.id;
        let value = e.target.value;
        update[key] = value;
        console.log(update)
        this.setState({ Update: update });
    }
    /**
* @description The function executed submit the form
*/
    HandleSubmit(e) {
        e.preventDefault();
        let token;
        let email;
        var newupdate = this.state.Update;
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        if (newupdate.CATEGORY) {
            this.setState({ categoryupdate: true });
            var query = this.Handleconvert(newupdate)
            var self = this;
            var details = [
                "category"
            ];
            console.log(query)
            //api for list category
            addNewFeaturedProductAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.addNewFeaturedProduct?.summary?.reason;
                    let status = res?.data?.addNewFeaturedProduct?.summary?.status;
                    if (reason == "RECORD_CREATED" && status == "SUCCESS") {
                        self.props.history.push("/homepage");
                    } else {
                        showNotification("Sorry Category Not Added", "danger");
                    }
                });
        } else {
            this.setState({ categoryupdate: false });
        }


    }
    /**
  * @description The function executed for conavert the object to string
  */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            TITLE: "TITLE"
        };
        let booleanFields = { CATEGORY: "CATEGORY", MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed change list homepage category
*/
    Handlechange() {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "record_id",
            "image",
            "title",
            "category",
        ];
        var bannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
        ];
        var bottombannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
            "description",
            "category",
        ];
        //api for list category
        listHomePageCategoryAPI(query, details, bannerdetails, bottombannerdetails)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.listHomePageCategory?.summary?.reason;
                let status = res?.data?.listHomePageCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listHomePageCategory.details;
                    let Type = self.state.Type
                    var category = [];
                    var newarray = [];
                    data.featureCategory.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });
                    data.featureProducts.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });
                    data.bottomHeader.forEach(element => {
                        newarray.push({ label: element.title + ' ( ID:' + element.category + ' )', value: element.category })
                    });

                    self.HandleHomePage(newarray)
                    var id = parseInt(self.state.Id);
                    // self.setState({ loading: false })
                    var Update = self.state.Update
                    if (category.category && category.category != null && category.category != '') {
                        Update['CATEGORY'] = category.category;
                    }


                } else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed change in home and menu bar category
*/
    HandleHomePage(array) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['HOME_PAGE'] = "true"
            newupdate['MENU_BAR'] = "false"

        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "id",
            "url",
            "label",
            "date_created",
            "date_modified",
            "is_inactive",
            "name",
            "show_in_menubar",
            "order_precedence",
            "is_homepage_category",
            "level",
            "sql_products_on_category",
            "parent_category_id",
        ];
        //api for list orders
        listCategoryAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listCategory?.summary?.reason;
                let status = res?.data?.listCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listCategory.details;
                    self.setState({ loading: false })
                    self.setState({ category: data });
                    let newarray = []
                    data = data.forEach(element => {
                        newarray.push({ label: element.label + ' ( ID:' + element.id + ' )', value: element.id + '(' + element.label + ')', id: element.id })
                    });
                    // console.log(array, newarray);
                    // let result = newarray.filter(o1 => !array.some(o2 => o1.value === o2.value));
                    self.setState({ categorylist: newarray });
                } else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    render() {
        return (
            <div className={'HomePageCustomization-main  h-screen xl:h-full ' + (this.state.States)}>
                <ReactNotification />
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={this.state.States} Count={3} />
                    <Link to='/homepage' className='flex bg-white w-56 rounded-xl mt-2 mr-3 border pl-2 h-8'><img src='/static/Icons/surface.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To HomePage</span>
                    </Link>
                </div>
                <div className='HomePageCustomization-main-content px-5'>
                    <form onSubmit={this.HandleSubmit}>
                        <div className='bg-white px-5 py-5'>
                            <div className='w-full flex'>
                                <div className='flex w-1/2 px-3'>
                                    {/* To choose thr header based on the Type */}
                                    <img src='/static/Icons/surface.svg' alt='' className='xl:w-8 w-5' />
                                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Banner' ? '' : 'hidden')}>Add New Banner</span>
                                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Category' ? '' : 'hidden')}>Add New Category</span>
                                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Product' ? '' : 'hidden')}>Add New Category</span>
                                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto ' + (this.state.Type == 'Bottombanner' ? '' : 'hidden')}>Add New Banner</span>
                                </div>
                                <div className={'w-1/2 text-right my-auto w-max ml-auto mr-5 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                    <span className={'ml-auto w-max block ml-auto mr-4 cursor-pointer ' + (this.state.Edit == true ? 'hidden' : '')} onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>

                                </div>
                                <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                                    <div className='buttons w-max flex ml-auto h-8'>
                                        <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                            <button type="submit" className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                                <div className='flex'>
                                                    <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                                    <span className='ml-2 mx-auto'>Save</span>
                                                </div>
                                            </button>
                                        </div>
                                        <Link to='/homepage'>
                                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl' >Cancel</span></Link>
                                    </div>
                                </div>
                            </div>
                            <hr className="sortfilterborder my-4"></hr>
                            {/* The section for edit when type is banner  */}
                            {/* <div className={'mt-5 px-4 ' + (this.state.Type == 'Banner' ? '' : 'hidden')}>
                                <table className="table-auto">
                                    <tbody>
                                        <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm align-top w-48 py-2'>Banner image</td>
                                            <td className='py-2'><div className={' h-36 bg-no-repeat  bg-cover bg-backgrey ' + (this.state.Update.url === '' ? '' : 'hidden')}> <div className={'w-max m-auto top-3/12 relative cursor-pointer '}> <input type="file" id='url' accept="image/*" className='cursor-pointer' onChange={this.HandleUpdate} /><img src=' /static/Icons/upload.svg' alt='' className='w-8 m-auto  cursor-pointer' />
                                                <span className='font-OpenSans font-normal text-xs text-greytext'>Upload Image</span>
                                            </div>
                                            </div>
                                                <div className={' h-36 bg-no-repeat  bg-cover opacity-60 bg-backgrey ' + (this.state.Update.url === '' ? 'hidden' : '')} style={{
                                                    backgroundImage: 'url(' + this.state.Update.url + ')'
                                                }}> <div className={'w-max m-auto top-3/12 relative cursor-pointer '}> <input type="file" id='url' name="img" accept="image/*" className='cursor-pointer' onChange={this.HandleUpdate} /><img src=' /static/Icons/replace.svg' alt='' className='w-8 m-auto  cursor-pointer' />
                                                        <span className='font-OpenSans font-normal text-xs text-white'>Replace Image</span>
                                                    </div>
                                                </div></td>

                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans font-bold text-bluecolor xl:text-sm text-sm w-48 py-2 align-top'>Description</td>
                                            <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'><textarea type='text' rows='3' id='description' className={'resize-none pl-2 font-OpenSans font-normal text-sm text-bluecolor border rounded-md  md:w-96 focus:border-redcolor '} onChange={this.handleBilling} required maxLength={this.state.varchar['LG']} required onChange={(e) => this.HandleUpdate(e)} /></td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2'>Url</td>
                                            <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'>
                                                <input type='text' id='url' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-2 focus:border-redcolor md:w-96 ' + (this.state.Edit == true ? '' : 'hidden')} required maxLength={this.state.varchar['LG']} onChange={(e) => this.HandleUpdate(e)} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
                            {/* The section for edit the type is not banner */}
                            <div className={'mt-5 px-4 ' + (this.state.Type != 'Banner' ? '' : 'hidden')}>
                                <table className="table-auto">
                                    <tbody>
                                        <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Title & Description *</td>
                                            <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2 word-breaks-list'>  <input type='text' id='TITLE' name='TITLE' className={' pl-2 font-OpenSans font-normal text-sm text-bluecolor border rounded-md  md:w-96 focus:border-redcolor '} required={this.state.Edit} maxLength={this.state.varchar['XM']} onChange={(e) => this.HandleUpdate(e)} /></td>
                                        </tr>
                                        <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2 align-top'>Category Id *</td>
                                            <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'>
                                                <span className={'phone-select order-page custom mb-2' + (this.state.Edit === true ? '  ' : 'hidden')}><Select
                                                    value={this.state.categoryid}
                                                    onChange={this.handleCategory}
                                                    options={this.state.categorylist} isSearchable={false}
                                                /> </span>
                                                <span className={'font-OpenSans font-normal  text-redcolor  text-xs    ' + (this.state.Edit === true && this.state.categoryupdate === false ? '' : "hidden")}>Choose Category</span>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm align-top w-48 py-2'>Image</td>
                                            <td className='py-2'><div className={' h-48 bg-no-repeat  bg-cover bg-backgrey w-52 opacity-60  ' + (this.state.Update.url === '' ? ' ' : 'hidden')}><div className={'w-max m-auto top-4/12 relative '}> <input type="file" id='url' name="img" accept="image/*" className='cursor-pointer' onChange={this.HandleUpdate} /><img src=' /static/Icons/upload.svg' alt='' className='w-8 m-auto  cursor-pointer' />   <span className='font-OpenSans font-normal text-xs text-greytext'>Upload Image</span>
                                            </div>
                                            </div>
                                                <div className={' h-48 bg-no-repeat  bg-cover w-52  opacity-60 bg-backgrey ' + (this.state.Update.url === '' ? 'hidden' : '')} style={{
                                                    backgroundImage: 'url(' + this.state.Update.url + ')'
                                                }}><div className={'w-max m-auto top-4/12 relative '}> <input type="file" id='url' name="img" accept="image/*" className='cursor-pointer' onChange={this.HandleUpdate} /><img src=' /static/Icons/replace.svg' alt='' className='w-8 m-auto  cursor-pointer' />   <span className='font-OpenSans font-normal text-xs text-white'>Replace Image</span>
                                                    </div>
                                                </div>  </td>

                                        </tr> */}
                                        {/* <tr>
                                            <td className='font-OpenSans font-bold  text-bluecolor xl:text-sm text-sm  w-48 py-2'>url</td>
                                            <td className='font-OpenSans font-normal  text-bluecolor xl:text-sm text-sm align-top max-w-sm py-2'>
                                                <input type='text' id='url' className={'font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-2 focus:border-redcolor md:w-96 '} required maxLength={this.state.varchar['LG']} onChange={(e) => this.HandleUpdate(e)} /></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
};

export default HomepageCustomAdd;