import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import history from './global/js/history';
import Dashboard from './pages/Dashboard/Dashboard'
import Delivery from './pages/DeliveryCharge/Delivery';
import Login from './pages/Login/Login';
import Signup from './pages/Login/SignUp';
import Forgot from './pages/Login/Forgot';
import NotFound from './pages/NoFound/NotFound';
import Order from './pages/Order/Order'
import OrderDetails from './pages/OrderDetails/OrderDetails'
import Payment from './pages/Payment/Payment';
import PaymentDetail from './pages/PaymentDetail/PaymentDetail';
import Product from './pages/Product/Product';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Reset from './pages/Login/Reset';
import PaymentMethod from './pages/PaymentMethod/PaymentMethod';
import PaymentMethodDetail from './pages/PaymentMethodDetail/PaymentMethodDetail';
import ProductCategory from './pages/ProductCategory/ProductCategory';
import AddCategory from './pages/ProductCategory/AddCategory';
import EditCategory from './pages/ProductCategory/EditCategory';
import ViewCategory from './pages/ProductCategory/ViewCategory';
import HomePageCustomization from './pages/HomePageCustomization/HomePageCustomization';
import HomepageCustom from './pages/HomePageCustomization/HomepageCustom';
import Customer from './pages/Customer/Customer';
import CustomerDetails from './pages/CustomerDetails/CustomerDetails'
import CustomerEditAddress from './pages/CustomerDetails/CustomerEditAddress';
import HomepageCustomAdd from './pages/HomePageCustomization/HomepageCustomAdd';
import User from './pages/User/User';
import NewUser from './pages/User/NewUser';
import UserDetail from './pages/UserDetail/UserDetail';
import ProductImportExport from './pages/ProductImportExport/ProductImportExport';
import Mapping from './pages/ProductImportExport/Mapping';
import Importing from './pages/ProductImportExport/Importing';
import ImportDone from './pages/ProductImportExport/ImportDone';
import AddProductDetail from './pages/AddProductDetail/AddProductDetail';
import Settings from './pages/Settings/Settings';
import Cronjob from './pages/Cronjob/Cronjob';
import Refund from './pages/PaymentDetail/Refund/Refund';
const Routes = () => (
    <Switch >
        <Route exact path="/" component={Login} />
        <Route path="/home" component={Dashboard} />
        <Route exact={true} path="/user" component={User} />
        <Route exact={true} path="/user/NewUser" component={NewUser} />
        <Route exact={true} path="/user/userdetail/:id" component={UserDetail} />
        <Route exact={true} path="/importproducts" component={ProductImportExport} />
        <Route exact={true} path="/importproducts/mapping" component={Mapping} />
        <Route exact={true} path="/importproducts/mapping/importing" component={Importing} />
        <Route exact={true} path="/importproducts/mapping/importing/done" component={ImportDone} />
        <Route exact={true} path="/order" component={Order} />
        <Route exact={true} path="/order/orderdetail/:id" component={OrderDetails} />
        <Route exact={true} path="/payment" component={Payment} />
        <Route exact={true} path="/payment/paymentdetails/:id" component={PaymentDetail} />
        <Route exact={true} path="/product" component={Product} />
        <Route exact={true} path="/addproduct" component={AddProductDetail} />
        <Route exact={true} path="/product/productdetails/:id" component={ProductDetail} />
        <Route exact={true} path="/delivery" component={Delivery} />
        <Route exact={true} path="/paymentmethod" component={PaymentMethod} />
        <Route exact={true} path="/paymentmethod/paymentdetails/:id" component={PaymentMethodDetail} />
        <Route exact={true} path="/payment/paymentdetails/:id/refund" component={Refund} />
        <Route exact={true} path="/category" component={ProductCategory} />
        <Route exact={true} path="/category/addcategory/:id" component={AddCategory} />
        <Route exact={true} path="/category/editcategory/:id" component={EditCategory} />
        <Route exact={true} path="/category/viewcategory/:id" component={ViewCategory} />
        <Route exact={true} path="/homepage" component={HomePageCustomization} />
        <Route exact={true} path="/homepage/customize/:id" component={HomepageCustom} />
        <Route exact={true} path="/homepage/customization/:id" component={HomepageCustomAdd} />
        <Route exact={true} path="/customer" component={Customer} />
        <Route exact={true} path="/customer/customerdetails/:id" component={CustomerDetails} />
        <Route exact={true} path="/customer/customerdetails/address/:id" component={CustomerEditAddress} />
        <Route exact={true} path="/cronjobs" component={Cronjob} />
        <Route path="/settings" component={Settings} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/reset" component={Reset} />
        <Route path="" component={NotFound} />
    </Switch>
);
export default Routes;