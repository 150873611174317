import React, { Component } from 'react';
import './App.css';
import './global.css';
import './components/Header/Header.scss'
import Header from './components/Header/Header'
import header from './data/Header.json'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Routes from './Routes';
import Sidebar from './components/Header/Sidebar'
import store from './Store/Store'
import SimpleHeader from './components/Header/SimpleHeader';
import SimpleFooter from './components/Footer/SimpleFooter';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { getCookie, eraseCookie } from './global/js/browserfunction'
class App extends Component {

  constructor(props) {
    super(props);
    store.dispatch({ type: 'Sidebar', Sidebar: true })
    this.state = {
      sidebar: 'block',
      noheader: 'hidden',
      header: store.getState().Header,

    };
  }
  /**
* @description The function executed for get the header value from store
*/
  async handleCheck() {
    store.subscribe(() => {
      this._isMounted && this.setState({ header: store.getState().Header })
    })
    //check whether display the simpler or header are show
    store.subscribe(() => {
      if (store.getState().Header == true || !getCookie("wn_log")) {
        this.setState({ sidebar: 'hidden' })
        this.setState({ noheader: 'block' })
      } else {
        this.setState({ sidebar: 'block' })
        this.setState({ noheader: 'hidden' })
      }
    })
  }
  /**
* @description The function executed for component exists
*/
  componentWillUnmount() {
    this._isMounted = false;

  }
  componentDidUpdate(prevProps) {
  }
  /**
* @description The function executed for before the component call
*/
  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.handleCheck();
    let url = window.location.pathname;
    if (url == '/login' || url == '/signup' || url == '/forgot' || url == '/reset' || url == '/') {
      store.dispatch({ type: 'Header', header: true })
    }
    else {
      store.dispatch({ type: 'Header', header: false })
    }

  }

  render() {
    return (
      <React.Fragment>
        <Router >
          {/* The header with all components */}
          <div className={this.state.sidebar}>
            <Header Navlist={header.Navbar} Iconmap={header.IconMap} />
            <div className='index'>
              <div className='xl:flex block'>
                <Sidebar Iconmap={header.IconMap} />
                <div className='w-full '>
                  <Routes />
                </div>
              </div>

            </div >
          </div>
          {/* The simplified header  */}
          {this.state.noheader == 'block' &&
            <div className={'log-main h-screen ' + this.state.noheader} >
              <SimpleHeader />
              <div className='index '>
                <Routes />
              </div>
              <SimpleFooter />
            </div>
          }
        </Router>
      </React.Fragment>

    );
  }
}

export default App;
