import React, { Component } from 'react';
import local from '../../global/js/locale';
import store from './../../Store/Store'
import './OrderDetails.scss'
import Select from 'react-select'
import CountryCode from './../../data/countrycode.json'
import { capitalizeFirstLetter } from './../../global/js/browserfunction'
import Bond_warehouse from './../../data/Bond_Warehouse.json'
class ShippingInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: '',
            CountryOption: '',
            MethodOption: '',
            CountryList: props.Countrylist,
            Countrylist: [],
            Shippingmethod: props.ShippingMethods,
            Edit: '',
            orderlist: props.orderlist,
            CurrentOrder: '',
            Billing: '',
            Shipping: '',
            id: props.Id,
            varchar: local.VARCHAR,
            Int: local.INT,
            Shipping_method: local.locale.SHIPPING.METHOD,
            codebilling: '',
            codeshipping: '',
            WareHouselist: [],
            Band_warehouse: {}

        }
        this.handleCountry = this.handleCountry.bind(this);
        this.handleMethod = this.handleMethod.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleBilling = this.handleBilling.bind(this);
        this.handleShipping = this.handleShipping.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);
        this.HandleconvertCode = this.HandleconvertCode.bind(this);
        this.HandleconvertBreak = this.HandleconvertCode.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.orderlist !== this.state.orderlist) {
            var countrycode = CountryCode.CountryCode
            this.setState({ orderlist: nextProps.orderlist })
            var code_billing = this.HandleconvertCode(nextProps.orderlist.billing_phone)
            let obj_billing = countrycode.find(o => o.code === code_billing);
            this.setState({ codebilling: obj_billing });
            var code_shipping = this.HandleconvertCode(nextProps.orderlist.shipping_phone)
            let obj_shipping = countrycode.find(o => o.code === code_shipping);
            console.log(obj_shipping)
            this.setState({ codeshipping: obj_shipping });
            var Band_warehouse = Bond_warehouse.find(o => o.Name === nextProps.orderlist.bond_warehouse_name);
            this.setState({ Band_warehouse: Band_warehouse })
        }



    }
    /**
* @description The function executed for change the country
*/
    handleCountry = selectedOption => {
        this.setState({ CountryOption: selectedOption });
        var update = store.getState().orderupdate;
        update['SHIPPING_COUNTRY'] = selectedOption.value;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    };
    /**
* @description The function executed for change shipping method
*/
    handleMethod = selectedOption => {
        this.setState({ MethodOption: selectedOption });
        var update = store.getState().orderupdate;
        update['SHIPPING_METHOD'] = selectedOption.value;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    };
    /**
   /**
* @description The function executed for change shipping method
*/
    handleWarehouse = selectedOption => {
        this.setState({ Band_warehouse: selectedOption });
        var update = store.getState().orderupdate;
        update['BOND_WAREHOUSE_NAME'] = selectedOption.value;
        update['BOND_WAREHOUSE_ID'] = selectedOption.Internal_ID;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    };
    /**
    * @description The function executed for convert the country and state formated array
    */
    async handleCheck() {
        var countrylist = this.state.CountryList
        var array = [];
        Object.keys(countrylist).forEach(function (key) {
            array.push({
                value: countrylist[key].country,
                label: countrylist[key].country,
                id: countrylist[key].itemid,
            });
            array.sort((a, b) =>
                a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0
            );
        });
        this.setState({ Codes: CountryCode.CountryCode });
        this.setState({ Countrylist: [...this.state.Countrylist, ...array] })
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
        // var id = this.state.id;
        // var obj = this.state.Orderlist.findIndex((el) => el.id === id);
        // var orderdetail = this.state.Orderlist[obj]
        // var billing = this.state.Orderlist[obj].BillingAddress;
        // var shipping = this.state.Orderlist[obj].ShippingAddress;
        // this.setState({ CurrentOrder: orderdetail });
        // this.setState({ Billing: billing });
        // this.setState({ Shipping: shipping });
        // this.setState({ Orderlist: orderdetail });
        // var Country = { value: orderdetail.Country, label: orderdetail.Country }
        // this.setState({ CountryOption: Country });
        // var Method = { value: orderdetail.ShippingMethod, label: orderdetail.ShippingMethod }
        // this.setState({ MethodOption: Method });
        console.log(Bond_warehouse)
        Bond_warehouse.forEach((e, i) => {
            Bond_warehouse[i]["value"] = e.Name;
            Bond_warehouse[i]["label"] = e.Name;
        });
        this.setState({ WareHouselist: Bond_warehouse })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for convert phone number
*@param input-phone number
*/
    Handleconvert(phone) {
        if (phone != undefined) {
            return phone.substr(phone.indexOf(" ") + 1)
        }

    }
    /** 
     * * @description The function executed for convert phone number to code
*@param input-phone number
*/
    HandleconvertCode(phone) {
        if (phone != undefined) {
            return phone.substr(0, phone.indexOf(" "));
        }

    }
    /**
* @description The function executed for change the country code and phone number
*/
    handleCodebilling = selectedOption => {
        this.setState({ codebilling: selectedOption });
        var update = store.getState().orderupdate;
        let phone = document.getElementById("BILLING_PHONE").value
        let phonenumber = selectedOption.code + ' ' + phone
        update['BILLING_PHONE'] = phonenumber;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
        console.log(update)

    };
    /**
* @description The function executed for change the country code and phone number
*/
    handleCodeshipping = selectedOption => {
        this.setState({ codeshipping: selectedOption });
        var update = store.getState().orderupdate;
        let phone = document.getElementById("SHIPPING_PHONE").value
        let phonenumber = selectedOption.code + ' ' + phone
        update['SHIPPING_PHONE'] = phonenumber;
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
        console.log(update)

    };
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
    }
    /**
* @description The function executed for get the changes in billing
*/
    handleBilling(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update = store.getState().orderupdate;
        if (key == 'BILLING_PHONE') {
            if (this.state.codebilling != undefined && this.state.codebilling != null) {
                update['BILLING_PHONE'] = this.state.codebilling.code + ' ' + value
            }
            else {
                update['BILLING_PHONE'] = value
            }

        }
        else if (key === 'BILLING_LANDMARK' || key === 'BILLING_ADDRESS_LINE_1' || key === 'BILLING_ADDRESS_LINE_1' || key === 'BILLING_ADDRESS_LINE_2' || key === 'BILLING_ADDRESS_LINE_3') {
            update[key] = value.replace(/\n/g, ' ');

        } else {
            update[key] = value;
        }
        console.log(update)
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    }
    /**
* @description The function executed for get  the update in shipping address
*/
    handleShipping(e) {
        var key = e.target.name;
        var value = e.target.value;
        var update = store.getState().orderupdate;
        if (key == 'SHIPPING_PHONE') {
            if (this.state.codeshipping != undefined && this.state.codeshipping != null) {
                update['SHIPPING_PHONE'] = this.state.codeshipping.code + ' ' + value
            } else {
                update['SHIPPING_PHONE'] = value
            }

        } else if (key === 'SHIPPING_LANDMARK' || key === 'SHIPPING_ADDRESS_LINE_1' || key === 'SHIPPING_ADDRESS_LINE_2' || key === 'SHIPPING_ADDRESS_LINE_3') {
            update[key] = value.replace(/\n/g, ' ');

        } else {
            if (key === "SPECIAL_INFORMATION") {
                value = `${value}`.replace(/(?:\r\n|\r|\n)/g, "<br>")
            }
            update[key] = value;
        }
        console.log(update)
        store.dispatch({ type: 'UpdateOrder', UpdateOrder: update })
    }
    /**
* @description The function executed for convert the br to string
*/
    HandleconvertBreak(string) {
        if (string != null && string != '') {
            var regex = /<br\s*[\/]?>/gi;
            var str = string.replace(regex, "\n")
            console.log(str)
            return str;
        }
    }
    render() {
        return (
            < div className='shipping-info bg-white mt-5  py-5 px-6 ' >
                <h2 className='font-OpenSans font-semibold  text-bluecolor text-xl px-5'>Shipping Information</h2>
                <hr className="sortfilterborder my-4"></hr>
                <div className='mt-5 w-9/10 m-auto xl:flex'>
                    <div className={'  grid  gap-4 ' + (this.state.orderlist.delivery_option === "CLIENT_STORAGE" ? ' xl:w-6/12 w-full grid-cols-1 ' : ' xl:w-9/12 w-full grid-cols-2 ')}>
                        <div className='billing-address '>
                            <h2 className='font-OpenSans font-semibold  text-bluecolor text-sm'>Billing Address</h2>
                            <div className={'mt-2 ml-1 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_attention} </p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_addressee} </p>
                                <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all ' + (this.state.orderlist.billing_landmark === null || this.state.orderlist.billing_landmark === '' ? 'hidden' : '')}>{this.state.orderlist.billing_landmark} </p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.billing_address_line_1}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.billing_address_line_2}</p>
                                {/* <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all ' + (this.state.orderlist.billing_address_line_3 === null || this.state.orderlist.billing_address_line_3 === '' ? 'hidden' : '')}> {this.state.orderlist.billing_address_line_3}</p> */}
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.billing_country_name}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_state}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_city}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_zip}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.billing_phone}</p>
                            </div>
                            <div className={'mt-6 ml-1 ' + (this.state.Edit === true ? 'block' : 'hidden')}>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Attention *&nbsp;&nbsp; </label>
                                    <div className='w-48 xl:ml-2 xl:w-56 2xl:w-60'>
                                        <input type='text' name='BILLING_ATTENTION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_attention} required maxLength={this.state.varchar['MD']} pattern="\s*\S+.*" title="space only is not allowed" />
                                    </div>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Addressee &nbsp;&nbsp; </label>
                                    <div className='w-48 xl:ml-2 xl:w-56 2xl:w-60'>
                                        <input type='text' name='BILLING_ADDRESSEE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_addressee} maxLength={this.state.varchar['MD']} />
                                    </div>
                                </div>
                                {/* <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans  font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Landmark &nbsp;&nbsp; </label>
                                    <textarea name='BILLING_LANDMARK' className=' resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2 ' onInput={this.handleShipping} defaultValue={this.state.orderlist.billing_landmark === null ? '' : this.state.orderlist.billing_landmark} maxLength={this.state.varchar['LG-1']} />
                                </div> */}
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans  font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 1 *&nbsp;&nbsp; </label>
                                    <textarea name='BILLING_ADDRESS_LINE_1' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2 ' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_address_line_1} required maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" />
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 2 &nbsp;&nbsp; </label>
                                    <textarea name='BILLING_ADDRESS_LINE_2' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_address_line_2} maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" />
                                </div>
                                {/* <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 3 &nbsp;&nbsp; </label>
                                    <textarea name='BILLING_ADDRESS_LINE_3' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_address_line_3} maxLength={this.state.varchar['XL']} />
                                </div> */}
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Country &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md  w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['XL']} >{this.state.orderlist.billing_country_name} </label>
                                </div>

                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>State &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md   w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['XL']} >{this.state.orderlist.billing_state} </label>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>City &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md  w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['MD']} >{this.state.orderlist.billing_city} </label>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Post Code *&nbsp;&nbsp; </label>
                                    <div className='w-48 xl:w-56 2xl:w-60 xl:ml-2'>
                                        <input type='text' name='BILLING_ZIP' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full' onInput={this.handleBilling} defaultValue={this.state.orderlist.billing_zip} required maxLength={this.state.varchar['SM']} />
                                    </div>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Phone Number *&nbsp;&nbsp; </label>
                                    <div className={'2xl:flex xl:ml-2 w-48  ' + (this.state.Edit === true ? ' ' : 'hidden')}><span className='phone-select order-page '><Select
                                        value={this.state.codebilling}
                                        onChange={this.handleCodebilling}
                                        options={this.state.Codes} isSearchable={false}
                                    /> </span>
                                        <input type='text' id='BILLING_PHONE' name='BILLING_PHONE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor lg:w-36 w-full 2xl:ml-1 2xl:mt-0 mt-1' onInput={this.handleBilling} defaultValue={this.Handleconvert(this.state.orderlist.billing_phone)} required minLength="8" maxLength="15" pattern='^(?!0*$)[0-9-]+' /></div>
                                </div>
                            </div>
                        </div>
                        <div className={'shipping-address 2 ' + (this.state.orderlist.delivery_option === 'DELIVERY' ? '' : 'hidden')}>
                            <h2 className='font-OpenSans font-semibold  text-bluecolor text-sm'>Shipping Address</h2>
                            <div className={'mt-2 ml-1 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.shipping_attention} </p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.shipping_addressee} </p>
                                {/* <p className={'font-OpenSans font-normal  text-bluecolor text-sm  py-0.5 break-all' + (this.state.orderlist.shipping_landmark === null || this.state.orderlist.shipping_landmark === '' ? 'hidden' : '')}>{this.state.orderlist.shipping_landmark} </p> */}
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.shipping_address_line_1}</p>
                                <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all ' + (this.state.orderlist.shipping_address_line_2 === null || this.state.orderlist.shipping_address_line_2 === '' ? 'hidden' : '')}> {this.state.orderlist.shipping_address_line_2}</p>
                                {/* <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all ' + (this.state.orderlist.shipping_address_line_3 === null || this.state.orderlist.shipping_address_line_3 === '' ? 'hidden' : '')}> {this.state.orderlist.shipping_address_line_3}</p> */}
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.shipping_country_name}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '> {this.state.orderlist.shipping_state}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.shipping_city}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.shipping_zip}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 break-all '>{this.state.orderlist.shipping_phone}</p>
                            </div>
                            <div className={'mt-6 ml-1 ' + (this.state.Edit === true ? 'block' : 'hidden')}>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Attention *&nbsp;&nbsp; </label>
                                    <div className='w-48 xl:w-56 2xl:w-60 xl:ml-2'>
                                        <input type='text' name='SHIPPING_ATTENTION' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full ' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_attention} required={this.state.orderlist.delivery_option === 'DELIVERY' ? true : false} maxLength={this.state.varchar['MD']} pattern="\s*\S+.*" title="space only is not allowed" />
                                    </div>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Addressee &nbsp;&nbsp; </label>
                                    <div className='w-48 xl:w-56 2xl:w-60 xl:ml-2'>
                                        <input type='text' name='SHIPPING_ADDRESSEE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full ' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_addressee} maxLength={this.state.varchar['MD']} />
                                    </div>
                                </div>
                                {/* <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans  font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Landmark &nbsp;&nbsp; </label>
                                    <textarea name='SHIPPING_LANDMARK' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2 ' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_landmark} maxLength={this.state.varchar['LG-1']} />
                                </div> */}
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 1 *&nbsp;&nbsp; </label>
                                    <textarea name='SHIPPING_ADDRESS_LINE_1' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_address_line_1} required={this.state.orderlist.delivery_option === 'DELIVERY' ? true : false} maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" />
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 2 &nbsp;&nbsp; </label>
                                    <textarea name='SHIPPING_ADDRESS_LINE_2' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_address_line_2} maxLength={this.state.varchar['LG-1']} pattern="\s*\S+.*" title="space only is not allowed" />
                                </div>
                                {/* <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Address Line 3 &nbsp;&nbsp; </label>
                                    <textarea name='SHIPPING_ADDRESS_LINE_3' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_address_line_3 === null ? '' : this.state.orderlist.shipping_address_line_3} maxLength={this.state.varchar['XL']} />
                                </div> */}
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Country &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md  w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['XL']} >{this.state.orderlist.shipping_country_name} </label>
                                </div>

                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>State &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md   w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['XL']} >{this.state.orderlist.shipping_state} </label>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>City &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md  w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['LG-1']} >{this.state.orderlist.shipping_city} </label>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Post Code *&nbsp;&nbsp; </label>
                                    <div className='w-48 xl:w-56 2xl:w-60 xl:ml-2'>
                                        <input type='text' name='SHIPPING_ZIP' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full' onInput={this.handleShipping} defaultValue={this.state.orderlist.shipping_zip} required={this.state.orderlist.delivery_option === 'DELIVERY' ? true : false} maxLength={this.state.varchar['SM']} />
                                    </div>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Phone Number *&nbsp;&nbsp; </label>
                                    <div className={'2xl:flex w-48 xl:ml-2' + (this.state.Edit === true ? ' ' : 'hidden')}><span className='phone-select order-page'><Select
                                        value={this.state.codeshipping}
                                        onChange={this.handleCodeshipping}
                                        options={this.state.Codes} isSearchable={false}
                                    /> </span>
                                        <input type='text' id="SHIPPING_PHONE" name='SHIPPING_PHONE' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor lg:w-36 w-full 2xl:mt-0 mt-1 2xl:ml-1' onInput={this.handleShipping} defaultValue={this.Handleconvert(this.state.orderlist.shipping_phone)} minLength="8" maxLength="15" required={this.state.orderlist.delivery_option === 'DELIVERY' ? true : false} pattern='^(?!0*$)[0-9-]+' /></div>
                                </div>
                            </div>
                        </div>
                        <div className={'shipping-address transfer_bond ' + (this.state.orderlist.delivery_option === 'DELIVERY' || this.state.orderlist.delivery_option === 'CLIENT_STORAGE' ? ' hidden ' : '')}>
                            <h2 className='font-OpenSans font-semibold  text-bluecolor text-sm'>Bonded Warehouse</h2>
                            <div className={'mt-2 ml-1 ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 '>{this.state.orderlist?.bond_warehouse_customer} </p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 '>{this.state.orderlist?.bond_warehouse_name} </p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 '> {this.state.orderlist?.bond_warehouse_account}</p>
                                <p className='font-OpenSans font-normal  text-bluecolor text-sm py-0.5 w-48 xl:w-60 2xl:w-64'> {this.state.Band_warehouse?.Address}</p>


                            </div>
                            <div className={'mt-6 ml-1 ' + (this.state.Edit === true ? 'block' : 'hidden')}>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Customer Bond Name *&nbsp;&nbsp; </label>
                                    <div className='w-48 xl:w-56 2xl:w-60 xl:ml-2'>
                                        <input type='text' name='BOND_WAREHOUSE_CUSTOMER' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full ' onInput={this.handleShipping} defaultValue={this.state.orderlist.bond_warehouse_customer} required={this.state.orderlist.delivery_option === 'BOND_WAREHOUSE' ? true : false} maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" title="space only is not allowed" />
                                    </div>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <span className='font-OpenSans font-normal  text-bluecolor text-sm xl:mr-0 mr-0 xl:w-36 w-32'>Bond Warehouse Name *</span>
                                    <span className={'w-48 xl:w-56 py-2 block shipping-country warehouse_bond  ml-2 ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                        value={this.state.Band_warehouse}
                                        onChange={this.handleWarehouse}
                                        options={this.state.WareHouselist} isSearchable={false}
                                    /></span>
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans  font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Account Number * &nbsp;&nbsp; </label>
                                    <textarea name='BOND_WAREHOUSE_ACCOUNT' className='resize-none font-OpenSans input-text-area font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-48 xl:w-56 2xl:w-60 xl:ml-2 ' onInput={this.handleShipping} defaultValue={this.state.orderlist.bond_warehouse_account} maxLength={this.state.varchar['MD']} required={this.state.orderlist.delivery_option === 'BOND_WAREHOUSE' ? true : false} />
                                </div>
                                <div className='w-full flex pb-2'>
                                    <label className='font-OpenSans font-normal text-sm text-bluecolor xl:mr-0 mr-2 xl:w-36 w-32'>Bond Warehouse Address &nbsp;&nbsp; </label>
                                    <label className='resize-none font-OpenSans  font-normal text-sm text-bluecolor  rounded-md  w-48 xl:w-56 2xl:w-60 xl:ml-2' maxLength={this.state.varchar['XL']} >{this.state.Band_warehouse?.Address} </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='mt-5 w-9/10 m-auto xl:mt-15 mt-8 xl:hidden block'>
                        <h2 className={'font-OpenSans font-semibold  text-bluecolor text-sm mb-5 ' + ((this.state.orderlist.special_information === null && this.state.Edit === false) || (this.state.orderlist.special_information === '' && this.state.Edit === false) ? 'hidden' : '')}>Special Instructions</h2>
                        <div className='w-full flex pb-2'>
                            <div className='w-full flex pb-2'>
                                <div className='w-80 xl:w-80 2xl:w-80 xl:ml-2'>
                                    <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 w-48 xl:w-60 2xl:w-64 ' + (this.state.Edit === true ? 'hidden' : '')}> <div dangerouslySetInnerHTML={{ __html: this.state.orderlist?.special_information?.replace(/(?:\r\n|\r|\n)/g, "<br>") }} />

                                    </p>
                                    <textarea type='text' name='SPECIAL_INFORMATION' className={'resize-none font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full ' + (this.state.Edit === true ? 'block' : 'hidden')} onInput={this.handleShipping} defaultValue={this.state.orderlist.special_information} maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" rows="5" title="space only is not allowed" maxLength={200}>{this.state.orderlist.special_information}</textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='xl:w-3/12 w-full xl:mt-2 mt-8 '>
                        <div className='xl:w-max m-auto flex xl:block'>
                            <div className='xl:w-full w-1/2'>
                                <div className={'xl:pb-1 xl:pt-1 py-1 flex'}>
                                    <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Shipping Country</span>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm ml-4 '}>{this.state.orderlist.shipping_country_name}</span>
                                    {/* <span className={'xl:w-11/12 w-52  py-2 block shipping-country status-select ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                        value={this.state.CountryOption}
                                        onChange={this.handleCountry}
                                        options={this.state.Countrylist} isSearchable={false}
                                    /></span> */}
                                </div>
                                <div className={' xl:pb-1 xl:pt-1 py-1 flex '}>
                                    <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Shipping Method </span>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm ml-4 '}>{this.state.orderlist.shipping_method} </span>
                                    {/* <span className={'xl:w-11/12 w-52  py-2 block shipping-country status-select ' + (this.state.Edit === true ? 'block' : 'hidden')}> <Select
                                        value={this.state.MethodOption}
                                        onChange={this.handleMethod}
                                        options={this.state.Shippingmethod} isSearchable={false}
                                    /></span> */}
                                </div>
                                <div className={' xl:pb-1 xl:pt-1 py-1 flex '}>
                                    <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Country Code </span>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm ml-4 '}>{this.state.orderlist.shipping_country_code} </span>

                                </div>
                                <div className={' xl:pb-1 xl:pt-1 py-1 flex '}>
                                    <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Delivery Option </span>
                                    <span className={'font-OpenSans font-normal  text-bluecolor text-sm ml-4 '}>{capitalizeFirstLetter(this.state.orderlist.delivery_option)} </span>

                                </div>

                            </div>
                            <div className='xl:w-full w-1/2'>
                                <div className={' xl:mr-auto xl:ml-0 ml-3 w-max xl:mt-0   ' + (this.state.Edit === true ? 'xl:mt-3' : '')}>
                                    <div className='flex  xl:pb-1 xl:pt-1 py-1  '>
                                        <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Payment </span>
                                        <span className='font-OpenSans font-normal  text-bluecolor text-sm ml-4'>{this.state.orderlist.payment_name} </span>
                                    </div>
                                    <div className='flex  xl:pb-1 xl:pt-1 py-1'>
                                        <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Currency</span>
                                        <span className='font-OpenSans font-normal  text-bluecolor text-sm ml-4'>{this.state.orderlist.currency_name} </span>
                                    </div>
                                    <div className='flex  xl:pb-1 xl:pt-1 py-1'>
                                        <span className='font-OpenSans font-semibold  text-bluecolor text-sm w-40'>Sync with NetSuite</span>
                                        <span className={'h-4 w-4 ml-4  text-white my-auto font-OpenSans font-normal  text-bluecolor text-sm ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                                            {this.state.orderlist.sync_with_netsuite === true ? 'Yes' : 'No'}
                                            {/* <input type="checkbox" className={"form-checkbox  h-4 w-4 ml-4  text-white my-auto " + (this.state.orderlist.sync_with_netsuite === true ? 'order-sync-netsuite' : 'order-sync')} defaultChecked={this.state.CurrentOrder.Sync} readOnly /> */}
                                        </span>
                                        <div className={'flex ' + (this.state.Edit === true ? 'block' : 'hidden')} >
                                            <div className='flex'>
                                                <span> <input type="checkbox" className={"form-checkbox   h-4 w-4 ml-4  text-white my-auto " + (this.state.orderlist.sync_with_netsuite === true ? 'order-sync-netsuite' : 'order-sync')} defaultChecked={true} readOnly /></span>
                                                <span className='font-OpenSans font-normal  text-bluecolor text-sm ml-2 my-auto pt-1'>Yes</span>
                                            </div>
                                            <div className='flex'>
                                                <span> <input type="checkbox" className={"form-checkbox   h-4 w-4 ml-4  text-white my-auto " + (this.state.orderlist.sync_with_netsuite === false || this.state.orderlist.sync_with_netsuite === null ? 'order-sync-netsuite' : 'order-sync')} defaultChecked={!this.state.CurrentOrder.Sync} readOnly /></span>
                                                <span className='font-OpenSans font-normal  text-bluecolor text-sm ml-2 my-auto pt-1'>No</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-5 w-9/10 m-auto xl:mt-15 mt-8 xl:block hidden'>
                    <h2 className={'font-OpenSans font-semibold  text-bluecolor text-sm mb-5 ' + ((this.state.orderlist.special_information === null && this.state.Edit === false) || (this.state.orderlist.special_information === '' && this.state.Edit === false) ? 'hidden' : '')}>Special Instructions</h2>
                    <div className='w-full flex pb-2'>
                        <div className='w-80 xl:w-80 2xl:w-80 xl:ml-2'>
                            <p className={'font-OpenSans font-normal  text-bluecolor text-sm py-0.5 w-48 xl:w-60 2xl:w-64 ' + (this.state.Edit === true ? 'hidden' : '')}> <div dangerouslySetInnerHTML={{ __html: this.state.orderlist?.special_information?.replace(/(?:\r\n|\r|\n)/g, "<br>") }} />

                            </p>
                            <textarea type='text' name='SPECIAL_INFORMATION' className={'resize-none font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor w-full ' + (this.state.Edit === true ? 'block' : 'hidden')} onInput={this.handleShipping} defaultValue={this.state.orderlist.special_information} maxLength={this.state.varchar['LG']} pattern="\s*\S+.*" rows="5" title="space only is not allowed" maxLength={200}>{this.state.orderlist.special_information}</textarea>
                        </div>
                    </div>
                </div>
            </div >

        )
    }
};

export default ShippingInfo;