import { BASEURL } from './Baseurl'

/**
     * @description  The function for fetch the leadtime infromation
     */
function fetchLeadTimeInformationAPI(query, details, dutydetails) {
    //The details are convert to string
    let detail = details.toString();
    let dutydetail = dutydetails.toString();
    return fetch(BASEURL + 'graphql/leadtime', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchLeadTimeInformation(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        leadtime{
        
                            ${detail}
                  
                          }
                          duty_details{
                            ${dutydetail}
                          }
                          markup_percentage
                          buffer_item_value
                          forwarded_to
                       }
                 
                }
                }`,
            }
        ),
    })
}

/**
     * @description  The function for update the leadtime and markup
     */
function updateLeadTimeInformationAPI(query, leadtime, emaillist, details, duty) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/leadtime', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateLeadTimeInformation(${query}LEAD_TIME:"${leadtime}",FORWARDED_TO:"${emaillist}",DUTY:"${duty}")
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       
                          ${detail}
                       }
                 
                }
                }`,
            }
        ),
    })
}
function fetchDutyDetailsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/leadtime', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                query{
                    fetchDutyDetails(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                        list{
        
                            ${detail}
                  
                          }
                          totalCount
                       }
                 
                }
                }`,
            }
        ),
    })
}
function updateDutyDetailsInformationAPI(query) {
    //The details are convert to string

    return fetch(BASEURL + 'graphql/leadtime', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                mutation{
                    updateDutyDetailsInformation(${query})
                       {
                   summary{
                       status
                       reason
                       }
                       details
                       {
                       
                        list{
                            record_row_id
                        }
                       }
                 
                }
                }`,
            }
        ),
    })
}
export {
    fetchLeadTimeInformationAPI,
    updateLeadTimeInformationAPI,
    fetchDutyDetailsAPI,
    updateDutyDetailsInformationAPI


}