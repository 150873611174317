import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.scss'
import store from './../../Store/Store'
import { emailregex } from './../../global/js/validator'
import local from '../../global/js/locale';
import { forgotPasswordAdminAPI } from './../../API/Admin'
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Loader from "../../components/Loader/FullLoader";
import { setCookie, getCookie, eraseCookie } from './../../global/js/browserfunction'
class Forgot extends Component {

    constructor(props) {
        super(props);
        store.dispatch({ type: 'Header', header: true })
        this.state = {
            email: '',
            valid: false,
            show: false,
            password: '',
            varchar: local.VARCHAR,
            Int: local.INT,
            emailvalid: true,
            loading: false,
        }
        this.emailvalidator = this.emailvalidator.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Gotopage = this.Gotopage.bind(this);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        eraseCookie('wn_log');
        localStorage.removeItem("wn_log");
        window.scrollTo(0, 0)
        store.dispatch({ type: 'Header', header: true })
        // this.emailvalidator()
        //update the url and dispatch to url
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
    }
    /**
* @description The function executed for when email enter
*/
    handleChange = (e) => {
        if (e.target.id == 'email') {
            this.setState({ email: e.target.value }, function () {
                this.emailvalidator();
            });
        }

    };
    /**
* @description The function executed for when the email valid or not
*/
    emailvalidator() {
        if (emailregex.test(this.state.email)) {
            this.setState({ valid: true })
        } else {
            this.setState({ valid: false })
        }
    }
    /**
* @description The function executed for submit form
*/
    handleSubmit(e) {
        store.dispatch({ type: 'Header', header: true })
        var self = this;
        var email = self.state.email;
        e.preventDefault();
        let details = [
            "first_name",
        ];
        if (this.state.valid == true) {
            this.setState({ emailvalid: true });
            this.setState({ loading: true });
            //api for forgot password
            forgotPasswordAdminAPI(email, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.forgotPassword?.summary?.reason;
                    let status = res?.data?.forgotPassword?.summary?.status;
                    if (reason == "EMAIL_SEND" && status == "SUCCESS") {
                        let data = res?.data?.forgotPassword?.details;
                        //when login sucess email,name,accesstoken are stored in local storage
                        self.setState({ loading: false });
                        self.Gotopage('/login')
                        showNotification("Email Sent", "success");
                    } else {
                        self.setState({ loading: false });
                        showNotification(
                            "Invalid Email",
                            "danger",
                        );
                    }
                });

        } else {
            this.setState({ emailvalid: false });
        }
    }
    Gotopage(path) {
        this.props.history.push(path);
    }
    render() {
        return (
            < div className=' mt-5 Forgot' >
                <ReactNotification />
                <Loader loading={this.state.loading} />
                <div className='xl:w-1/3 w-2/3 m-auto bg-white shadow-loginshadow  mt-20'>
                    <h1 className='login-head xl:text-4xl text-2xl font-OpenSans font-normal text-bluecolor text-center py-20'>Recover Password</h1>
                    <form action="" className="mt-2 flex flex-col lg:w-2/3 w-8/12 m-auto" onSubmit={this.handleSubmit} autoComplete="off">
                        <div className={"flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded  " + (this.state.emailvalid == false ? '' : 'mb-6')}>
                            <div className="flex -mr-px justify-center w-15 p-4 pr-2 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white px-3 pl-0 border-0 rounded rounded-r-none text-2xl text-gray-600"
                                >
                                    <img src='/static/Icons/email_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type="text" id='email' autoComplete="off" maxLength={this.state.varchar['MD']}
                                className={"flex-shrink text-base input-box text-bluecolor  flex-grow flex-auto leading-normal w-px flex-1 h-10   px-3 self-center relative   text-sm outline-none border-b font-OpenSans " + (this.state.valid === true ? 'border-grey-light' : 'border-redcolor')}
                                placeholder="Enter Your Email " value={this.state.email} onChange={this.handleChange}
                            />
                        </div>
                        <span className={'font-OpenSans font-normal text-redcolor text-sm pl-16 ' + (this.state.emailvalid == false ? 'mb-6' : 'hidden')}>Invalid Email</span>
                        <button
                            href="/" type='submit'
                            className="bg-redcolor font-semibold py-4  text-center w-full text-white rounded leading-tight text-xl xl:text-lg font-OpenSans font-sans mt-4 mb-2 m-auto"
                        >
                            Request Reset Link
                        </button>
                        <div className='flex mt-6 m-auto mb-16'>
                            <span className='font-OpenSans text-base text-bluecolor font-normal'>Back to </span>
                            <Link to='/login' className='font-OpenSans text-base text-bluecolor font-semibold'>&nbsp; Sign In</Link>
                        </div>
                    </form>
                </div>
            </div >
        )
    }
};

export default Forgot;