import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import product from './../../data/Product.json'
import ProductFilter from './ProductFilter';
import Country from './../../data/Country.json'
import ProductList from './ProductList';
import store from './../../Store/Store'
import { getCookie } from './../../global/js/browserfunction'
import { listProductsAPI } from './../../API/Product'
import { eraseCookie } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterVal: '',
            update: {
                orderno: '',
                status: '',
                fromdate: '',
                todate: '',
                sync: '',
                name: '',
                email: '',
                sort: '',
                page: '',
                display_in_web: 'true',
                loading: false,
                Filterchange: false,
            },
            updateall: '',
            total: '',
            listproduct: [],
            newupdate: {}

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.Handlechange = this.Handlechange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleSubmit = this.HandleSubmit.bind(this);
        this.HandleFilter = this.HandleFilter.bind(this);
    }
    /**
* @description The function executed get the serach param and convert to object
*/
    async handleCheck() {
        window.scrollTo(0, 0)
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        }
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        this._isMounted && store.dispatch({ type: 'URL', url: urls })
        Update.subscribe(() => {
            this._isMounted && this.setState({ updateall: Update.getState().AllUpdates })
        })
    }
    /**
* @description The function executed for back to popup
*/
    HandleFilter(value) {
        this.setState({ Filterchange: value });
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed conver the updates and change the keys as the same of the api keyword
*/
    Handlechange(value) {
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        this.setState({ Status: value });
        // check thee filter are true
        if (value == true) {
            var update = {};
            console.log('second')
            update = store.getState().urlappend;
            console.log(update)
            // convert the value of the object in filter to compatible to api keywords
            if (update.name) {
                newupdate['KEYWORD'] = update.name.trim()
            }
            if (update.LWIN_18) {
                newupdate['LWIN_18'] = update.LWIN_18
            }
            if (update.SKU) {
                newupdate['SKU'] = update.SKU.trim()
            }
            if (update.dutypaid) {
                newupdate['PRODUCT_DUTY_PAID'] = update.dutypaid
            }
            if (update.display_in_web) {
                newupdate['SHOW_IN_WEBSITE'] = update.display_in_web
            }
            if (update.geograph) {
                newupdate['COUNTRY'] = update.geograph.trim()
            }
            if (update.sort) {
                newupdate['SORT'] = update.sort
            }
            if (update.contract) {
                newupdate['PRODUCT_CONTRACT_TYPE'] = update.contract
            }
            if (update.color) {
                newupdate['COLOR'] = update.color.trim()
            }
            if (update.vintage) {
                newupdate['VINTAGE'] = update.vintage.trim()
            }
            if (update.stock) {
                newupdate['STOCK'] = update.stock
            }
            if (update.supplier) {
                newupdate['SUPPLIER_NAME'] = update.supplier.trim()
            }
            if (update.page) {
                newupdate['limit'] = 20
            }
            if (update.page) {
                if (parseInt(update.page) == 1) {
                    newupdate['offset'] = '0'
                } else {
                    newupdate['offset'] = ((parseInt(update.page)) - 1) * 20
                }

            }

            if (update.sync) {
                newupdate['SYNC_WITH_NETSUITE'] = Boolean(update.sync);
            }
            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL",
                LWIN_18: "LWIN_18",
                SKU: "SKU",
                NAME: "NAME",
                ORDER_NO: "ORDER_NO",
                SORT: "SORT",
                COUNTRY: "COUNTRY",
                COLOR: "COLOR",
                STOCK: "STOCK",
                SUPPLIER_NAME: "SUPPLIER_NAME",
                KEYWORD: "KEYWORD"
            };
            let booleanFields = { limit: "limit", IS_INACTIVE: "IS_INACTIVE", offset: "offset", DISPLAY_IN_WEBSITE: "DISPLAY_IN_WEBSITE", VINTAGE: "VINTAGE", SHOW_IN_WEBSITE: "SHOW_IN_WEBSITE", PRODUCT_DUTY_PAID: "PRODUCT_DUTY_PAID" };

            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
                else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }

            console.log(graphQLParam);
            this.HandleSubmit(graphQLParam)
        }
    }
    /**
* @description The function executed pass the keyword to api and get the result and pass to components
*/
    HandleSubmit(query) {
        console.log(query)
        var self = this
        self.setState({ loading: true })
        var details = [
            "record_row_id",
            "date_modified",
            "date_created",
            "lwin_18",
            "sku",
            "wine_color",
            "wine_vintage",
            "wine_country",
            "wine_region",
            "wine_subregion",
            "quantity_available",
            "wine_bottlesize",
            "wine_packsize",
            "price_markup_percentage",
            "price_online",
            "name"
        ];
        //api for list orders
        listProductsAPI(query, details)
            .then((res) => res.json())
            .then(function (res) {
                let reason = res?.data?.listProducts?.summary?.reason;
                let status = res?.data?.listProducts?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listProducts.details.list;
                    let total = res.data.listProducts.details.totalCount;
                    console.log(data)
                    console.log(total)
                    self.setState({ loading: false })
                    self.setState({ listproduct: data });
                    self.setState({ total: total });

                } else {
                    self.setState({ loading: false })
                    let data = []
                    self.setState({ listproduct: data });
                    self.setState({ total: 0 });
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;
        var update = {}
        store.dispatch({ type: 'urlappend', Urlappend: update })
    }
    /**
        /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();


    }
    render() {
        return (
            < div className={'Order-main h-full  '} >
                {/* Breadthcrum of the Dashboard */}
                < Breadthcrumbs Breadthcrumb={'Product'} />
                <div className='order-content px-5 py-2'>
                    <ProductFilter Contract={product.Contract} Inactive={product.inactive} history={this.props.history} Stock={product.Stock} Supplier={product.Supplier} Countrylist={Country.CountryList} Update={this.state.update} passToParent={this.Handlechange} Filter={this.state.Filterchange} />
                    <ProductList ProductList={product.ProductList} SortByFilter={product.SortByFilter} Filterval={this.state.filterVal} Update={this.state.update} listproduct={this.state.listproduct} Total={this.state.total} history={this.props.history} passToParent={this.Handlechange} updateall={this.state.updateall} loading={this.state.loading} passToFilter={this.HandleFilter} />
                </div>
            </div >
        )
    }
};

export default Product;