import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import './ProductCategory.scss'
import Progress from '../../components/ProgressBar/Progress';
import Select from 'react-select'
import ImportProduct from '../../data/ImportProduct.json'
import { withRouter } from 'react-router-dom';
import ImportHeader from './ImportHeader';
import Multiselect from '../../components/MultiSelect/MultiSelect';
import Export from './Export';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import CSV from './../../Store/Csv'
import { capitalizeFirstLetter } from './../../global/js/browserfunction'
import { importProductsAPI } from './../../API/Cronjob'

class UploadCSV extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            varchar: local.VARCHAR,
            Int: local.INT,
            isLoading: false,
            Category: props.Category,
            Homepage: props.Homepage,
            Status: 'Home',
            level0: '',
            level1: '',
            level2: '',
            level_0: '',
            home_level_0: '',
            parent: 0,
            Keylist: [],
            delimiter: {
                "label": "Tab",
                "value": "Tab"
            },
            Countinue: ">> Continue",
            File_name: '',


        }

        this.handleStatus = this.handleStatus.bind(this);
        this.handleLevel0 = this.handleLevel0.bind(this);
        this.handleLevel1 = this.handleLevel1.bind(this);
        this.handleDelimiter = this.handleDelimiter.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.HanldeFile = this.HanldeFile.bind(this);
        this.csvJSON = this.csvJSON.bind(this);
        this.csvColumn = this.csvColumn.bind(this);
    }
    /**
 * @description The function executed for before the component call
 */
    componentDidMount() {
        window.scrollTo(0, 0)


    }
    /**
        * @description The function executed for change the status
    */
    handleStatus(id) {
        this.setState({ Status: id });
    }

    /**
        * @description The function executed for set the levels for dropdown
    */
    handleLevel0(id) {
        if (this.state.level0 == id) {
            this.setState({ level0: null })
        } else {
            this.setState({ level0: id })
        }
    }

    /**
        * @description The function executed for set the levels for dropdown
    */
    handleLevel1(id) {
        if (this.state.level1 == id) {
            this.setState({ level1: null })
        } else {
            this.setState({ level1: id })
        }
    }

    /**
        * @description The function executed for set the values of delimiter
        * @returns Selected Values
    */
    handleDelimiter(e) {
        this.setState({ delimiter: e })
    }

    /**
        * @description The function executed for navigating to next page
    */
    handleContinue() {
        var keys = this.state.Keylist
        console.log(keys.length)
        if (this.state.delimiter !== '' && this.state.File_name != '' & keys.length >= 15) {
            this.props.history.push('/importproducts/mapping')
        }
        else if (keys.length === 1) {
            showNotification("Delimiter should be tab while choosing CSV files", "warning")
        }
        else if (this.state.File_name === '') {
            showNotification("Please Choose CSV File", "warning")
        }
        else {
            showNotification("Please Select a Delimiter", "warning")
        }

    }
    /**
      * @description The function executed for upload file name
  */
    HanldeFile() {
        var fileInput = document.getElementById("file-upload")
        if (fileInput?.files[0] !== undefined) {
            var file = fileInput.files[0];
            CSV.dispatch({ type: 'File', File: file })
            var self = this;
            var Json;
            let input = document.getElementById('file-upload')?.files[0].name
            this.setState({ File_name: input })
            var reader = new FileReader();
            reader.onload = function () {
                Json = self.csvJSON(reader.result)
                self.csvColumn(Json)

            };
            reader.readAsText(fileInput.files[0]);
        }

        // start reading the file. When it is done, calls the onload event defined above.

    }
    csvJSON(csv) {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split("\t");

        for (var i = 1; i < lines.length; i++) {

            var obj = {};
            var currentline = lines[i].split("\t");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);

        }

        //return result; //JavaScript object
        return result //JSON
    }
    csvColumn(Json) {
        var Keylist = []
        this.setState({ Keylist: Keylist })
        var JSONfile = Json[0]
        var keys = Object.keys(JSONfile);
        keys.forEach(element => {
            Keylist.push({ value: element, label: capitalizeFirstLetter(element) })
        });
        this.setState({ Keylist: Keylist })
        CSV.dispatch({ type: 'CSV', Key: Keylist })
    }
    render() {
        return (
            <div className='Categorys-main py-1 mx-4'>
                <ReactNotification />
                {/* Name of the Page Header */}
                <ImportHeader />

                {/* Rest of the portion with step progress bar */}
                <div className='Categorys-details  py-5 px-6 bg-white shadow-shadowblack mt-8'>
                    <div className='xl:grid xl:grid-cols-2 xl:gap-2 w-3/4'>
                        <div className='categorys grid grid-cols-2 '>

                            {/* Import Header */}
                            {/* <div className={'category-list '}>
                                <div className={' px-2 border py-2 ' + (this.state.Status === 'Menu' ? 'bg-bluecolor' : ' ')}>
                                    <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Menu')}>
                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'Menu' ? 'text-white' : 'text-bluecolor')}  >IMPORT</h2>
                                    </div>
                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'Menu' ? 'block' : 'hidden')} />
                            </div> */}

                            {/* Export Header */}
                            <div className={'category-list'}>
                                <div className={' px-2 border py-2 px-2 ' + (this.state.Status === 'Home' ? 'bg-bluecolor' : ' ')}>
                                    <div className='cursor-pointer flex w-max m-auto' onClick={() => this.handleStatus('Home')}>
                                        <h2 className={' font-OpenSans text-base font-semibold my-auto text-center  ' + (this.state.Status === 'Home' ? 'text-white' : 'text-bluecolor')}>EXPORT</h2>
                                    </div>
                                </div>
                                <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'Home' ? 'block' : 'hidden')} />
                            </div>

                        </div>
                    </div>

                    {/* Import Section Contents */}
                    <div className={'category-down pt-6 ' + (this.state.Status === 'Menu' ? '' : 'hidden')}>
                        <div className='categorys-div '>

                            {/* Step Progress Indicator */}
                            <div className='level0  w-full'>
                                <Progress level1={"level1"} />
                            </div>

                            <div className='mt-12 mb-2'>
                                <span className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl mt-4'>Import Product from CSV File</span>
                            </div>

                            <div className='border-solid border-2 border-gray-200 my-auto border-b mt-2'>
                                <div className="mb-6 ml-4">
                                    <div className="w-full">

                                        <form>
                                            <div className='w-full xl:flex'>

                                                <div className="xl:w-1/4 w-3/5 my-auto ml-4 pt-6 pb-2">
                                                    <div className="xl:w-1/2 w-full mb-4 my-auto">
                                                        <span className='font-OpenSans font-semibold text-bluecolor xl:text-sm text-xl mb-8 mt-8 '>Choose CSV File</span>
                                                    </div>

                                                    {/* File Uploading section */}
                                                    <div className="w-full h-40 border-solid border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                                        <div className="absolute">
                                                            <div className="flex flex-col items-center ">
                                                                <img src='/static/Icons/file upload.svg' alt='' className=" w-1/4 my-auto" />
                                                                <span className="block text-gray-400 font-normal">Drop your files of</span>
                                                                <span className="block text-redcolor font-normal">Browse</span>
                                                                <input type="file" id="file-upload" name="myfile" accept=".csv" onChange={() => this.HanldeFile()} />
                                                                <p className='font-OpenSans font-semibold text-bluecolor text-sm '>{this.state.File_name}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="w-3/4 xl:ml-20 ml-4 pt-6 pb-2">
                                                    {/* Desktop View of Delimitter section*/}
                                                    <div className="xl:w-full xl:block hidden ">
                                                        <div className="xl:w-full xl:mb-4">
                                                            <span className='font-OpenSans font-semibold text-bluecolor xl:text-sm text-xl mb-8 mt-8 '>Delimiter</span>
                                                        </div>
                                                        <div className="xl:w-full w-4/5 my-auto bg-white border-bluecolor xl:justify-center justify-left xl:items-center items-left hover:cursor-pointer">
                                                            <div className="flex xl:w-1/4 w-2/5">
                                                                <span className='w-full mb-8 order-status status-select adduser'>
                                                                    <Select
                                                                        value={this.state.delimiter}
                                                                        onChange={this.handleDelimiter}
                                                                        options={ImportProduct.Delimiter} isSearchable={true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Tab View of Delimitter section*/}
                                                    <div className="w-full flex xl:hidden">
                                                        <div className="w-1/4 mt-0.5">
                                                            <span className='font-OpenSans font-semibold text-bluecolor text-xl mb-8 mt-8 '>Delimiter</span>
                                                        </div>
                                                        <div className="w-4/5 my-auto bg-white border-bluecolor justify-left items-left hover:cursor-pointer">
                                                            <div className="flex w-3/5">
                                                                <span className='w-full mb-8 order-status status-select adduser'>
                                                                    <Select
                                                                        value={this.state.delimiter}
                                                                        onChange={this.handleDelimiter}
                                                                        options={ImportProduct.Delimiter} isSearchable={true}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </form>

                                    </div>
                                    {/* Button Section */}
                                    <div className='w-full flex'>
                                        <div className=" my-auto">

                                        </div>
                                        <div className='w-max ml-auto mr-5'>
                                            <div className='flex items-end'>
                                                <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleContinue}>
                                                    <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Countinue} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Export Section Contents */}

                    {this.state.Status === 'Home' && <Export />}

                </div>

            </div >
        )
    }
};

export default withRouter(UploadCSV);