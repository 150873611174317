import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../../Store/Store'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import Loader from './../../../components/Loader/FullLoader'
import RefundDetails from './RefundDetails';
class RefundOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Status: 'AUTOMATIC',
            Countinue: "Continue >>",

        }
        this.handleStatus = this.handleStatus.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
    }
    /**
* @description The function executed for redirect the page
*/

    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleStatus(this.state.Status)
    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
  * @description The function executed for change the status
  */
    handleStatus(id) {
        this.setState({ Status: id });
        this.props.passToParent(id)
    }
    /**
 * @description The function change the progress level and change to next option
 */
    handleContinue() {
        var self = this
        let params = new URLSearchParams(document.location.search)
        if (!params.has("status")) {
            let status = params.get("status")
            if (this.state.Status === 'MANUAL') {
                self.props.history.push({
                    pathname: window.location.pathname,
                    search: "?status=Amount"
                })
            } else {
                self.props.history.push({
                    pathname: window.location.pathname,
                    search: "?status=Confirm"
                })
            }

            this.props.passToRefund()

        }
    }

    render() {
        return (
            <div>
                <div
                    className=" grid xl:grid-cols-2 md:grid-cols-1 "
                >
                    <h2 className='font-OpenSans font-semibold  text-redcolor xl:text-base text-base my-2 w-11/12 m-auto opacity-90'>Note that refund can only be processed once. </h2>
                </div>
                <div
                    className=" grid xl:grid-cols-2 md:grid-cols-1 "
                >
                    <div
                        className=" rounded overflow-hidden  my-2 refund-new md:mb-10 mb-7 xl:mb-15  w-11/12 xl:h-80 md:h-56 h-44 mx-auto"
                    >
                        <div className="addittion">
                            <div
                                className="text-left text-xl mb-2 font-semibold Address-text  refund-align xl:top-3/14 md:top-2/12 relative  px-7  "
                            >
                                <div className={'category-list    '}>
                                    <p className='font-OpenSans font-semibold  text-bluecolor xl:text-base lg:text-sm md:text-xs my-2'>If the items are Failed .This will be created as closed at Netsuite. </p>
                                    <div className={' px-2 border py-2 px-2 cursor-pointer  ' + (this.state.Status === 'AUTOMATIC' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('AUTOMATIC')}>
                                        <div className='cursor-pointer flex w-max m-auto'>

                                            <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'AUTOMATIC' ? 'text-white' : 'text-bluecolor')}>Automatic Refund</h2>
                                        </div>
                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 relative bottom-0.5 ml-auto ' + (this.state.Status === 'AUTOMATIC' ? 'block' : 'hidden')} />
                                </div>
                                <p className='font-OpenSans font-semibold  text-bluecolor xl:text-base lg:text-sm md:text-xs my-2'>Automatic refunds  are calculated by the System.  </p>
                            </div>
                        </div>
                    </div>
                    {/* <div
                        className=" rounded overflow-hidden  my-2 refund-new md:mb-10 mb-7 xl:mb-15 mx-auto w-11/12 xl:h-80 md:h-56 h-44"
                    >
                        <div className="addittion">
                            <div
                                className="text-left text-xl mb-2 font-semibold Address-text  refund-align xl:top-3/14 md:top-2/12 relative  px-7  "
                            >
                                <div className={'category-list '}>
                                    <p className='font-OpenSans font-semibold  text-bluecolor xl:text-base lg:text-sm md:text-xs my-2'>If the items are Failed .This will be created as closed at Netsuite. </p>
                                    <div className={' px-2 border py-2 cursor-pointer ' + (this.state.Status === 'MANUAL' ? 'bg-bluecolor' : ' ')} onClick={() => this.handleStatus('MANUAL')}>

                                        <div className='cursor-pointer flex w-max m-auto' >

                                            <h2 className={' font-OpenSans text-base font-semibold my-auto text-center ' + (this.state.Status === 'MANUAL' ? 'text-white' : 'text-bluecolor')}  >Manually Refund</h2>
                                        </div>

                                    </div>
                                    <img src='/static/Icons/triangle.svg' alt='' className={'w-4 mr-3 ml-auto relative bottom-0.5 ' + (this.state.Status === 'MANUAL' ? 'block' : 'hidden')} />
                                </div>
                                <p className='font-OpenSans font-semibold  text-bluecolor xl:text-base lg:text-sm md:text-xs my-2'>For manual refund, Please provide revised carttotal and shipping total amount.  Please note that the final amount will be added as a special discount in the order summary . </p>

                            </div>
                        </div>
                    </div> */}

                </div>
                <div>
                    <div className='w-full flex mb-10'>
                        <div className=" my-auto">

                        </div>
                        <div className='w-max ml-auto mr-5'>
                            <div className='flex items-end'>
                                <button className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleContinue}>
                                    <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Countinue} </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
};

export default RefundOption;