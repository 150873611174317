import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../../Store/Store'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import Loader from './../../../components/Loader/FullLoader'
import { listPaymentDetailsAPI } from './../../../API/Payment'
import { CurrencyFormat, capitalizeFirstLetter } from './../../../global/js/browserfunction'
import RefundProgress from './RefundProgress';
import RefundOption from './RefundOption'
import RefundAmount from './RefundAmount';
import RefundConfirm from './RefundConfirm';
import RefundStatus from './RefundStatus';
class RefundDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Option: '',
            level: 0,
            Id: props.Id,
            paymentdetails: '',
            Amounts: {},
            Refund_id: '',
            confirm: '',
        }

        this.HandleString = this.HandleString.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
        this.Handlecontinue = this.Handlecontinue.bind(this);
        this.handleListPayment = this.handleListPayment.bind(this);
        this.HandleAmount = this.HandleAmount.bind(this);
        this.HandleSucess = this.HandleSucess.bind(this);
    }

    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.Handlecontinue()
        this._isMounted && this.handleListPayment(this.state.Id)
        window.onpopstate = e => {

            this._isMounted && this.Handlecontinue()
        }
    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
  * @description The function executed get the orderdetails based on the orderid
      *@param id-order id
      */
    handleListPayment(id) {
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "RECORD_ROW_ID": id
            }
            query = this.HandleString(query)
            var details = [
                "refund_number",
                "order_header_id",
                "order_header_subtotal",
                "order_header_tax_total",
                "order_header_duty_total",
                "order_header_weight_total",
                "order_header_shipping_total",
                "order_header_transaction_total"

            ];
            console.log(query)
            // api for payment information
            self.setState({ loading: true })
            listPaymentDetailsAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchPaymentDetails?.summary?.reason;
                    let status = res?.data?.fetchPaymentDetails?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var paymentdetails = res.data.fetchPaymentDetails.details;
                        if (paymentdetails.refund_number != null) {
                            self.props.history.push("/payment/paymentdetails/" + this.state.Id);
                        }
                        self.setState({ paymentdetails: paymentdetails })
                        self.setState({ Refund_id: paymentdetails.order_header_id })
                        console.log(paymentdetails.order_header_id)
                        self.setState({ loading: false })
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/payment?page=1");
                    }
                });
        } else {
            self.props.history.push("/");
        }

    }
    /**
    * @description The function executed for cancel the edit
    */
    HandleString(newupdate) {
        var newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    /**
* @description The function executed change in home and menu bar category
*/
    Handlechange(value) {
        console.log(value)
        this.setState({ Option: value })
    }
    /**
* @description The function executed change progress level and refund page
*/
    Handlecontinue() {
        let params = new URLSearchParams(document.location.search)
        if (params.has("status")) {
            let status = params.get("status")

            if (status === 'Amount') {
                this.setState({ level: 1 })
            }
            else if (status === 'Confirm') {
                this.setState({ level: 2 })
            } else {
                this.setState({ level: 3 })
            }

        } else {
            this.setState({ level: 0 })
        }
    }
    /**
* @description The function executed status to sucess
*/
    HandleSucess(value) {
        this.setState({ confirm: value })
    }
    /**
* @description The function executed get the value of amounts from child
*/
    HandleAmount(value) {
        this.setState({ Amounts: value })
        console.log(value)
    }
    render() {
        return (
            < div className=' xl:h-full h-full  ' >
                < div className='CategorysInformation   bg-white shadow-shadowblack py-5 px-6' >
                    <div className='w-full flex'>
                        <div className='flex w-1/2'>
                            <img src='/static/Icons/payment.svg' alt='' className='xl:w-8 w-5' />
                            <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 my-auto'>Refund Information</h1>
                        </div>

                    </div>
                </div >
                <div className='Categorys-details  py-5 xl:px-6 px-3 bg-white shadow-shadowblack mt-8 flex'>
                    <div className='xl:w-3/12 w-4/12'>
                        <RefundProgress level={this.state.level} />
                    </div>
                    <div className='xl:w-9/12 w-8/12 mx-4 ' >
                        < div className='' >
                            <div className=' w-full'>
                                <h2 className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl mb-2 ' + (this.state.level === 0 ? '' : 'hidden')}>Choose any option for Refund</h2>
                            </div>
                            <div className='border border-1 w-full'>
                                {this.state.level === 0 &&
                                    <RefundOption passToParent={this.Handlechange} passToRefund={this.Handlecontinue} history={this.props.history} />
                                }
                                {this.state.level === 1 &&
                                    <RefundAmount passToRefund={this.Handlecontinue} history={this.props.history} passToAmount={this.HandleAmount} Id={this.state.Id} paymentdetails={this.state.paymentdetails} />
                                }
                                {this.state.level === 2 &&
                                    <RefundConfirm passToRefund={this.Handlecontinue} passToSucess={this.HandleSucess} history={this.props.history} Id={this.state.Id} Head_Id={this.state.Refund_id} Option={this.state.Option} Amount={this.state.Amounts} />
                                }
                                {this.state.level === 3 &&
                                    <RefundStatus passToRefund={this.Handlecontinue} history={this.props.history} Id={this.state.Id} confirm={this.state.confirm} />
                                }
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'order-summary mt-16 mb-10 ' + (this.state.level === 0 || this.state.level === 1 ? '' : 'hidden')}>
                    <div className='xl:w-2/7 w-3/7 px-5 order-sum ml-auto py-3'>
                        <h2 className='font-OpenSans font-semibold  text-orderred  text-sm text-xs pb-3'>Order Summary</h2>
                        <table className="w-full">
                            <tbody className=''>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm '>Sub Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm w-10'>{CurrencyFormat(this.state.paymentdetails.order_header_subtotal)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Tax Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_tax_total)}</td>
                                </tr>
                                <tr>
                                    <td className='py-1 font-OpenSans font-normal  text-orderred  text-sm'>Duty Paid</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_duty_total)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Weight Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.paymentdetails.order_header_weight_total == null ? 0 : (this.state.paymentdetails.order_header_weight_total / 50).toFixed(2)}Kg</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Shipping Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_shipping_total)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-bold pt-2 text-orderred  text-sm'>Transaction Total</td>
                                    <td className='text-left py-1 font-OpenSans  pt-2 font-bold  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_transaction_total)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        )
    }
};

export default RefundDetails;