import { createStore } from 'redux';

const initState = {
    AllUpdates: false
}

function reducer(state = initState, action) {
    if (action.type == 'UpdatesAll') {
        return {
            ...state,
            AllUpdates: action.UpdatesAll
        }
    }
}
const Update = createStore(reducer);
export default Update;


