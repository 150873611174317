import React, { Component } from 'react';
import './ProductDetail.scss'
import store from './../../Store/Store'
import local from '../../global/js/locale';
import { convertDate, convertDatetime } from './../../global/js/browserfunction'
import moment from 'moment';
class ProductInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: store.getState().edit,
            id: props.Id,
            Products: props.Products,
            currentProduct: '',
            varchar: local.VARCHAR,
            Int: local.INT,
            Float: local.FLOAT,
            product: props.Products,
            Display_web: '',
            inactive: '',
            Livex: false,
            FORMAT: local.locale.DATE.FORMAT,

        }
        this.handleToggle = this.handleToggle.bind(this);
    }
    /**
* @description The function executed for get the value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }
    /**
* @description The function executed cancel the edit when exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();

    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Products !== this.state.Products) {
            this.setState({ Products: nextProps.Products })
            if (nextProps.Products.display_in_website === null) {
                this.setState({ Display_web: false })
            } else {
                this.setState({ Display_web: nextProps.Products.display_in_website })
            }
            if (nextProps.Products.is_inactive === null) {
                this.setState({ inactive: false })
            } else {
                this.setState({ inactive: nextProps.Products.is_inactive })
            }
            if (nextProps.Products.supplier_name === "Liv-ex" || nextProps.Products.supplier_name === 'livex' || nextProps.Products.supplier_name === 'LIVEX') {
                this.setState({ Livex: true })
            } else {
                this.setState({ Livex: false })
            }
        }


    }
    /**
* @description The function executed for change the state of active and inactive
*/
    handleToggle(e) {
        var self = this;
        var update = store.getState().update
        if (e.target.children.inactive != undefined) {
            var inactive = !e.target.children.inactive.checked
            self.setState({ inactive: inactive })
            update['IS_INACTIVE'] = !e.target.children.inactive.checked;
            store.dispatch({ type: 'Update', Update: update })
        }
        else if (e.target.children.display_web != undefined) {
            self.setState({ Display_web: !e.target.children.display_web.checked })
            update['DISPLAY_IN_WEBSITE'] = !e.target.children.display_web.checked;
            store.dispatch({ type: 'Update', Update: update })
        }

    }
    /**
* @description The function executed for cancel the edit
*/
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
        var edit = !this.state.Edit;
        if (edit === false) {
            var update = {}
            store.dispatch({ type: 'Update', Update: update })
            this.props.passToParent(false)
        }

    }
    /**
* @description The function executed updated value are stored as object the id as key
*/
    handleUpdate(e) {
        var key = e.target.id;
        var value = e.target.value;
        var update = store.getState().update
        update[key] = value;
        store.dispatch({ type: 'Update', Update: update })

    }
    render() {
        return (
            <div className='ProductInfo h-full py-5 px-5 bg-white shadow-shadowblack'>
                <div className='flex w-full pr-5'>
                    <div className='w-10/12 Product-no flex xl:px-5 px-3'>
                        <img src='/static/Icons/Products.svg' alt='' className='xl:w-8 w-8' />
                        <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-lg my-auto ml-3 mt-2 my-auto ' + (this.state.Edit === true ? 'block' : 'hidden')}>Edit a Product -</span>
                        <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-lg my-auto ml-3 mt-2 my-auto '>SKU {this.state.Products.sku}</h1>
                    </div>
                    <div className={'w-2/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                        <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                    </div>
                    <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                        <div className='buttons w-max flex ml-auto h-8 '>
                            <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                    <div className='flex'>
                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                        <span className='ml-2 mx-auto'>Save</span>
                                    </div>
                                </button>
                            </div>

                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => this.HandleEdit()}>Cancel</span>
                        </div>
                    </div>
                </div>
                <hr className='sortfilterborder my-4' />
                <div className='flex px-4'>
                    <div className='xl:w-5/12 w-1/2'>
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className='w-16 align-top'><span className='font-OpenSans font-semibold text-sm text-bluecolor'>Name *&nbsp;&nbsp; </span></td>
                                    <td className='text-left'> <span className={'font-normal ont-OpenSans d text-sm text-bluecolor  ' + (this.state.Edit === true && this.state.Livex === false ? 'hidden' : '')}>{this.state.Products.name}</span><span className={this.state.Edit === true && this.state.Livex === false ? 'xl:w-9/12 w-full ' : 'hidden'}>
                                        <textarea id='NAME' className='resize-none font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor  ml-2 xl:w-9/12 w-full ' onChange={(e) => this.handleUpdate(e)} defaultValue={this.state.Products.name} maxLength={this.state.varchar['XL']} required pattern="\s*\S+.*" title="space only is not allowed" /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-16 font-OpenSans font-semibold text-sm text-bluecolor py-2'>Inactive</td>
                                    <td className={' font-OpenSans font-normal text-sm text-bluecolor py-2 '}> <span onClick={(e) => this.handleToggle(e)} className='cursor-pointer '><input type="checkbox" id={(this.state.Edit === true ? 'inactive' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white ml-2 " + (this.state.Edit === true ? 'cursor-pointer' : '')} defaultValue={this.state.inactive} checked={this.state.inactive} readOnly={!this.state.Edit} /></span></td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div className='xl:flex xl:w-7/12 w-max m-auto mt-0'>
                        <div className='xl:w-1/2 xl:my-auto mb-4'>
                            <div className={'mb-2 ' + (this.state.Edit === true && this.state.Livex === false ? 'h-xl' : '')}>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max xl:mr-auto '>Date Created &nbsp;&nbsp; <span className='font-normal ml-2 '>{this.state.Products.date_created !== '' && this.state.Products.date_created !== null ? convertDatetime(this.state.Products.date_created) : ''}</span></span>
                            </div>
                            <div className=''>
                                <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max xl:mr-auto'>Display in Web &nbsp;&nbsp; <span className='font-normal'><span onClick={(e) => this.handleToggle(e)} className='cursor-pointer'><input type="checkbox" id={(this.state.Edit === true ? 'display_web' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white " + (this.state.Edit === true ? 'cursor-pointer' : '')} defaultValue={this.state.Display_web} checked={this.state.Display_web} readOnly={!this.state.Edit} /></span></span></span>
                            </div>
                        </div>
                        <div className='xl:w-1/2 xl:my-auto xl:mt-0 mb-4'>
                            <span className='font-OpenSans font-semibold text-sm text-bluecolor xl:w-max m-auto block '>Date Modified &nbsp;&nbsp;   <span className='font-normal'>{this.state.Products.date_modified !== null && this.state.Products.date_modified !== '' ? convertDatetime(this.state.Products.date_modified) : ''}</span></span>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default ProductInfo;