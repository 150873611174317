import React, { Component } from 'react';
import local from '../../../global/js/locale';
import Breadthcrumbs from '../../../components/Breadthcrumbs/Breadcrumbs'
import store from './../../../Store/Store'
import { getCookie } from './../../../global/js/browserfunction'
import { Link } from 'react-router-dom';
import '../PaymentDetail.scss'
import Loader from './../../../components/Loader/FullLoader'
import locale from '../../../global/js/locale';
import { CurrencyFormat, capitalizeFirstLetter } from './../../../global/js/browserfunction'
class RefundStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            Status: 'Manually',
            Countinue: ">> Continue",
            Back: "<< Back To Payment Details",
            Int: locale.INT,
            Id: props.Id,
            confirm: props.confirm,
            Refund: ''

        }


    }


    /**
* @description The function executed for before the component call
*/
    componentDidMount() {

        if (this.state.confirm === 'STRIPE_REFUND_SUCCEEDED') {
            this.setState({ Refund: 'Success' })
        } else if (this.state.confirm === 'STRIPE_REFUND_NOT_SUCCEEDED') {
            this.setState({ Refund: 'Failed' })
        } else {
            this.props.history.push({
                pathname: '/payment/paymentdetails/' + this.state.Id
            })
        }


    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render


    }
    render() {
        return (
            <div>
                <div
                    className=" grid xl:grid-cols-1 md:grid-cols-1 "
                >
                    <h2 className='font-OpenSans font-semibold  text-bluecolor xl:text-base text-base my-2  m-auto mx-5'>Note that refund can only be processed once. </h2>

                </div>
                <div className=" w-full success">
                    <div className={'w-max m-auto ' + (this.state.Refund === 'Success' ? '' : 'hidden')}>
                        <img src="/static/Icons/tick123.svg" alt="Sucees-refund" className="xl:w-20 w-16 xl:mt-0 mt-5  m-auto mb-5" />
                        <h2 className='font-OpenSans font-semibold  text-bluecolor xl:text-base text-base   m-auto '>Refund request submitted successfully.</h2>
                    </div>

                </div>
                <div className={" w-full Failed " + (this.state.Refund === 'Failed' ? '' : 'hidden')}>
                    <div className='w-max m-auto '>
                        <img src="/static/Icons/error.svg" alt="failed-refund" className="xl:w-20 w-16 xl:mt-0 mt-5 m-auto mb-5" />
                        <h2 className='font-OpenSans font-semibold  text-bluecolor xl:text-base text-base   m-auto '>Refund request Failed.</h2>
                    </div>

                </div>
                <div className='w-full flex my-10'>
                    <div className=" my-auto">
                        <div className='w-max  ml-5'>
                            <div className='flex items-end' >
                                <Link to={'/payment/paymentdetails/' + this.state.Id} className='bg-redcolor text-white font-OpenSans font-semibold text-base rounded-lg xl:px-1 my-auto py-1 cursor-pointer W-1/6 flex items-end' onClick={this.handleBack}>
                                    <span className="xl:w-full w-full text-white text-md mx-4 whitespace-nowrap"> {this.state.Back} </span>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
};

export default RefundStatus;