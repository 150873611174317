
import { BASEURL } from './Baseurl'
/**
     * @description  The function for list the all payment methods
     * @returns all the products
 */
function listAllPaymentMethodAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/paymentmethod', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                    query{
                        listAllPaymentMethods(${query})
                            {
                                summary{
                                    status
                                    reason
                                }
                                details
                                {
                                    list{
                                        ${detail}
                            
                                    }
                                    totalCount
                                }
                            }
                        }`,
            }
        ),
    })
}
function listSinglePaymentDetailsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/paymentmethod', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
                    query{
                        fetchPaymentmethodInformation(${query})
                        {
                    summary{
                        status
                        reason
                        }
                        details
                        {
                        
                            ${detail}
                        
                        }
                            
                    
                    }
                    }`,
            }
        ),
    })
}

/**
 * @description  The function for API for updating single user details
 * @param {query, details}, payload and details to be displayed
 * @returns updated details of an User
*/
function updatePaymentDetailsAPI(query, details) {
    //The details are convert to string
    let detail = details.toString();
    return fetch(BASEURL + 'graphql/paymentmethod', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                query: `
            mutation{
                updatePaymentMethod(${query})
                {
                    summary{
                        status
                        reason
                    }
                    details
                    {
                      
                            ${detail}
                        
                      
                    }
                }
            }`,
            }
        ),
    })
}

export {
    listAllPaymentMethodAPI,
    listSinglePaymentDetailsAPI,
    updatePaymentDetailsAPI
}