import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './HomePageCustomization.scss'
import HomePage from './HomePage';
import HomePageCustom from './../../data/HomePage.json'
import store from './../../Store/Store'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { listHomePageCategoryAPI } from './../../API/Category'

class HomePageCustomization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            category: []

        }

        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.Handlechange = this.Handlechange.bind(this);
        this.Handleconvert = this.Handleconvert.bind(this);

    }
    /**
* @description The function executed change list homepage category
*/
    Handlechange(value) {
        console.log(value)
        let token;
        let email;
        var newupdate = {};
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
        } else {
            this.GoToPage("/");
        }
        var query = this.Handleconvert(newupdate)
        console.log(this.Handleconvert(newupdate))
        var self = this;
        self.setState({ loading: true })
        var details = [
            "record_id",
            "image",
            "title",
            "category",
        ];
        var bannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
        ];
        var bottombannerdetails = [
            "record_id",
            "image",
            "title",
            "date",
            "description",
            "category",
        ];
        //api for list orders
        listHomePageCategoryAPI(query, details, bannerdetails, bottombannerdetails)
            .then((res) => res.json())
            .then(function (res) {
                console.log(res.data)
                let reason = res?.data?.listHomePageCategory?.summary?.reason;
                let status = res?.data?.listHomePageCategory?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.listHomePageCategory.details;
                    self.setState({ loading: false })
                    self.setState({ category: data });

                } else {
                    eraseCookie('wn_log');
                    localStorage.removeItem("wn_log");
                    self.props.history.push("/");
                }
            });

    }
    /**
    * @description The function executed for conavert the object to string
    */
    Handleconvert(newupdate) {
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN"
        };
        let booleanFields = { MENU_BAR: "MENU_BAR", HOME_PAGE: "HOME_PAGE" };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam

    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
    }


    render() {
        return (
            <div className='HomePageCustomization-main  h-screen xl:h-full'>
                < Breadthcrumbs Breadthcrumb={'Home Page Customization'} />

                <div className={'HomePageCustomization-main-content '}>
                    <HomePage Customization={HomePageCustom} passToParent={this.Handlechange} Category={this.state.category} loading={this.state.loading} />
                </div>
            </div>
        )
    }
};

export default HomePageCustomization;