import React, { Component } from 'react';
import local from '../../global/js/locale';
import Select from 'react-select'
import { CgSearch } from 'react-icons/cg';
import './Customer.scss'
import store from './../../Store/Store'
import moment from 'moment';
class CustomerFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Record: '',
            orderdata: props.CustomerData,
            Status: props.Status,
            SyncNetsuite: props.Sync,
            States: 0,
            applayfilter: [],
            OrderNo: '',
            Fromdate: '',
            Todate: '',
            Name: '',
            Email: '',
            filter: false,
            selectedOption: { value: "", label: 'Any', id: 1 },
            Have_access: { value: "", label: 'Any', id: 1 },
            varchar: local.VARCHAR,
            Int: local.INT,
            update: store.getState().urlappend,
            Filtervalue: props.Filter,
            FORMAT: local.locale.DATE.FORMAT,
            fromopacity: '',
            toopactity: '',
            currenDate: new Date().toJSON().slice(0, 10)
        }
        this.handleForm = this.handleForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDate = this.handleDate.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.HandleLoad = this.HandleLoad.bind(this);
    }
    /**
* @description The function executed the component exists
*/
    componentWillUnmount() {
        this._isMounted = false;

    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.Filter !== this.state.Filtervalue) {
            console.log('filter', nextProps.Filter)
            if (nextProps.Filter == true) {
                this._isMounted && this.handleCheck();
            }
        }

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleLoad();

    }
    HandleLoad() {
        var update = this.state.update;
        let params = new URLSearchParams(document.location.search)
        //The search param not empty convert the serach parammeter to object
        if (window.location.search != null && window.location.search != '') {
            var search = window.location.search.substring(1);
            update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this.setState({ update: update });
            console.log(update.name)
            console.log(update)
            if (update.inactive != '' && update.inactive != null) {
                var obj = this.state.Status.find(item => item.value === update.inactive)
                this.setState({ selectedOption: obj });
            }
            if (update.access != '' && update.access != null) {
                var obj = this.state.Status.find(item => item.value === update.access)
                this.setState({ Have_access: obj });
            }
            if (update.fromdate != '' && update.fromdate != null) {
                let target = document.getElementsByName("fromdate")[0];
                let targets = document.getElementsByName("fromdate")[1];
                this.setState({ fromactive: 'active' });
                this.setState({ fromopacity: 'active' });
                target.setAttribute(
                    "data-date",
                    moment(update.fromdate, "YYYY-MM-DD")
                        .format(target.getAttribute("data-date-format"))
                );
                targets.setAttribute(
                    "data-date",
                    moment(update.fromdate, "YYYY-MM-DD")
                        .format(targets.getAttribute("data-date-format"))
                );
            }
            if (update.todate != '' && update.todate != null) {
                let target = document.getElementsByName("todate")[0];
                let targets = document.getElementsByName("todate")[1];
                this.setState({ toactive: 'active' });
                this.setState({ toopactity: 'active' });
                let enddate = moment(update.todate, "YYYY-MM-DD").add(-1, 'days').format('YYYY-MM-DD');
                target.setAttribute(
                    "data-date",
                    moment(enddate, "YYYY-MM-DD")
                        .format(target.getAttribute("data-date-format"))
                );
                targets.setAttribute(
                    "data-date",
                    moment(enddate, "YYYY-MM-DD")
                        .format(targets.getAttribute("data-date-format"))
                );

            }
        }
    }
    /**
* @description The function executed for set the the updated value of the filters
*/
    async handleCheck() {
        setTimeout(
            function () {
                document.getElementById("fromdate").classList.add("active");

                document.getElementById("todate").classList.add("active");
            }
                .bind(this),
            1000
        );

        // store.dispatch({ type: 'urlappend', Urlappend: this.state.update })
        store.subscribe(() => {
            this._isMounted && this.setState({ update: store.getState().urlappend })
            console.log(store.getState().urlappend.access)
            if (store.getState().urlappend) {
                //the sync with netsute is not empty set the select box value
                if (store.getState().urlappend.access == '' || store.getState().urlappend.access == undefined) {
                    var Have_access = { value: "", label: 'Any', id: 1 }
                    this._isMounted && this.setState({ Have_access: Have_access });
                }
            }
            // set the value of the status from the store
            if (store.getState().urlappend) {
                if (store.getState().urlappend.inactive == '' || store.getState().urlappend.inactive == undefined) {
                    var selectedOption = { value: "", label: 'Any', id: 1 }
                    this._isMounted && this.setState({ selectedOption: selectedOption });
                }
            }
        })


    }
    /**
   * @description The function executed for when change in status 
   */
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        var update = store.getState().urlappend;
        update['inactive'] = selectedOption.value
        this.setState({ update: update });
        store.dispatch({ type: 'urlappend', Urlappend: update })
    };
    handleAccess = Have_access => {
        this.setState({ Have_access });
        console.log(`Option selected:`, Have_access);
        var update = store.getState().urlappend;
        update['access'] = Have_access.value
        this.setState({ update: update });
        store.dispatch({ type: 'urlappend', Urlappend: update })
    };
    /**
     * @description The function executed for when the form value has been changes .
     * @param {object} e-event of the inputbox
     */
    handleForm(e) {
        var update = store.getState().urlappend;
        var key = e.target.id;
        var value = e.target.value;
        update[key] = value
        this.setState({ update: update });
        store.dispatch({ type: 'urlappend', Urlappend: update })
        console.log(update)
    }
    /**
  * @description The function executed for submit the form
  */
    handleSubmit() {
        var update = store.getState().urlappend;
        update['page'] = 1;
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/customer',
            search: "?" + str.toString()
        })
        this.props.passToParent(true)
        setTimeout(
            function () {
                this.props.passToParent(false)
            }
                .bind(this),
            3000
        );

    }
    /**
  * @description The function executed for if any date picker changes its value
  */
    handleDate(e, index) {
        var update = this.state.update
        var key = e.target.id;
        var startdate = e.target.parentNode.parentNode.parentElement.querySelector(
            "#fromdate"
        ).value;
        var enddate = e.target.parentNode.parentNode.parentElement.querySelector(
            "#todate"
        ).value;
        let target = document.getElementsByName(key)[0];
        let targets = document.getElementsByName(key)[1];
        target.setAttribute(
            "data-date",
            moment(e.target.value, "YYYY-MM-DD")
                .format(target.getAttribute("data-date-format"))
        )
        targets.setAttribute(
            "data-date",
            moment(e.target.value, "YYYY-MM-DD")
                .format(targets.getAttribute("data-date-format"))
        )
        if (index === 'from') {
            document.getElementById("fromdate").classList.remove("opacity-60");
            document.getElementById("fromdate").classList.add("active");
        }
        else if (index === 'to') {
            document.getElementById("todate").classList.remove("opacity-60");
            document.getElementById("todate").classList.add("active");
        }
        if (enddate != '') {
            if (startdate > enddate) {
                this.setState({ Fromdate: enddate });
            }
        }
        if (startdate != null && startdate != '') {
            update['fromdate'] = startdate
            store.dispatch({ type: 'urlappend', Urlappend: update })
        }
        if (enddate != null && enddate != '') {
            enddate = moment(enddate, "YYYY-MM-DD").add(1, 'days').format('YYYY-MM-DD');
            update['todate'] = enddate
            store.dispatch({ type: 'urlappend', Urlappend: update })
        }
        this.setState({ update: update });
    }
    /**
  * @description The function executed for When the filter applies
  */
    handleFilter() {
        this.setState({ filter: !this.state.filter });
    }
    render() {
        return (
            <div className='filter-order'>
                <button className='filter-btn bg-white   py-1 w-32 px-4  shadow-shadowblack  flex' onClick={() => this.handleFilter()}>
                    <span className='font-OpenSans font-semibold text-left text-base text-bluecolor '>Filters</span>
                    <img src='/static/Icons/filter-icon.svg' alt='' className={'my-auto ml-auto transform  ' + (this.state.filter === true ? '' : ' rotate-180')} />
                </button>
                <img src='/static/Icons/filter.svg' alt='' className={'' + (this.state.filter === true ? 'block' : 'hidden')} />
                <div className={'bg-white bottom-3 relative shadow-shadowblack filter-main ' + (this.state.filter === true ? 'block' : 'hidden')}>
                    <div className='xl:px-10 px-5 py-7'>
                        <div className='flex '>
                            <div className='xl:w-2/12 w-4/12'>
                                <div className='xl:w-9/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Customer Name</span>
                                    <div className=' w-full'>
                                        <input type='text' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold " id='name' placeholder="Name" onChange={this.handleForm} defaultValue={this.state.update.name} maxLength={this.state.varchar["MD"]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=' xl:w-2/12 w-1/4 '>
                                <div className='xl:w-9/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Inactive</span>
                                    <div className=' w-full status-select'>
                                        <Select className="status-select"
                                            value={this.state.selectedOption}
                                            onChange={this.handleChange}
                                            options={this.state.Status} isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className=' xl:w-2/12 w-1/4 '>
                                <div className='xl:w-9/12 w-10/12'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Have Access</span>
                                    <div className=' w-full status-select'>
                                        <Select className="status-select"
                                            value={this.state.Have_access}
                                            onChange={this.handleAccess}
                                            options={this.state.Status} isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='xl:w-6/12 hidden xl:block xl:px-20 xl:pl-10'>
                                <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Date</span>
                                <div className='  flex '>
                                    <div className='flex pt-3  w-1/2 '>
                                        <span className='font-OpenSans font-semibold text-sm text-bluecolor mt-auto opacity-60'>FROM </span>
                                        <input type="date" id="fromdate" name="fromdate" className={' xl:ml-6 ml-3 uppercase text-bluecolor font-OpenSans mt-auto font-semibold   border-b border-bluecolor w-max active ' + (this.state.fromopacity === 'active' ? '' : ' opacity-60 ')} data-date="MM/DD/YYYY" data-date-format={this.state.FORMAT} defaultValue={this.state.update.fromdate} onChange={(e) => this.handleDate(e, 'from')} max={this.state.currenDate} />
                                    </div>
                                    <div className='flex pt-3 w-1/2'>
                                        <span className='font-OpenSans font-semibold text-sm text-bluecolor mt-auto opacity-60 ml-3 '>TO </span>
                                        <input type="date" id="todate" name='todate' className={' xl:ml-6 ml-3 uppercase text-bluecolor font-OpenSans mt-auto font-semibold  border-b border-bluecolor w-max active ' + (this.state.toopactity === ' active ' ? '' : ' opacity-60 ')} data-date="MM/DD/YYYY" data-date-format={this.state.FORMAT} defaultValue={this.state.update.todate} onChange={(e) => this.handleDate(e, 'to')} max={this.state.currenDate} />
                                    </div>
                                </div>
                            </div>
                            {/* The mobile view of the customer email */}
                            <div className='w-5/12 xl:hidden block'>
                                <div className='w-11/12 mr-auto'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Customer Email</span>
                                    <div className=' w-full'>
                                        <input type='email' id='email' name='email' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm    font-semibold" pattern="/\S+@\S+\.\S+/"
                                            placeholder="Email" defaultValue={this.state.update.email} onChange={this.handleForm} maxLength={this.state.varchar["MD"]}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='w-full py-7 xl:flex'>
                            <div className='xl:w-7/12 w-full flex'>
                                {/* The desktop view of the customer email */}
                                <div className='w-1/2 xl:block hidden'>
                                    <div className='w-11/12 mr-auto'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Customer Email</span>
                                        <div className=' w-full'>
                                            <input type='email' id='email' name='email' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm    font-semibold" pattern="/\S+@\S+\.\S+/"
                                                placeholder="Email" defaultValue={this.state.update.email} onChange={this.handleForm} maxLength={this.state.varchar["MD"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='w-3/5 xl:hidden block xl:px-20 xl:pl-10'>
                                    <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Date</span>
                                    <div className='  flex '>
                                        <div className='flex pt-3  w-1/2 '>
                                            <span className='font-OpenSans font-semibold text-sm text-bluecolor mt-auto opacity-60'>FROM </span>
                                            <input type="date" id="fromdate" name="fromdate" className={' xl:ml-6 ml-3 text-sm uppercase text-bluecolor font-OpenSans mt-auto font-semibold   border-b border-bluecolor w-max active ' + (this.state.fromopacity === 'active' ? '' : ' opacity-60 ')} data-date="MM/DD/YYYY" data-date-format={this.state.FORMAT} defaultValue={this.state.update.fromdate} onChange={(e) => this.handleDate(e, 'from')} max={this.state.currenDate} />
                                        </div>
                                        <div className='flex pt-3 w-1/2'>
                                            <span className='font-OpenSans font-semibold text-sm text-bluecolor mt-auto opacity-60 ml-3 '>TO </span>
                                            <input type="date" id="todate" name="todate" className={' xl:ml-6 ml-3 uppercase text-bluecolor font-OpenSans mt-auto font-semibold   border-b border-bluecolor w-max text-sm active ' + (this.state.fromopacity === 'active' ? '' : ' opacity-60 ')} data-date="MM/DD/YYYY" data-date-format={this.state.FORMAT} defaultValue={this.state.update.todate} onChange={(e) => this.handleDate(e, 'to')} max={this.state.currenDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className='xl:w-1/2 w-4/12 ml-auto'>
                                    <div className='w-11/12 mr-auto'>
                                        <span className='order-name-text font-OpenSans font-semibold text-sm text-redcolor'>Phone number</span>
                                        <div className=' w-full'>
                                            <input type='text' id='phone' className="w-full border-b border-bluecolor py-2 pr-3 text-bluecolor font-OpenSans text-sm       font-semibold"
                                                placeholder="Phone number" defaultValue={this.state.update.phone} onChange={this.handleForm} minLength="8" maxLength="15"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className=' xl:w-5/12 mt-5 w-full '>
                                <div className='ml-auto xl:ml-auto my-auto w-max'>
                                    <button type='submit' className='text-white bg-redcolor flex mx-auto px-6 py-1 rounded-lg '><span className='text-base font-OpenSans font-normal' onClick={() => this.handleSubmit()}>Apply Filter</span><span className='text-white my-auto ml-3'><CgSearch /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
};

export default CustomerFilter;