import React, { Component } from 'react'
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { browserHistory } from 'history'
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import store from './../../Store/Store'
import './Header.scss'
import { getCookie, eraseCookie } from './../../global/js/browserfunction'
import { adminLoginValidateAPI } from './../../API/Admin'
import Update from './../../Store/Update'
class Header extends Component {

    constructor(props) {
        super(props);
        store.dispatch({ type: 'ADD_TODO', todo: false })
        // store.dispatch({ type: 'URL', url: window.location.pathname })
        this.handleClick = this.handleClick.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleUrl = this.handleUrl.bind(this);
        this.dropdown = this.dropdown.bind(this);
        this.state = {
            Navlist: props.Navlist,
            menuid: store.getState().todos,
            iconmap: props.Iconmap,
            tag: '',
            categorymenuid: [],
            urlchange: store.getState().urlchange,
            locationurl: '',
            dropstate: false,
            popstate: false,
            User: ''
        }

        this.handleDropdown = this.handleDropdown.bind(this);
        this.hideOnClickOutside = this.hideOnClickOutside.bind(this);
        this.HandleLogout = this.HandleLogout.bind(this);
        this.Gotopage = this.Gotopage.bind(this);
        this.Loginvalidate = this.Loginvalidate.bind(this);


    }
    /**
   * @description The function executes before component call
   */
    componentDidMount() {
        console.log(this.props.history)
        store.subscribe(() => {
            this.setState({ menuid: store.getState().todos })
            this.setState({ urlchange: store.getState().urlchange })
            if (getCookie("wn_log")) {
                if (localStorage.getItem("wn_log") !== null) {
                    let login = JSON.parse(localStorage.getItem("wn_log"));
                    var user_name = login.first_name + ' ' + login.last_name;
                    this.setState({ User: user_name })
                }

            }
        })
        //  To execute the function each 1 hr for validate the admin 
        this.interval = setInterval(() => this.Loginvalidate(), 1000 * 60 * 60);
        //  To execute the function when resize the page the sidebar removed
        window.addEventListener("resize", this.handleResize);
        window.addEventListener('click', this.hideOnClickOutside)

    }
    /**
  * @description The function executed for validate the admin user
  */
    Loginvalidate() {
        if (localStorage.getItem("wn_log") !== null && getCookie("wn_log")) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            let token = login.access_token;
            let email = login.email;
            let details = ["first_name"];
            let self = this
            //pass the email and token from local storage
            adminLoginValidateAPI(email, token, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.adminLoginValidate?.summary?.reason;
                    let status = res?.data?.adminLoginValidate?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == 'SUCCESS') {
                        // self.props.history.push("/");
                    } else {
                        self.props.history.push("/");
                        showNotification(
                            "Session expired",
                            "danger",
                        );
                        eraseCookie('wn_log');
                        localStorage.removeItem("wn_log");

                    }
                });
        }
    }
    /**
  * @description The function executes for the sidebar open and close
  */
    handleClick() {
        this.setState({ menuid: !this.state.menuid })
        //dispatch the value to store can access any components
        store.dispatch({ type: 'ADD_TODO', todo: !this.state.menuid })
        var tag = document.querySelector("html");
        if (this.state.menuid == false) {
            tag.classList.add("nav-navbar-open");
        }
        else {
            tag.classList.remove("nav-navbar-open");
        }

    }
    /**
    * @description The function executed for when resize the page close the sidebar
   */
    handleResize() {
        this.setState({ menuid: false })
        store.dispatch({ type: 'ADD_TODO', todo: this.state.menuid })
        var tag = document.querySelector("html");
        tag.classList.remove("nav-navbar-open");
    }
    /**
  * @description The function executes when the click of the pages dispatch the url to store
  * @param {object} url- The object link of the pages
  */
    handleUrl(url) {
        var update = store.getState().urlappend;
        store.dispatch({ type: 'URL', url: url })
        this.setState({ menuid: false })
        store.dispatch({ type: 'ADD_TODO', todo: this.state.menuid })
        store.dispatch({ type: 'urlappend', Urlappend: update })
        Update.dispatch({ type: 'UpdatesAll', UpdatesAll: true })
        var tag = document.querySelector("html");
        tag.classList.remove("nav-navbar-open");
    }
    /**
    * @description The function executes logout dropdown of the url
    * @param {object} id- the id of the page
    */
    dropdown(id) {
        var array = [...this.state.categorymenuid];
        var index = array.indexOf(id)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ categorymenuid: array });
        } else {
            this.setState({
                categorymenuid: [...this.state.categorymenuid, id]
            })
        }
    }
    /**
   * @description The function executes when click popup the dropdown
   */
    handleDropdown() {
        if (this.state.popstate == false) {
            this.setState({ dropstate: !this.state.dropstate });

        }
    }
    /**
 * @description The function executes when popup of the logout close when outsideclick
 */
    hideOnClickOutside() {
        if (this.state.dropstate == true) {
            this.setState({ popstate: !this.state.popstate });
            this.handleDropdown();
        }
    }
    /**
     * @description The function executed for logout the page 
     * @description allso remove the cookie and local storage values of the user
     */
    HandleLogout() {
        eraseCookie('wn_log');
        localStorage.removeItem("wn_log");
        localStorage.removeItem("dt_log");
        this.Gotopage('/')
    }
    /**
    * @description The function executed for redirect the page to another
    * @param {object} path-The path of the route to redirect
    */
    Gotopage(path) {
        this.props.history.push(path)
    }
    /**
    * @description The function executed for remove the intervel when componenet change
    */
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <div className='wrapper'>
                <ReactNotification />
                <header className='shadow-shadowblack xl:shadow-shadowleft'>
                    <nav >
                        <div className="xl:flex items-center justify-between flex-wrap py-3  xl:grid-cols-none grid grid-cols-3">
                            <div className={"flex items-center flex-no-shrink text-white px-8  order-2 xl:order-1 " + (this.state.menuid === true ? 'side-main animated sidebar-lg' : 'animate')}>
                                {/* The desktop icon for header */}
                                <Link key={1} to='/home' onClick={() => this.handleUrl('/home')}>
                                    <img src='/static/Icons/wineye.svg' alt='' className={" hidden " + (this.state.menuid === true ? 'xl:block ' : 'xl:block')} /></Link>
                                <Link key={2} to='/home' className='w-max xl:w-max xl:mr-auto m-auto' onClick={() => this.handleUrl('/home')}>
                                    <img src='/static/images/header/Wineye.png' alt='' className={"  w-max xl:w-max xl:mr-auto m-auto " + (this.state.menuid === false ? 'xl:hidden' : 'block xl:hidden')} /></Link>
                            </div>
                            <div className="flex items-center flex-no-shrink text-white xl:mr-6 xl:ml-0 ml-6 px-4 lg:px-0 order-1 xl:order-2 ">
                                <span onClick={this.handleClick}>
                                    <img src='/static/Icons/dropicon.svg' alt='' className='cursor-pointer' /></span>
                            </div>
                            {/* The desktop view of the header  */}
                            <div className=" flex-grow flex lg:items-center w-auto xl:block hidden xl:order-3">
                                <div className="lg:flex-grow">
                                    {this.state.Navlist.map(nav =>

                                        <Link key={nav.id} to={nav.link} onClick={() => this.handleUrl(nav.link)} className={" inline-block  px-4 nav-link text-bluecolor font-OpenSans font-semibold text-base " + (this.state.urlchange === nav.link ? 'activelink ' : '')}>
                                            {nav.text}
                                        </Link>

                                    )}

                                </div>
                            </div>
                            <div className='flex mr-5 order-3 xl:order-4 ml-auto cursor-pointer user-icon' onClick={() => this.handleDropdown()}>
                                <img src='/static/Icons/profile-user.svg' alt='' className='pr-2 w-10' />
                                <div className=' flex w-full' >
                                    <span className='my-auto user-text text-bluecolor font-OpenSans font-semibold text-sm'>{this.state.User}</span>
                                    <img src='/static/Icons/down-chevron.svg' alt='' className='pl-2 w-4 ml-auto' />
                                </div>
                                <div className={'absolute bg-white shadow-shadowblack h-10 xl:w-36 w-32 top-16  xl:right-3 md:right-2 login-drop ' + (this.state.dropstate === true ? 'visible down' : 'invisible')}>
                                    <div className='top-2/12 relative'>
                                        <Link to='/login' className='text-sm xl:px-5 px-2 text-center xl:text-left text-bluecolor font-OpenSans font-semibold block cursor-pointer ' onClick={() => this.HandleLogout()}>Logout</Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <hr className='xl:block hidden header-hr'></hr> */}

                        {/* The Mobile view of the header links */}
                        <div className=" flex-grow flex lg:items-center w-auto xl:hidden block xl:order-3 py-3">
                            <div className="  m-auto">
                                {this.state.Navlist.map(nav =>

                                    <Link key={nav.id} to={nav.link} onClick={() => this.handleUrl(nav.link)} className={" inline-block  xl:px-4 px-8 nav-link text-bluecolor font-OpenSans font-semibold text-base " + (this.state.urlchange === nav.link ? 'activelink ' : '')}>
                                        {nav.text}
                                    </Link>

                                )}
                            </div>
                        </div>

                    </nav>
                </header >
            </div >
        )
    }
};

export default withRouter(Header);