import React, { Component } from 'react';
import local from './../../global/js/locale';
import store from './../../Store/Store'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './Order.scss'
import Filter from './Filter';
import order from './../../data/Order.json'
import Loader from './../../components/Loader/Loader'
import { Link } from 'react-router-dom';
import { convertDate } from './../../global/js/browserfunction'
import Update from './../../Store/Update'
import { CurrencyFormat, capitalizeFirstLetter } from './../../global/js/browserfunction'

class OrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            Orderlist: [],
            OrderLength: '',
            pageNumbers: [],
            Count: '',
            counts: 1,
            pageid: '',
            PageStart: 0,
            PageEnd: 20,
            loading: props.loading,
            PageMin: 0,
            PageMax: 5,
            TableView: 'grid',
            sort: false,
            sortdropdown: false,
            SortbyFilter: props.SortByFilter,
            SortValue: "",
            sorthover: "",
            filtervalue: props.Filterval,
            Cursur: 'none',
            Update: {},
            updates: props.Update,
            List: props.listOrders,
            Total: props.Total,
            updateall: props.updateall,
            popstate: false
        }
        this.handlePage = this.handlePage.bind(this);
        this.handlePagecheck = this.handlePagecheck.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleView = this.handleView.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSortFilter = this.handleSortFilter.bind(this);
        this.handlesorthover = this.handlesorthover.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.Capitalize = this.Capitalize.bind(this);
        this.HandleLoad = this.HandleLoad.bind(this);
        this.hideOnClickOutside = this.hideOnClickOutside.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.Filterval !== this.props.Filterval) {
            if (this.props.Filterval === false) {
                this.setState({ sortdropdown: false });
                this.setState({ sort: false });
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        // if (nextProps.updates !== this.state.updates) {
        //     if (window.location.search === '') {
        //         var update = {
        //             orderno: '',
        //             status: '',
        //             fromdate: '',
        //             todate: '',
        //             sync: '',
        //             name: '',
        //             email: '',
        //             sort: '',
        //             page: 1
        //         }
        //         this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
        //         document.getElementById("orderno").value = "";
        //         document.getElementById("fromdate").value = "";
        //         document.getElementById("todate").value = "";
        //         document.getElementById("name").value = "";
        //         document.getElementById("email").value = "";
        //         console.log('first')
        //         this.handlePage(1)
        //         var order = [];
        //         this.setState({ Orderlist: order });
        //         this.setState({ PageMin: 0, PageMax: 5 });
        //         this.setState({ PageMin: 0, PageMax: 5 });
        //     }
        // }
        if (nextProps.loading !== this.state.loading) {
            this.setState({ loading: nextProps.loading });
        }
        if (nextProps.updateall !== this.state.updateall) {
            console.log(nextProps.updateall)
            if (nextProps.updateall == true) {
                var update = {
                    orderno: '',
                    status: '',
                    fromdate: '',
                    todate: '',
                    sync: '',
                    name: '',
                    email: '',
                    sort: '',
                    page: 1
                }
                this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
                this._isMounted && Update.dispatch({ type: 'UpdatesAll', UpdatesAll: false })
                document.getElementById("orderno").value = "";
                document.getElementById("fromdate").value = "";
                document.getElementById("todate").value = "";
                document.getElementById("name").value = "";
                document.getElementById("email").value = "";
                let target = document.getElementById("fromdate");
                document.getElementById("fromdate").classList.remove("active");
                document.getElementById("fromdate").classList.add("opacity-60");
                target.setAttribute(
                    "data-date", "MM/DD/YYYY"

                );
                let targets = document.getElementById("todate")
                document.getElementById("todate").classList.remove("active");
                document.getElementById("todate").classList.add("opacity-60");
                targets.setAttribute(
                    "data-date", "MM/DD/YYYY"

                );
                console.log('first')
                this.handlePage(1)
                var order = [];
                this.setState({ Orderlist: order });
                this.setState({ PageMin: 0, PageMax: 5 });
                this.setState({ PageMin: 0, PageMax: 5 });
            }

        }
        if (nextProps.listOrders !== this.state.listOrders) {
            this.setState({ Orderlist: nextProps.listOrders });
            var page = store.getState().urlappend.page
            if (store.getState().urlappend.page != null && store.getState().urlappend.page != undefined) {
                this.setState({ PageStart: ((page - 1) * 20) });
                this.setState({ PageEnd: (20 * page) });
                var Count = Math.ceil(page / 5)
                this.setState({ counts: Count });
                var pagemax = 5 * Count
                var pagemin = pagemax - 5
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                var length = nextProps.listOrders.length;
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                if (length < 20) {
                    this.setState({ PageEnd: this.state.PageStart + length });
                    this.setState({ Cursur: 'block' });
                }
            }
        }
        if (nextProps.Total !== this.state.Total) {
            this.setState({ Total: nextProps.Total });
            this.setState({ OrderLength: nextProps.Total });
            var length = nextProps.Total;
            var pagenum = [];
            for (let i = 1; i <= Math.ceil(length / 20); i++) {
                pagenum.push(i);
            }
            console.log(pagenum)
            this.setState({ pageNumbers: pagenum });
            this.setState({ Count: Math.ceil(pagenum.length / 5) });
            // if (length < 10) {
            //     this.setState({ PageEnd: this.state.Total });
            //     this.setState({ Cursur: 'block' });
            // }

        }
    }
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Update: store.getState().urlappend })
        })

    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    /**
* @description The function executed for capitalize the letter
*/
    Capitalize(s) {
        return s.toLowerCase().replace(/\b./g, function (a) { return a.toUpperCase(); });
    };

    /**
    * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        this._isMounted && this.HandleLoad();

        window.onpopstate = e => {
            this._isMounted && this.setState({ popstate: true });
            this._isMounted && this.HandleLoad();
            this._isMounted && this.props.passToFilter(true)
            this._isMounted && this.props.passToFilter(false)

        }

    }
    /**
   * @description The function executed for convert url to object and list order
   */
    HandleLoad() {
        window.addEventListener('click', this.hideOnClickOutside)
        console.log(this.state.List)
        //check the parameters and if the value of the sort is exists applay as sort value
        let params = new URLSearchParams(document.location.search)
        if (params.get("sort")) {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === params.get("sort"))
            this.setState({ SortValue: obj.label });
        } else {
            var sort = this.state.SortbyFilter
            var obj = sort.find(item => item.value === 'Date_Desc')
            this.setState({ SortValue: obj.label });
        }
        //conver the url parameters as object
        var str = window.location.search;
        if (str != '') {
            var search = window.location.search.substring(1);
            var update = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            var page = parseInt(update.page)
            console.log(update)
            if (update.page != null && update.page != undefined) {
                //check the url and show the corresponding page
                var popstate = this.state.popstate
                if (popstate == false) {
                    this.handlePage(page)
                } else {
                    this.handlePagecheck(page)
                }
                var Count = Math.ceil(page / 5)
                this.setState({ counts: Count });
                var pagemin = this.state.PageMin + (5 * (Count - 1))
                var pagemax = this.state.PageMax + (5 * (Count - 1))
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                if (update.orderno == undefined) {
                    document.getElementById("orderno").value = "";
                } else {
                    document.getElementById("orderno").value = update.orderno;
                }
                if (update.fromdate == undefined) {
                    let target = document.getElementById("fromdate");
                    document.getElementById("fromdate").classList.remove("active");
                    document.getElementById("fromdate").classList.add("opacity-60");
                    target.setAttribute(
                        "data-date", "MM/DD/YYYY"

                    );
                    document.getElementById("fromdate").value = "";
                } else {
                    document.getElementById("fromdate").classList.remove("opacity-60");
                    document.getElementById("fromdate").classList.add("active");
                    document.getElementById("fromdate").value = update.fromdate;
                }
                if (update.todate == undefined) {
                    let targets = document.getElementById("todate")
                    document.getElementById("todate").classList.remove("active");
                    document.getElementById("todate").classList.add("opacity-60");
                    targets.setAttribute(
                        "data-date", "MM/DD/YYYY"

                    );
                    document.getElementById("todate").value = "";
                } else {
                    document.getElementById("todate").classList.remove("opacity-60");
                    document.getElementById("todate").classList.add("active");
                    document.getElementById("todate").value = update.todate;
                }
                if (update.email == undefined) {
                    document.getElementById("email").value = "";
                } else {
                    document.getElementById("email").value = update.email;
                }
                if (update.name == undefined) {
                    document.getElementById("name").value = "";
                } else {
                    document.getElementById("name").value = update.name;
                }
            }
        }
        else {
            var update = {}
            this._isMounted && this.setState({ update: update });
            this._isMounted && store.dispatch({ type: 'urlappend', Urlappend: update })
            document.getElementById("orderno").value = "";
            document.getElementById("fromdate").value = "";
            document.getElementById("todate").value = "";
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
        }
        // if the page in url null execute the page as one
        if (params.get("page") == null) {
            console.log('page null')
            this._isMounted && this.handlePagecheck(1)
        }
        this.setState({ popstate: false });
    }
    /**
   * @description The function executed for pagination when click the pagenumber
   */
    handlePagecheck(id) {
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;
        console.log(pageStart, pageEnd)
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });



    }
    // /**
    // * @description The function executed for convert the date with timezone to date only
    // */
    // convertDate(inputFormat) {
    //     let input = inputFormat.split("T")[0];
    //     function pad(s) {
    //         return s < 10 ? "0" + s : s;
    //     }
    //     var d = new Date(input);
    //     return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
    //         "/"
    //     );
    // }
    /**
    * @description The function executed for pagination when click the pagenumber
    */
    handlePage(id) {
        window.scrollTo(0, 0)
        console.log('handle null')
        var length = this.state.Total;
        this.setState({ loading: true });
        var pageId;
        this.setState({ pageid: id });
        var update = store.getState().urlappend;
        update['page'] = id
        update = Object.keys(update)
            .filter((k) => update[k] != '')
            .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
        var str = "";
        for (var key in update) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(update[key]);
        }
        //  store.dispatch({ type: 'urlappend', Urlappend: update })
        this.props.history.push({
            pathname: '/order',
            search: "?" + str.toString()
        })
        pageId = id;
        var pageStart = (pageId - 1) * 20;
        var pageEnd = pageId * 20;
        if (pageEnd > length) {
            this.setState({ PageStart: pageStart, PageEnd: length });
        }
        else {
            this.setState({ PageStart: pageStart, PageEnd: pageEnd });
        }
        //pass the value to parent 
        this.props.passToParent(true)
        this.props.passToParent(false)
        this.setState({ loading: false });



    }
    /**
    * @description The function executed for pagination when click prev and next button
    */
    handlePagination(id) {

        var self = this.state;
        var count = this.state.counts;
        var pagemin = this.state.PageMin;
        var pagemax = this.state.PageMax;
        if (id == 'next') {
            if (self.counts < self.Count) {
                pagemin = pagemin + 5
                pagemax = pagemin + 5
                this.setState({ counts: count + 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)
            }
        }
        if (id == 'prev') {
            console.log(self.counts, self.Count)
            if (1 < self.counts) {
                pagemin = pagemin - 5
                pagemax = pagemax - 5
                this.setState({ counts: count - 1 })
                this.setState({ PageMin: pagemin, PageMax: pagemax });
                this.handlePage(pagemin + 1)

            }
        }

    }
    /**
    * @description The function executed for grid and list view
    */
    handleView(view) {
        this.setState({ TableView: view });
    }
    /**
    * @description The function executed for sort select box
    */
    handleSort() {
        console.log(this.state.sortdropdown, this.state.popstate)
        if (this.state.popstate == false) {
            this.setState({ sort: !this.state.sort });
            if (this.state.sortdropdown === false) {
                this.setState({ sortdropdown: !this.state.sortdropdown });
            } else {
                this.setState({ sortdropdown: false });
            }
        }

    }
    /**
    * @description The function executed for sort the list in orders
    */
    handleSortFilter(value) {
        var sort = this.state.SortbyFilter
        var obj = sort.find(item => item.value === value)
        var update
        this.setState({ SortValue: obj.label }, function () {
            update = store.getState().urlappend;
            update['sort'] = obj.value;
            update = Object.keys(update)
                .filter((k) => update[k] != '')
                .reduce((a, k) => ({ ...a, [k]: update[k] }), {});
            var str = "";
            for (var key in update) {
                if (str != "") {
                    str += "&";
                }
                str += key + "=" + encodeURIComponent(update[key]);
            }
            //dipatch the updated value to store
            store.dispatch({ type: 'urlappend', Urlappend: update })
            this.props.history.push({
                pathname: '/order',
                search: "?" + str.toString()
            })
            this.props.passToParent(true)

            this.props.passToParent(false)
            setTimeout(
                function () {
                    this.setState({ popstate: false });
                    this.handleSort();
                    this.setState({ sortdropdown: false });
                }
                    .bind(this),
                1000
            );

        });

        this.setState({ sortdropdown: !this.state.sortdropdown });
    }
    /**
* @description The function executes outside click
*/
    hideOnClickOutside() {
        console.log(this.state.sortdropdown)
        if (this.state.sortdropdown == true) {
            this.setState({ popstate: !this.state.popstate });
            this.handleSort();
        }
    }
    /**
    * @description The function executed hover of the sort
    */
    handlesorthover(id) {
        this.setState({ sorthover: id });
    }
    render() {
        return (
            <div className={'Order-Main' + (this.state.filtervalue)}>
                < div className='Order-List h-full shadow-shadowblack mt-7 bg-white px-5' >
                    <div className='items count flex'>
                        <div className={'item-show mt-3 w-5/12 ' + (this.state.Orderlist.length === 0 ? 'hidden' : '')}>
                            <span className='font-OpenSans font-bold text-base text-bluecolor'>Showing {this.state.PageStart + 1} - {this.state.PageEnd} of {this.state.Total} Results</span>
                        </div>
                        <div className={'item-show mt-3 w-5/12 ' + (this.state.Orderlist.length === 0 ? '' : 'hidden')}>
                            <span className='font-OpenSans font-bold text-base text-bluecolor'> {this.state.Total} Results</span>
                        </div>
                        <div className='item-filter  my-auto mt-3 flex w-7/12'>
                            {/* The grid and list view button */}
                            <div className='w-7/12 xl:w-full '>
                                <div className='w-max flex my-auto bg-bluetablebg px-3 py-1 rounded-lg ml-auto'>
                                    <span className='font-OpenSans font-semibold text-base text-bluecolor'>Sort By</span>
                                    <span className={this.state.sort === true ? 'block' : 'hidden'}><input type='text' className='border-none text-sm font-OpenSans font-normal text-sortblue bg-bluetablebg pl-2' readOnly value={this.state.SortValue} /></span>
                                    <span onClick={() => this.handleSort()}><img src='/static/Icons/sort-by.svg' alt='' className='ml-2 mt-1 cursor-pointer ' /> </span>
                                    <div className={'absolute mt-8 bg-bluetablebg rounded-lg z-20 ' + (this.state.sortdropdown === true && this.state.sort === true ? 'block ' : 'hidden ')}>
                                        <div className='block w-full py-2'>
                                            {this.state.SortbyFilter.map(list =>
                                                <div key={list.id} onMouseMove={() => this.handlesorthover(list.id)} onMouseLeave={() => this.handlesorthover(list)}>
                                                    <div className={' font-OpenSans font-semibold text-sm  py-2 px-4 pr-9 w-64 rounded-lg cursor-pointer  ' + (this.state.sorthover === list.id ? 'text-white sort-by ' : 'text-bluecolor ') + (this.state.SortValue === list.label ? 'sort-by-text' : '')} onClick={() => this.handleSortFilter(list.value)}>{list.label}</div>
                                                    <hr className={'sortfilterborder mx-4 ' + (this.state.sorthover === list.id ? 'hidden ' : 'block ') + ((this.state.SortValue === list.label ? 'hidden' : ''))} ></hr>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-3/12 ml-auto my-auto border-l  xl:hidden'>
                                <div className='w-max ml-auto flex'>
                                    <span className='text-xs font-OpenSans text-bluecolor opacity-80 my-auto'>View :</span>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/grid.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('grid')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}> <img src='/static/Icons/gridred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                    <div className='ml-2'>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'grid' ? 'block' : 'hidden'}><img src='/static/Icons/list.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                        <span onClick={() => this.handleView('list')} className={this.state.TableView === 'list' ? 'block' : 'hidden'}><img src='/static/Icons/listred.svg' alt='' className='w-max m-auto cursor-pointer' /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                        <Loader loading={this.state.loading} />
                    </div>
                    {/* The orderlist view Desktop */}
                    <div className={'items-text mt-12 hidden ' + (this.state.loading === true ? 'hidden ' : 'xl:block ') + (this.state.Orderlist.length === 0 ? '' : 'pb-6')}>
                        <table className="table-auto w-full mt-3 ">
                            <thead >
                                <tr className='order-table'>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans  py-3'>
                                    </th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Date</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Order No</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Customer Name</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Status</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Amount</th>
                                    <th className=' font-bold text-sm text-bluecolor font-OpenSans py-3'>Sync with NetSuite</th>
                                </tr>
                            </thead>
                            <tbody className={'mt-2 order-table-body ' + (this.state.Orderlist.length === 0 ? 'hidden' : '')}>
                                {/* The Foreach of the added products */}
                                {this.state.Orderlist.map(list =>
                                    <tr key={list.record_row_id}>
                                        <td className='table-order font-normal text-sm text-bluecolor font-OpenSans  py-3'>
                                            <Link to={'/order/orderdetail/' + (list.record_row_id)} >  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' /></Link>
                                        </td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{convertDate(list.transaction_date)}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.transaction_number}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{list.customer_name}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3 capitalize'>{capitalizeFirstLetter(list.status)}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>{CurrencyFormat(list.transaction_total)}</td>
                                        <td className='table-order text-center text-sm  text-bluecolor font-NotoSans font-normal py-3'>
                                            <input type="checkbox" className="form-checkbox sync-netsuite  h-4 w-4  text-white " defaultChecked={list.sync_with_netsuite == true ? true : false} readOnly /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* The orderlist view Mobile */}
                    <div className={'items-text mt-16 pb-6  gap-2 xl:hidden  ' + (this.state.loading === true ? 'hidden ' : 'block ') + (this.state.TableView === 'list' ? ' grid grid-cols-1' : 'grid grid-cols-2')}>
                        {this.state.Orderlist.map(list =>
                            <div key={list.record_row_id} className='order-border-table rounded-xl px-4 py-3 mb-4'>
                                <div className='grid grid-cols-2 gap-2 my-1'>
                                    <div className='w-max mr-auto pl-2 my-auto'>
                                        <span className='text-bluecolor font-NotoSans font-normal opacity-50 text-xs'>{convertDate(list.transaction_date)}</span>
                                    </div>
                                    <div className='w-max mr-3 pl-2 my-auto ml-auto'>
                                        <Link to={'/order/orderdetail/' + (list.record_row_id)} > <img src='/static/Icons/view.svg' alt='' className='w-max ml-auto' /></Link>
                                    </div>
                                </div>
                                <hr className='order-view-table'></hr>
                                <h2 className='text-redcolor  text-sm font-OpenSans font-semibold mt-3 py-2'>Order {list.transaction_number}</h2>
                                <table className="w-full table-order-mobile">
                                    <tbody>
                                        <tr className=''>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Customer Name</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{list.customer_name}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Amount</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl'>{this.state.Currency}&nbsp;{list.transaction_total}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1 '>Status</td>
                                            <td className=' text-bluecolor font-NotoSans font-semibold  py-1 text-sm text-right pr-2 py-2 rounded-r-xl capitalize'>{capitalizeFirstLetter(list.status)}</td>
                                        </tr>
                                        <tr>
                                            <td className=' text-bluecolor font-NotoSans font-normal opacity-70 py-1 text-sm py-2 rounded-l-xl pl-1'>Sync With NetSuite</td>
                                            <td className='w-max ml-auto mr-6 block py-2 rounded-r-xl  '>   <input type="checkbox" className="form-checkbox sync-netsuite  h-4 w-4 ml-auto   text-white  " defaultChecked={list.sync_with_netsuite == true ? true : false} readOnly /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className={'w-max m-auto py-3 ' + (this.state.Orderlist.length === 0 ? '' : 'hidden')}>
                        <span className=' font-OpenSans text-bluecolor   text-sm font-semibold'>-- No Result Found --</span>
                    </div>
                </div >
                <div className={'w-max mt-5 m-auto px-5 flex ' + (this.state.Orderlist.length === 0 ? 'hidden' : '')}>
                    <span className={'font-OpenSans text-xs font-regular border border-bluecolor  px-1   flex cursor-pointer ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('prev')}> <img src='/static/Icons/arrowleft.svg' alt='' className='w-5' /> </span>
                    {this.state.pageNumbers.slice(this.state.PageMin, this.state.PageMax).map(list =>
                        <span key={list} className={'font-OpenSans text-xs font-regular border border-bluecolor  px-3 py-1  cursor-pointer  ' + (parseInt(this.state.Update.page) === list ? 'text-white active-page ' : 'text-bluecolor ') + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePage(list)}> {list}</span>
                    )}
                    <span className={'font-OpenSans flex text-xs font-regular border border-bluecolor  px-1  cursor-pointer ' + (this.state.Cursur === 'none' ? ' cursor-pointer' : '')} onClick={() => this.handlePagination('next')}> <img src='/static/Icons/arrowright.svg' alt='' className='w-5 flex' /> </span>
                </div>
            </div >
        )
    }
};

export default OrderList;