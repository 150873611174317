import React, { Component } from 'react';
import local from '../../global/js/locale';
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import OrderInfo from './OrderInfo';
import store from './../../Store/Store'
import order from './../../data/Order.json'
import ShippingInfo from './ShippingInfo';
import Country from './../../data/Country.json'
import OrderSummary from './OrderSummary'
import { getCookie } from './../../global/js/browserfunction'
import { listOrderInformationAPI, updateOrderInformationAPI } from './../../API/Order'
import { eraseCookie } from './../../global/js/browserfunction'
import Loader from './../../components/Loader/FullLoader'
import { Link } from 'react-router-dom';
import ReactNotification from 'react-notifications-component'
import { showNotification } from './../../global/js/notification'
import 'react-notifications-component/dist/theme.css'

class Order extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: '',
            orderlist: {},
            itemlist: [],
            Id: props.match.params.id,
            loading: false,
            paymentlist: []

        }
        if (!getCookie("wn_log")) {
            this.GoToPage("/");
        }
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleListorder = this.handleListorder.bind(this);
        this.HandleString = this.HandleString.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
    }
    /**
* @description The function executed for get the value from store
*/
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }
    /**
* @description The function executed for redirect the page
*/
    GoToPage(path) {
        this.props.history.push(path);
    }
    /**
* @description The function executed for exists the components
*/
    componentWillUnmount() {
        this._isMounted = false;
    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var str = window.location.pathname
        var urls = "/" + window.location.pathname.split('/')[1];
        store.dispatch({ type: 'URL', url: urls })
        var id = this.state.Id
        this.handleListorder(id)

    }
    /**
* @description The function executed conver the updates and change the keys as the same of the api keyword
*/
    Handlechange(value) {
        var id = this.state.Id
        this.handleListorder(id)
    }

    /**
* @description The function executed get the orderdetails based on the orderid
*@param id-order id
*/
    handleListorder(id) {
        document.getElementById("Order-form").reset();
        var self = this;
        var email;
        var token;
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            var query = {
                "EMAIL": email,
                "TOKEN": token,
                "RECORD_ROW_ID": id
            }
            query = this.HandleString(query)
            console.log(query)
            var details = [
                "transaction_number",
                "status",
                "customer_name",
                "sync_with_netsuite",
                "billing_attention",
                "billing_addressee",
                "billing_attention",
                "billing_address_line_1",
                "billing_address_line_2",
                "billing_address_line_3",
                "billing_landmark",
                "billing_country_name",
                "currency_name",
                "billing_city",
                "billing_state",
                "billing_zip",
                "billing_phone",
                "shipping_addressee",
                "shipping_attention",
                "shipping_address_line_1",
                "shipping_address_line_2",
                "shipping_address_line_3",
                "shipping_country_name",
                "shipping_state",
                "shipping_city",
                "shipping_zip",
                "shipping_phone",
                "shipping_landmark",
                "shipping_method",
                "payment_name",
                "subtotal",
                "tax_total",
                "duty_total",
                "weight_total",
                "shipping_total",
                "shipping_tax",
                "transaction_total",
                "transaction_date",
                "date_created",
                "date_modified",
                "customer_id",
                "order_resolved",
                "delivery_option",
                "shipping_country_code",
                "bond_warehouse_name",
                "bond_warehouse_account",
                "bond_warehouse_customer",
                "bond_warehouse_id",
                "special_information"

            ];
            var paymentdetails = [
                "payment_order_header_id",
                "payment_record_row_id",
                "payment_transaction_number",
                "payment_date_created",
                "payment_date_modified",
                "payment_is_inactive",
                "payment_payment_number",
                "payment_name",
                "payment_amount",
                "payment_transaction_date",
                "payment_status",
                "payment_refund_amount"

            ]
            var itemdetails = [
                "product_name",
                "supplier_name",
                "customer_row_id",
                "price_online",
                "cart_quantity",
                "cart_total",
                "cart_tax",
                "cart_duty",
                "product_duty_paid",
                "product_contract_type",
                "product_row_id",
                "wine_vintage",
                "wine_packsize",
                "wine_bottlesize",
                "status",
                "price_offer",
                "product_online_price"
            ];
            //api for order information
            self.setState({ loading: true })
            listOrderInformationAPI(query, details, itemdetails, paymentdetails)
                .then((res) => res.json())
                .then(function (res) {
                    console.log(res.data)
                    let reason = res?.data?.fetchOrderInformation?.summary?.reason;
                    let status = res?.data?.fetchOrderInformation?.summary?.status;
                    if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                        var orderlist = res.data.fetchOrderInformation.details;
                        var itemlist = res.data.fetchOrderInformation.details.items;
                        var paymentlist = res.data.fetchOrderInformation.details.payment;
                        if (orderlist.special_information != null && orderlist.special_information != undefined && orderlist.special_information != '') {
                            orderlist.special_information = orderlist.special_information.replace(/<br\s*[\/]?>/g, "\n")
                        }
                        self.setState({ orderlist: orderlist })
                        self.setState({ itemlist: itemlist })
                        self.setState({ paymentlist: paymentlist })
                        self.setState({ loading: false })
                    } else {
                        self.setState({ loading: false })
                        self.props.history.push("/order?page=1");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }

    }
    /**
* @description The function executed for cancel the edit
*/
    HandleString(newupdate) {

        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            BILLING_PHONE: "BILLING_PHONE",
            BILLING_PHONE: "SHIPPING_PHONE",
            SHIPPING_ZIP: "SHIPPING_ZIP",
            BILLING_ADDRESS_LINE_1: "BILLING_ADDRESS_LINE_1",
            BILLING_ADDRESS_LINE_2: "BILLING_ADDRESS_LINE_2",
            BILLING_ADDRESS_LINE_3: "BILLING_ADDRESS_LINE_3",
            BILLING_COUNTRY: "BILLING_COUNTRY",
            BILLING_STATE: "BILLING_STATE",
            BILLING_ZIP: "BILLING_ZIP",
            BILLING_PHONE: "BILLING_PHONE",
            SHIPPING_ADDRESS_LINE_1: "SHIPPING_ADDRESS_LINE_1",
            SHIPPING_ADDRESS_LINE_2: "SHIPPING_ADDRESS_LINE_2",
            SHIPPING_ADDRESS_LINE_3: "SHIPPING_ADDRESS_LINE_3",
            SHIPPING_COUNTRY: "SHIPPING_COUNTRY",
            SHIPPING_STATE: "SHIPPING_STATE",
            SHIPPING_ZIP: "SHIPPING_ZIP",
            SHIPPING_PHONE: "SHIPPING_PHONE",
            BILLING_LANDMARK: "BILLING_LANDMARK",
            SHIPPING_LANDMARK: "SHIPPING_LANDMARK",
            SHIPPING_ADDRESSEE: "SHIPPING_ADDRESSEE",
            BILLING_ADDRESSEE: "BILLING_ADDRESSEE",
            SHIPPING_ATTENTION: "SHIPPING_ATTENTION",
            BILLING_ATTENTION: "BILLING_ATTENTION",
            SHIPPING_METHOD: "SHIPPING_METHOD",
            BOND_WAREHOUSE_CUSTOMER: "BOND_WAREHOUSE_CUSTOMER",
            BOND_WAREHOUSE_NAME: "BOND_WAREHOUSE_NAME",
            BOND_WAREHOUSE_ACCOUNT: "BOND_WAREHOUSE_ACCOUNT",
            SPECIAL_INFORMATION: "SPECIAL_INFORMATION"

        };
        let booleanFields = { RECORD_ROW_ID: "RECORD_ROW_ID", ORDER_HEADER_ID: "ORDER_HEADER_ID", BOND_WAREHOUSE_ID: "BOND_WAREHOUSE_ID", };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        return graphQLParam
    }
    /**
* @description The function executed for cancel the edit
*/
    handleCancel() {
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    /**
* @description The function executed for submit the form
*/
    handleSubmit(e) {
        e.preventDefault();

        var update = store.getState().orderupdate;
        update['ORDER_HEADER_ID'] = this.state.Id
        var self = this;
        var email;
        var token;
        var query
        console.log(query)
        if (localStorage.getItem("wn_log") !== null) {
            let login = JSON.parse(localStorage.getItem("wn_log"));
            token = login.access_token;
            email = login.email;
            update['EMAIL'] = email
            update['TOKEN'] = token
            query = this.HandleString(update)
            var details = [
                "transaction_number",

            ];
            //api for order information
            self.setState({ loading: true })
            console.log(query)
            updateOrderInformationAPI(query, details)
                .then((res) => res.json())
                .then(function (res) {
                    let reason = res?.data?.updateOrderInformation?.summary?.reason;
                    let status = res?.data?.updateOrderInformation?.summary?.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        var id = parseInt(self.state.Id)
                        self.setState({ loading: false })
                        self.handleListorder(id)
                        showNotification("Updated Order", "success");
                    } else if (reason == "RECORD_NOT_UPDATED" && status == "SUCCESS") {
                        var id = parseInt(self.state.Id)
                        self.setState({ loading: false })
                        self.handleListorder(id)
                        showNotification("Order Has Not Update", "danger");
                    }
                    else {
                        var id = parseInt(self.state.Id)
                        self.setState({ loading: false })
                        self.handleListorder(id)
                        showNotification("Cannot Update Order", "danger");
                        // self.props.history.push("/order?page=1");
                    }
                });
            store.dispatch({ type: 'EditOrder', edits: false })
        } else {
            this.GoToPage("/");
        }
        store.dispatch({ type: 'EditOrder', edits: false })
    }
    render() {
        return (
            < div className='Order-detail h-full Order-main  ' >
                <ReactNotification />
                {/* Breadthcrum of the Dashboard */}
                <div className='flex'>
                    < Breadthcrumbs Breadthcrumb={'Order Details'} Count={2} level={'Order'} />
                    <Link to='/order' className='flex bg-white w-44 rounded-xl mt-2 mr-5 border pl-2 h-8'><img src='/static/Icons/order-status.svg' alt='order-status' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To Order</span>
                    </Link>
                </div>

                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                </div>
                <div className={'order-detail-content px-5 py-2 ' + (this.state.loading === true ? 'hidden' : 'xl:block')}>
                    <form onSubmit={this.handleSubmit} id="Order-form">
                        <OrderInfo Status={order.Status} orderlist={this.state.orderlist} Id={parseInt(this.state.Id)} itemlist={this.state.itemlist} passToParent={this.Handlechange} paymentlist={this.state.paymentlist} />
                        <ShippingInfo Countrylist={Country.CountryList} ShippingMethods={order.ShippingMethod} orderlist={this.state.orderlist} Id={parseInt(this.state.Id)} paymentlist={this.state.paymentlist} />
                        <OrderSummary orderlist={this.state.orderlist} itemlist={this.state.itemlist} Countrylist={Country.CountryList} Id={parseInt(this.state.Id)} paymentlist={this.state.paymentlist} />
                    </form>

                </div>

            </div >
        )
    }
};

export default Order;