import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import paymentmethod from '../../data/PaymentMethod.json'
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import store from '../../Store/Store'
import moment from 'moment';
import cc from '../../data/countrycode.json'
import Select from 'react-select'
import { data } from 'autoprefixer';
import { convertDatetime } from '../../global/js/browserfunction'
class UserInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Int: local.INT,
            methodlist: props.MethodList,
            country: '',
            phone: (props.MethodList.phone) ? (props.MethodList.phone).split(" ")[1] : '',
            id: props.Id,
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.maskVal = this.maskVal.bind(this);
    }

    /**
        * @description  Function to subscribe to the store    
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }

    componentWillUnmount() {
        store.dispatch({ type: 'EditOrder', edits: false })
        store.dispatch({ type: 'EditUser', useredit: false })
        this._isMounted = false;

    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var countrycode = cc.CountryCode
        if (this.props.MethodList.phone !== undefined) {
            var code_user = (this.props.MethodList.phone).split(" ")[0]
            let obj_user = countrycode.find(o => o.code === code_user);
            this.setState({ country: obj_user });
        }

    }

    /**
        * @description  Function for update Country code
        * @returns {e}, entered details
        * @returns Display updated Countrycode
    */
    handleCountry(e) {
        if (e.code !== " " || e.code !== undefined) {
            var code = e.code
            var update = store.getState().userUpdate
            this.setState({ country: e })
            update["country"] = code;
        }
        if (e.code === " " || e.code === undefined) {
            var update = store.getState().userUpdate
            var cntrycode = ((this.state.methodlist.phone)?.split(" "))[0];
            update["country"] = cntrycode;
        }
    }

    /**
        * @description  Function for update Details
        * @returns {e}, entered details
        * @returns Display updated data
    */
    handleUpdate(e) {
        var self = this
        var key = e.target.id;
        if (key == 'inactive' || key == 'display_web') {
            var value = !e.target.value;
            this.setState({ key: !e.target.value })
            var update = store.getState().userUpdate
            update[key] = value;
        }
        else {
            var value = e.target.value;
            var update = store.getState().userUpdate
            update[key] = value;
        }
        store.dispatch({ type: 'UpdateUser', UpdateUser: update })
    }

    maskVal(val) {
        if ((val !== null || val !== "") && (typeof (val) === 'string')) {
            var arr = [...val]
            var newStr = ""
            if (arr.length > 0) {
                arr.forEach((item, index) => {
                    if (index < (arr.length - 2)) {
                        newStr += "*"
                    }
                    else {
                        newStr += item
                    }
                })
            }
            return newStr
        }
    }

    render() {
        return (
            <div className='ProductInfo  py-4 mx-5 px-5 my-8 bg-white shadow-shadowblack'>
                <div className='flex'>
                    <span className={'font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 mt-2 '}>Details</span>
                </div>
                <hr className='sortfilterborder my-4' />
                {/* User Main Detail Section */}
                <div className='flex px-4'>
                    {/* User Main Detail Section - Left side*/}
                    <div className='xl:flex xl:w-7/12 w-full'>
                        <table className="w-full">
                            <tbody>

                                <tr>
                                    <td className='xl:w-36 w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>First Name</span></td>
                                    <td className='py-1'>
                                        {
                                            this.state.Edit === true ?
                                                <input type='text' id="firstname" name='Name' onInput={this.handleUpdate} defaultValue={this.state.methodlist?.first_name || ''} required className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-64  px-2' />
                                                : <span className='font-OpenSans font-normal text-sm text-bluecolor w-64'>{this.state.methodlist?.first_name}</span>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td className='xl:w-36 w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Last Name</span></td>
                                    <td className='py-1'>
                                        {this.state.Edit === true ?
                                            <input type='text' id="lastname" name='Name' onInput={this.handleUpdate} defaultValue={this.state.methodlist.last_name ? this.state.methodlist.last_name : ''} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor w-64 px-2' required />
                                            : <span className='font-OpenSans font-normal text-sm text-bluecolor w-64'>{this.state.methodlist.last_name}</span>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td className='xl:w-36 w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Email</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-64'>{this.state.methodlist.email}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='xl:w-36 w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Phone No</span></td>
                                    <td className='py-1'>
                                        {this.state.Edit === true ?


                                            <div className="2xl:flex flex xl:w-48  w-64">
                                                <span className='user-select order-page my-auto'>
                                                    <Select
                                                        value={this.state.country}
                                                        onChange={this.handleCountry}
                                                        options={cc.CountryCode} isSearchable={true}
                                                    />
                                                </span>

                                                <input type='text' id='phone' name='Name' defaultValue={((this.state.methodlist?.phone).split(" "))[1] ? ((this.state.methodlist?.phone).split(" "))[1] : this.state.methodlist.phone} onInput={this.handleUpdate} className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md  focus:border-redcolor xl:w-36 w-56 w-full px-2 ml-1' required minLength="8" maxLength="15" pattern="^(?!0*$)[0-9-]+" />
                                            </div>
                                            : <span className='font-OpenSans font-normal text-sm text-bluecolor'>{this.state.methodlist.phone}</span>
                                        }
                                    </td>
                                </tr>

                            </tbody>

                        </table>

                        <table className="w-full xl:hidden">
                            <tbody>
                                <tr>
                                    <td className=' w-52 py-1'> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor '>Reset Token</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.reset_token ? this.maskVal(this.state.methodlist.reset_token) : null}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Reset Token Date</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.reset_token_request_date ? convertDatetime(this.state.methodlist.reset_token_request_date) : null}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Last Login Date</span></td>
                                    <td className=''>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.last_login_date ? convertDatetime(this.state.methodlist.last_login_date) : null} </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor'>Last Password reset date</span></td>
                                    <td className=''>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.last_password_reset_date ? convertDatetime(this.state.methodlist.last_password_reset_date) : null}</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    {/* User Main Detail Section - Right Side */}
                    <div className='xl:flex xl:w-7/12 w-1/2 ml-4 xl:block hidden'>
                        <table className="w-full">
                            <tbody>
                                <tr>
                                    <td className='w-52 py-1'> <span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor w-32'>Reset Token</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.reset_token ? this.state.methodlist.reset_token ? this.maskVal(this.state.methodlist.reset_token) : null : null}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor w-32'>Reset Token Date</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.reset_token_request_date ? convertDatetime(this.state.methodlist.reset_token_request_date) : null}</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor w-32'>Last Login Date</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.last_login_date ? convertDatetime(this.state.methodlist.last_login_date) : null} </span>
                                    </td>
                                </tr>

                                <tr>
                                    <td className='w-52 py-1'><span className='font-OpenSans font-semibold text-sm  mr-6 text-bluecolor w-32'>Last Password reset date</span></td>
                                    <td className='py-1'>
                                        <span className='font-OpenSans font-normal text-sm text-bluecolor w-32'>{this.state.methodlist.last_password_reset_date ? convertDatetime(this.state.methodlist.last_password_reset_date) : null}</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
};

export default UserInformation;