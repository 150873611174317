import React, { Component } from 'react'
class SyncStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalunsyc: props.totalunsyc
        }
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.totalunsyc !== this.state.totalunsyc) {
            this.setState({ totalunsyc: nextProps.totalunsyc })
        }
    }
    render() {
        return (
            <div >
                <h3 className='py-2 px-2 pl-4 xl:text-base text-sm text-bluecolor font-OpenSans font-semibold'>Sync Status</h3>
                <div className='unsync-order relative  xl:h-full h-72 pt-12'>
                    <h2 className='py-2 px-2 xl:text-3xl text-3xl text-redcolor font-OpenSans font-bold text-center my-2 unsync-amount pl-4 xl:pl-0'>{this.state.totalunsyc}</h2>
                    <span className='orders-unsync-text text-bluecolor font-OpenSans font-semibold text-center my-2 w-max m-auto block' >Unsync Orders</span>
                </div>

            </div>
        )
    }
};

export default SyncStatus;