
import { BASEURL, WEBSTORE_BASEURL, CRONJOB_BASEURL } from './Baseurl'
/**
     * @description  The function for server working
     * @returns server working or not
     */
function TestServerAPI() {
    //The details are convert to string
    return fetch(BASEURL + 'testserver', {

    })
}
function WebstoreServerAPI() {
    //The details are convert to string
    return fetch(WEBSTORE_BASEURL + 'testserver', {

    })
}
function CronjobServerAPI() {
    //The details are convert to string
    return fetch(CRONJOB_BASEURL + 'testserver', {

    })
}
function LivexserverAPI() {
    //The details are convert to string
    return fetch(WEBSTORE_BASEURL + 'api/livex', {

    })
}
export {
    TestServerAPI,
    WebstoreServerAPI,
    LivexserverAPI,
    CronjobServerAPI
}