import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import './PaymentDetail.scss'
import { convertDate, convertDatetime } from './../../global/js/browserfunction'
import moment from 'moment';
import { CurrencyFormat, capitalizeFirstLetter } from './../../global/js/browserfunction'
class PaymentInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Currency: local.locale.CURRENCY.SYMBOL,
            payment: props.Payment,
            paymentdetails: props.paymentdetails,
            PaymentData: '',
            CurrentOrder: '',
            id: props.Id,
            FORMAT: local.locale.DATE.FORMAT,

        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.paymentdetails !== this.state.paymentdetails) {
            this.setState({ paymentdetails: nextProps.paymentdetails })
            console.log(nextProps.paymentdetails)
        }

    }
    /**
* @description The function executed for before the component call
*/
    componentDidMount() {
    }

    render() {
        return (
            <div>
                < div className='paymentInformation h-full py-5 px-6 bg-white shadow-shadowblack mt-8' >
                    <div className='w-full flex'>
                        <h1 className='font-OpenSans font-semibold  text-bluecolor xl:text-xl text-xl my-auto ml-3 mt-3'>Payment Information</h1>
                    </div>
                    <hr className='sortfilterborder my-4' />
                    <div className='flex px-4 mt-4'>
                        <div className='xl:w-5/12 w-1/2'>
                            <table className="mt-4 ">
                                <tbody>
                                    {/* <tr>
                                    <td className='w-24 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Order </td>
                                    <td className='w-24 font-OpenSans font-semibold text-sm text-redcolor py-1'> <Link to={'/order/orderdetail/' + (this.state.paymentdetails.order_header_id)} >{this.state.paymentdetails.order_transaction_number}</Link></td>
                                </tr> */}
                                    <tr>
                                        <td className='w-24 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Payment </td>
                                        <td className='w-24 font-OpenSans font-normal text-sm text-bluecolor py-1'>{this.state.paymentdetails.payment_method_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Payment No </td>
                                        <td className='w-60 font-OpenSans font-normal text-sm text-bluecolor py-1 flex'>{this.state.paymentdetails.payment_number}<a target="_blank" className='m-auto' href={'https://dashboard.stripe.com/payments/' + (this.state.paymentdetails.payment_number)}>  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto' /></a></td>
                                    </tr>
                                    <tr>
                                        <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Refund No </td>
                                        <td className='w-60 font-OpenSans font-normal text-sm text-bluecolor py-1 flex'>{this.state.paymentdetails.refund_number}<a target="_blank" className={'m-auto ' + (this.state.paymentdetails.refund_number === null || this.state.paymentdetails.refund_number === '' ? 'hidden' : '')} href={'https://connect.stripe.com/test/payments/' + (this.state.paymentdetails.payment_number)}>  <img src='/static/Icons/view.svg' alt='' className='w-max m-auto ' /></a></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='xl:flex xl:w-7/12 w-1/2'>
                            <div className='xl:w-1/2 xl:mt-4 xl:mb-4'>

                                <table className="">
                                    <tbody>

                                        <tr>
                                            <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Payment Token </td>
                                            <td className='w-44 font-OpenSans font-normal text-sm text-bluecolor py-1'>{this.state.paymentdetails.payment_token}</td>
                                        </tr>

                                        <tr>
                                            <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Transaction Amount</td>
                                            <td className='w-44 font-OpenSans font-normal text-sm text-bluecolor py-1'>{CurrencyFormat(this.state.paymentdetails.transaction_amount)}</td>
                                        </tr>
                                        <tr>
                                            <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Refund Amount </td>
                                            <td className='w-44 font-OpenSans font-normal text-sm text-bluecolor py-1'>{CurrencyFormat(this.state.paymentdetails.refund_amount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='xl:w-1/2 xl:my-auto mb-4 xl:mt-4'>
                                <table className="">
                                    <tbody>
                                        <tr>
                                            <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Currency</td>
                                            <td className='w-44 font-OpenSans font-normal text-sm text-bluecolor py-1'>{this.state.paymentdetails.currency_name}</td>
                                        </tr>
                                        <tr>
                                            <td className='w-44 font-OpenSans font-semibold text-sm text-bluecolor py-1'>Amount  </td>
                                            <td className='w-44 font-OpenSans font-normal text-sm text-bluecolor py-1'>{CurrencyFormat(this.state.paymentdetails.amount)}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div >
                    </div >

                </div >
                <div className='order-summary mt-16'>
                    <div className='xl:w-2/7 w-3/7 px-5 order-sum ml-auto py-3'>
                        <h2 className='font-OpenSans font-semibold  text-orderred  text-sm text-xs pb-3'>Order Summary</h2>
                        <table className="w-full">
                            <tbody className=''>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm '>Sub Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm w-10'>{CurrencyFormat(this.state.paymentdetails.order_header_subtotal)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Tax Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_tax_total)}</td>
                                </tr>
                                <tr>
                                    <td className='py-1 font-OpenSans font-normal  text-orderred  text-sm'>Duty Paid</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_duty_total)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>Weight Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.paymentdetails.order_header_weight_total == null ? 0 : (this.state.paymentdetails.order_header_weight_total).toFixed(2)}Kg</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.paymentdetails.order_header_delivery_option === 'DELIVERY' ? "Shipping" : 'Transfer'} Total</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_shipping_total)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-normal  text-orderred  text-sm'>{this.state.paymentdetails.order_header_delivery_option === 'DELIVERY' ? "Shipping" : 'Transfer'} Tax</td>
                                    <td className='text-left py-1 font-OpenSans font-normal  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_shipping_tax)}</td>
                                </tr>
                                <tr>
                                    <td className=' py-1 font-OpenSans font-bold pt-2 text-orderred  text-sm'>Transaction Total</td>
                                    <td className='text-left py-1 font-OpenSans  pt-2 font-bold  text-orderred  text-sm'>{CurrencyFormat(this.state.paymentdetails.order_header_transaction_total)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
};

export default PaymentInformation;