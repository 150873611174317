import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import Loader from '../../components/Loader/FullLoader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import user from '../../data/User.json'
import store from '../../Store/Store'
import UserInformation from './UserInformation';
import UserDetails from './UserDetails';
import { getCookie } from './../../global/js/browserfunction'
import { singleUserListAPI, updateUserAPI, sendPasswordAPI } from '../../API/Users';
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
class UserDetail extends Component {

    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            Edit: store.getState().edit,
            Id: props.match.params.id,
            loading: false,
            isEdit: false,
            userData: [],
            total: '',
            Currentmethod: {},
            firstName: '',
            lastName: '',
            phone: '',
            inactive: false,
            giveAccess: '',
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRstPassword = this.handleRstPassword.bind(this);
        this.handleData = this.handleData.bind(this);
        this.Handlechange = this.Handlechange.bind(this);
    }

    /**
        * @description  Function to subscribe to the store and calls API for user single view
        * @returns userdata of a single User  
    */
    async handleCheck() {
        this.handleData()
        if (this._isMounted) {
            store.subscribe(() => {
                this._isMounted && this.setState({ Edit: store.getState().edit })
            })

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        var update = {}
        store.dispatch({ type: 'UpdateUser', UpdateUser: update })
        store.dispatch({ type: 'Update', Update: update })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this._isMounted = true;
        this._isMounted && this.handleCheck();
    }
    Handlechange() {
        this.handleData()
    }
    handleData() {
        var self = this;
        var token;
        var email;
        var id;

        var newupdate = {};
        if (!getCookie("wn_log")) {//checking for empty cookies
            self.props.history.push("/user");
        }
        if (localStorage.getItem("wn_log") !== null) {//checking  for empty token
            self.setState({ loading: true })
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            id = self.state.Id
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['RECORD_ID'] = id
        } else {
            self.props.history.push("/user");
        }

        //convert the object to string also check the bolean and string
        newupdate = Object.keys(newupdate)
            .filter((k) => newupdate[k] != '')
            .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
        let stringFields = {
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
        };

        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }

        var details = [
            "email",
            "first_name",
            "last_name",
            "date_created",
            "date_modified",
            "reset_token",
            "reset_token_request_date",
            "have_access",
            "is_inactive",
            "last_login_date",
            "last_password_reset_date",
            "phone"
        ];

        singleUserListAPI(graphQLParam, details)//API call for getting single User details
            .then((res) => res.json())
            .then(function (res) {

                let reason = res?.data?.fetchAdminDetails?.summary?.reason;
                let status = res?.data?.fetchAdminDetails?.summary?.status;
                if (reason == "RECORD_FOUND" && status == "SUCCESS") {
                    let data = res.data.fetchAdminDetails.details;
                    self.setState({ Currentmethod: data }); // Setting value of selected User Details to the state Currentmethod       
                } else {
                    self.setState({ loading: false })
                    self.props.history.push("/user?page=1");
                }
                self.setState({ loading: false })
            })
    }

    /**
        * @description  Function for form submission. it stores the updated details of an User
        * @param {e}, entered details
        * @returns updated details of a particular User    
    */
    handleSubmit(e) {
        e.preventDefault();
        var self = this;
        var token;
        var email;
        var newupdate = {};
        var update = store.getState().userUpdate
        console.log(store.getState().userUpdate)
        if (!getCookie("wn_log")) {//checking for empty cookies
            self.props.history.push("/user");
        }

        if (localStorage.getItem("wn_log") !== null) {//checking for empty tokens
            let obj = JSON.parse(localStorage.getItem("wn_log"));
            token = obj.access_token;
            email = obj.email;
            newupdate['EMAIL'] = email
            newupdate['TOKEN'] = token
            newupdate['USER_EMAIL'] = self.state.Currentmethod.email
            if (update.firstname != undefined) {
                newupdate['FIRST_NAME'] = update.firstname
            }
            if (update.lastname != undefined) {

                newupdate['LAST_NAME'] = update.lastname
            }
            if (update.phone) {
                newupdate['PHONE'] = (update.country ? update.country + " " : ((self.state.Currentmethod.phone).split(" "))[0] + " ") + (update.phone ? update.phone : ((self.state.Currentmethod.phone).split(" "))[1])
            }

            newupdate['GIVE_ACCESS'] = update.Acess ? update.Acess : self.state.giveAccess
            newupdate['IS_INACTIVE'] = update.inActive ? update.inActive : self.state.inactive
        }

        else {
            self.props.history.push("/user");
        }

        let stringFields = {//setting string fields
            EMAIL: "EMAIL",
            TOKEN: "TOKEN",
            USER_EMAIL: "USER_EMAIL",
            FIRST_NAME: "FIRST_NAME",
            LAST_NAME: "LAST_NAME",
            PHONE: "PHONE",
        };
        let booleanFields = { GIVE_ACCESS: "GIVE_ACCESS", IS_INACTIVE: "IS_INACTIVE", RECORD_ID: "RECORD_ID" };//setting boolean fields
        let graphQLParam = ``;

        for (const [key, value] of Object.entries(newupdate)) {
            if (stringFields[key]) graphQLParam += `${key}:"${value}",`;
            else if (booleanFields[key]) graphQLParam += `${key}:${value},`;
            else
                graphQLParam += `${key}:${Number.isNaN(Number(value))
                    ? '"' + value + '"'
                    : Number(value)
                    },`;
        }
        console.log(graphQLParam)
        var details = [//setting up what data should display
            "email",
            "first_name",
            "last_name",
            "date_created",
            "date_modified",
            "reset_token",
            "reset_token_request_date",
            "have_access",
            "is_inactive",
            "last_login_date",
            "last_password_reset_date",
            "phone"
        ];

        if (self.state.Edit) {
            updateUserAPI(graphQLParam, details)//API call for Upadting user details
                .then((res) => res.json())
                .then(function (res) {
                    self.setState({ loading: true })
                    let reason = res.data.updateAdmin.summary.reason;
                    let status = res.data.updateAdmin.summary.status;
                    if (reason == "RECORD_UPDATED" && status == "SUCCESS") {
                        showNotification("Updated Successfully", "success")
                        let data = res.data.updateAdmin.details;
                        self.handleData()

                    }

                })
        }

    }

    /**
        * @description  Function for sending password for new users and resetting password for existing users
        * @returns successful message of sending password   
    */
    handleRstPassword() {
        var self = this
        if (!self.state.Edit) {
            var obj = self.state.Currentmethod
            var email = obj.email
            var newupdate = {}
            newupdate['EMAIL'] = email

            //convert the object to string also check the bolean and string
            newupdate = Object.keys(newupdate)
                .filter((k) => newupdate[k] != '')
                .reduce((a, k) => ({ ...a, [k]: newupdate[k] }), {});
            let stringFields = {
                EMAIL: "EMAIL"
            };
            let graphQLParam = ``;

            for (const [key, value] of Object.entries(newupdate)) {
                if (stringFields[key]) graphQLParam += `${key}:"${value}",`;

                else
                    graphQLParam += `${key}:${Number.isNaN(Number(value))
                        ? '"' + value + '"'
                        : Number(value)
                        },`;
            }
            var details = [
                "email",
                "first_name",
                "last_name",
                "last_login_date",
                "last_password_reset_date",
            ];
            if (!self.state.Edit) {
                sendPasswordAPI(graphQLParam, details)//API call for send/resetting password token for  user
                    .then((res) => res.json())
                    .then(function (res) {
                        self.setState({ loading: true })
                        let reason = res.data.forgotPassword.summary.reason;
                        let status = res.data.forgotPassword.summary.status;
                        if (reason == "EMAIL_SEND" && status == "SUCCESS") {
                            showNotification("Sent Password Link", "success")
                            self.handleData()
                        }

                    })
            }
        }
    }

    render() {
        return (
            this.state.loading === true ?
                <div className={this.state.loading ? 'parentDisable w-max m-auto block ' : ''}>
                    <Loader loading={this.state.loading} />
                    <ReactNotification />
                </div> :
                <div className='paymentMethod-main h-screen'>
                    <div className="flex">
                        < Breadthcrumbs Breadthcrumb={'User Details'} Count={2} level={'User'} />
                        <Link to='/user' className='flex bg-white w-40 rounded-xl mt-2 mr-5 border pl-2 h-8'>
                            <img src='/static/Icons/user_form.png' alt='user' className='w-4 h-4  my-auto' /> <span className=' font-OpenSans font-semibold text-sm my-auto ml-2 text-bluecolor'>Back To User</span>
                        </Link>
                    </div>
                    <ReactNotification />
                    <form onSubmit={this.handleSubmit}>
                        <UserDetails MethodList={this.state.Currentmethod} passToParent={this.Handlechange} Id={parseInt(this.state.Id)} resetPassword={this.handleRstPassword} />
                        <UserInformation MethodList={this.state.Currentmethod} Id={parseInt(this.state.Id)} isEdit={this.state.isEdit} />
                    </form>


                </div>

        )
    }
};

export default UserDetail;