import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import local from '../../global/js/locale';
import paymentmethod from './../../data/PaymentMethod.json'
import Loader from '../../components/Loader/Loader'
import Breadthcrumbs from '../../components/Breadthcrumbs/Breadcrumbs'
import './../PaymentMethod/PaymentMethod.scss'
import store from './../../Store/Store'
import moment from 'moment';
import { convertDatetime } from '../../global/js/browserfunction'
import { showNotification } from './../../global/js/notification'
import ReactNotification from 'react-notifications-component'
class PaymentMethodinfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Edit: store.getState().edit,
            varchar: local.VARCHAR,
            Int: local.INT,
            Display_web: props.MethodList.display_in_website,
            methodlist: props.MethodList,
            inactive: props.MethodList.is_inactive,
            id: props.Id,
            Currentmethod: ''
        }
        this.HandleEdit = this.HandleEdit.bind(this);
        this.HandleCancel = this.HandleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleDisplayToggle = this.handleDisplayToggle.bind(this);
    }

    /**
        * @description The function executed for get the edit value from store
    */
    async handleCheck() {
        store.subscribe(() => {
            this._isMounted && this.setState({ Edit: store.getState().edit })
        })
    }

    /**
        * @description The function executed for when exists the component
    */
    componentWillUnmount() {
        this._isMounted = false;
        store.dispatch({ type: 'EditOrder', edits: false })
    }

    /**
        * @description The function executed for before the component call
    */
    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.handleCheck();
        var id = this.state.id;

    }

    /**
        * @description The function executed for when the edit button clicks
    */
    HandleEdit() {
        store.dispatch({ type: 'EditOrder', edits: !this.state.Edit })
    }

    /**
        * @description The function executed for cancel the edit
    */
    HandleCancel() {
        store.dispatch({ type: 'EditOrder', edits: false })
        var update = {}
        store.dispatch({ type: 'Update', Update: update })
    }

    /**
        * @description The function executed for when update from inputbox store the value as object based on id
        * @param {e}, entered Data
    */
    handleUpdate(e) {
        var key = e.target.name;
        var value = e.target.value;

        if (key === 'name' && (value === '' || value === undefined || value === null)) {
            showNotification("Name can't be Empty", "warning")
        }

        var update = store.getState().update
        update[key] = value;
        store.dispatch({ type: 'Update', Update: update })

    }

    /**
        * @description The function executed for change the state of active and inactive
        * @param {e}, object of selected option
        * @returns selected value
    */
    handleToggle(e) {

        e.preventDefault();
        var self = this
        var key = e.target.id

        if (key === 'inactive' && self.state.Edit === true) { //checking for correct ID
            var elm = document.getElementById('inactive');
            var v = !this.state.inactive
            var value = v
            var update = store.getState().update
            update[key] = JSON.stringify(v); //setting value to the update object in the store

            store.dispatch({ type: 'Update', Update: update })//updating value in the store
            self.setState({ inactive: v })//setting value in corresponding state
        }

        else {
            return null
        }

    }

    /**
        * @description The function executed for change the state of display web
        * @param {e}, object of selected option
        * @returns selected value
    */
    handleDisplayToggle(e) {

        e.preventDefault();
        var self = this
        var key = e.target.id

        if (e.target.id === 'display_web' && self.state.Edit === true) { //checking for correct ID
            var act = document.getElementById('display_web');
            var v = !this.state.Display_web
            var value = v //setting value to the update object in the store
            var update = store.getState().update
            update[key] = JSON.stringify(v);
            store.dispatch({ type: 'Update', Update: update })//updating value in the store
            self.setState({ Display_web: v })//setting value in corresponding state
        }

        else {
            return null
        }

    }

    render() {
        return (
            <div className='payment-content mx-5 py-4 pt-6 px-6 bg-white shadow-shadowblack mt-2'>
                {/* Name and Edit section */}
                <div className='w-full flex  px-2  '>
                    <div className='w-8/12 flex'>
                        <img src='/static/Icons/payment.svg' alt='' className='w-10' />
                        <span className='font-OpenSans font-semibold  text-bluecolor xl:text-2xl text-xl my-auto ml-3 '>{this.state.methodlist.name}  </span>
                    </div>
                    <div className={'w-4/12 my-auto ' + (this.state.Edit === true ? 'hidden' : 'block')}>
                        <span className='ml-auto w-max block ml-auto mr-4 cursor-pointer' onClick={() => this.HandleEdit()}><img src='/static/Icons/edit.svg' alt='' className='w-max' /></span>
                    </div>
                    <div className={'action w-max ml-auto my-auto ' + (this.state.Edit === true ? '' : 'hidden')}>
                        <div className='buttons w-max flex ml-auto'>
                            <div className='flex bg-bluecolor rounded-xl px-4 py-1'>
                                <button type='submit' className='  font-OpenSans font-semibold text-base text-white mx-3 '>
                                    <div className='flex'>
                                        <img src='/static/Icons/save_category.svg' alt='' className='w-4 m-auto' />
                                        <span className='ml-2'>Save</span>
                                    </div>
                                </button>
                            </div>

                            <span className='bg-white px-4 py-1 font-OpenSans font-semibold text-base text-bluecolor mx-3 rounded-xl cursor-pointer' onClick={() => { this.HandleCancel(); this.props.handleData(); }}>Cancel</span>
                        </div>
                    </div>
                </div>

                <hr className='sortfilterborder my-4 ' />

                {/* Product Information Section */}
                <div className='xl:w-full px-4 xl:flex'>
                    <div className='flex xl:w-1/2'>
                        <table className='xl:w-1/2 w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Display Name </td>
                                    <td className='w-32 font-OpenSans font-normal text-sm text-bluecolor py-2'>
                                        <span className={'font-normal ont-OpenSans d text-sm text-bluecolor ' + (this.state.Edit === true ? 'hidden' : '')}>{this.state.methodlist.name}</span>
                                        <span className={this.state.Edit === true ? 'w-max ' : 'hidden'}><input type='text' name='name' className='font-OpenSans font-normal text-sm text-bluecolor border rounded-md pl-3 focus:border-redcolor xl:w-56 w-44 ml-2' defaultValue={this.state.methodlist.name} maxLength={this.state.varchar['SM']} required onChange={this.handleUpdate} pattern="\s*\S+.*" title="space only is not allowed" /></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2'>Inactive</td>
                                    <td className={'w-32 font-OpenSans font-normal text-sm text-bluecolor py-2 '}> <span id="inactive" onClick={this.handleToggle} defaultValue={this.state.inactive} className={'cursor-pointer ' + (this.state.Edit === true ? 'pl-8 xl:pl-3' : '')}><input type="checkbox" name={(this.state.Edit === true ? 'inactive ' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white " + (this.state.Edit === true ? 'cursor-pointer' : '')} checked={this.state.inactive} readOnly={!this.state.Edit} /></span></td>
                                </tr>
                            </tbody>
                        </table>

                        <table className='xl:w-1/2 w-1/2'>
                            <tbody>
                                <tr>
                                    <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Date Modified </td>
                                    <td className='w-32 font-OpenSans font-normal text-sm text-bluecolor py-2'> {this.state.methodlist && convertDatetime(this.state.methodlist.date_modified)}</td>
                                </tr>
                                <tr>
                                    <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2'>Display in Web </td>
                                    <td className='w-32 font-OpenSans font-normal text-sm text-bluecolor py-2'> <span id="display_web" onClick={this.handleDisplayToggle} defaultValue={this.state.Display_web} className='cursor-pointer'><input type="checkbox" name={(this.state.Edit === true ? 'display_web' : '')} className={"form-checkbox sync-netsuite  h-4 w-4  text-white " + (this.state.Edit === true ? 'cursor-pointer' : '')} checked={this.state.Display_web} readOnly={!this.state.Edit} /></span> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table className='xl:w-1/4 w-1/2'>
                        <tbody>
                            <tr className='align-baseline'>
                                <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2 '>Date Created</td>
                                <td className='w-32 font-OpenSans font-normal text-sm text-bluecolor py-2'> {this.state.methodlist && convertDatetime(this.state.methodlist.date_created)}</td>
                            </tr>
                            <tr>
                                <td className='w-32 font-OpenSans font-semibold text-sm text-bluecolor py-2'> </td>
                                <td className='w-32 font-OpenSans font-normal text-sm text-bluecolor py-2'> </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        )
    }
};

export default PaymentMethodinfo;