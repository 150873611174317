import { createStore } from 'redux';

const initState = {
    CSVkeys: undefined,
    Files: ''
}

function reducer(state = initState, action) {
    if (action.type == 'CSV') {
        return {
            ...state,
            CSVkeys: action.Key
        }
    }
    if (action.type == 'File') {
        return {
            ...state,
            Files: action.File
        }
    }
}
const CSV = createStore(reducer);
export default CSV;


