import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Login.scss'
import store from './../../Store/Store'
import local from '../../global/js/locale';
import { emailregex } from './../../global/js/validator'
class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            valid: false,
            show: false,
            password: '',
            phone: '',
            passwordvalid: false,
            varchar: local.VARCHAR,
            Int: local.INT
        }
        this.emailvalidator = this.emailvalidator.bind(this);
        this.PasswordValidator = this.PasswordValidator.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        store.dispatch({ type: 'Header', header: true })
        this.emailvalidator()
    }
    handleChange = (e) => {
        if (e.target.id == 'email') {
            this.setState({ email: e.target.value }, function () {
                this.emailvalidator();
            });
        }
        else if (e.target.id == 'password') {
            this.setState({ password: e.target.value }, function () {
                this.PasswordValidator()
            });


        }

    };
    emailvalidator() {
        if (emailregex.test(this.state.email)) {
            this.setState({ valid: true })
        } else {
            this.setState({ valid: false })
        }
    }
    PasswordValidator() {
        let passlen = this.state.password.length;
        console.log(this.state.password)
        this.setState({ password: this.state.password.trim() })
        if (passlen > 5 && passlen < 15) {
            this.setState({ passwordvalid: true })
            return false;
        } else {
            this.setState({ passwordvalid: false })
        }
    }
    handleShow() {
        this.setState({ show: !this.state.show })
    }
    handleSubmit(e) {
        store.dispatch({ type: 'Header', header: true })
        e.preventDefault();
        if (this.state.valid == true) {
            console.log(this.state.email, this.state.password)
        }
    }
    render() {
        return (
            < div className=' mt-5 ' >
                <div className='user-icon m-auto w-max  relative top-9'>
                    <img src='/static/Icons/user_icon.png' alt='' />
                </div>
                <div className='xl:w-1/3 w-2/3 m-auto bg-white shadow-loginshadow '>
                    <h1 className='login-head xl:text-4xl text-2xl font-OpenSans font-normal text-bluecolor text-center py-12'>Create New Account</h1>
                    <form action="" className="mt-2 flex flex-col lg:w-2/3 w-8/12 m-auto" onSubmit={this.handleSubmit} autoComplete="off">
                        <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded mb-6 ">
                            <div className="flex -mr-px justify-center w-15 p-4 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white px-3 pl-0 border-0 rounded rounded-r-none text-2xl text-gray-600"
                                >
                                    <img src='/static/Icons/user_form.png' alt='' />
                                </span>
                            </div>
                            <input
                                type="text" id='name' autoComplete="off" maxLength={this.state.varchar['LG']}
                                className={"flex-shrink text-base input-box text-bluecolor  flex-grow flex-auto leading-normal w-px flex-1 h-10   px-3 self-center relative   text-sm outline-none border-b font-OpenSans "}
                                placeholder="Full Name" onChange={this.handleChange}
                            />
                        </div>
                        <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded mb-6 ">
                            <div className="flex -mr-px justify-center w-15 p-4 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white px-3 pl-0 border-0 rounded rounded-r-none text-2xl text-gray-600"
                                >
                                    <img src='/static/Icons/email_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type="text" id='email' autoComplete="off" maxLength={this.state.varchar['MD']}
                                className={"flex-shrink text-base input-box text-bluecolor  flex-grow flex-auto leading-normal w-px flex-1 h-10   px-3 self-center relative   text-sm outline-none border-b font-OpenSans " + (this.state.valid === true ? 'border-grey-light' : 'border-redcolor')}
                                placeholder="Email" value={this.state.email} onChange={this.handleChange}
                            />
                        </div>
                        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white items-center rounded mb-4">
                            <div className="flex -mr-px justify-center w-15 p-4 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white   px-3 pl-0 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/password_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type={this.state.show === true ? 'text' : 'password'} id='password' autoComplete="off" maxLength={this.state.varchar['2X']}
                                className="flex-shrink flex-grow input-box flex-auto text-bluecolor leading-normal w-px flex-1 border-0 h-10 px-3 relative self-center  outline-none border-b font-OpenSans text-sm"
                                placeholder="Password" onChange={this.handleChange}
                            />
                            <div className="flex -mr-px border-b font-OpenSans h-10 my-auto">
                                <span
                                    className="flex items-center leading-normal bg-white rounded rounded-l-none border-0 px-3 whitespace-no-wrap text-gray-600"
                                >
                                    <img src='/static/Icons/show_password.png' alt='' className='cursor-pointer' onClick={this.handleShow} />
                                </span>
                            </div>

                        </div>
                        <span className={'font-OpenSans text-sm text-redcolor w-max ml-auto ' + (this.state.passwordvalid === true ? 'hidden' : 'block')}>Password must be between 5-15 characters</span>
                        <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 bg-white items-center rounded mb-6 ">
                            <div className="flex -mr-px justify-center w-15 p-4 pl-0">
                                <span
                                    className="flex items-center leading-normal bg-white px-3 pl-0 border-0 rounded rounded-r-none text-2xl text-gray-600"
                                >
                                    <img src='/static/Icons/call_icon.png' alt='' />
                                </span>
                            </div>
                            <input
                                type="text" id='phone' autoComplete="off" maxLength={this.state.varchar['MD']} pattern='^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$'
                                className={"flex-shrink text-base input-box text-bluecolor  flex-grow flex-auto leading-normal w-px flex-1 h-10   px-3 self-center relative   text-sm outline-none border-b font-OpenSans "}
                                placeholder="Phone Number" onChange={this.handleChange}
                            />
                        </div>
                        <button
                            href="/" type='submit'
                            className="bg-redcolor font-semibold py-4 mx-2 text-center w-full text-white rounded leading-tight text-xl xl:text-lg font-OpenSans font-sans mt-4 mb-2 m-auto"
                        >
                            Sign Up
                          </button>
                        <div className='flex mt-6 m-auto mb-16'>
                            <span className='font-OpenSans text-base text-bluecolor font-normal'>Back to </span>
                            <Link to='/login' className='font-OpenSans text-base text-bluecolor font-semibold'>&nbsp; Sign In</Link>
                        </div>
                    </form>
                </div>
            </div >
        )
    }
};

export default Signup;